<template>
  <div class="route_detail">
    <BaseHeader v-if="config" :headerNum="currenttab" :config="config"></BaseHeader>

    <div class="main">

      <!-- 展示区 -->
      <el-row type="flex" justify="center">
        <el-col :lg="16" :md="18" :sm="20" :xs="22">
          <div class="base_width main_room">

            <div class="page_box">
              <el-breadcrumb v-if="aim === 1" separator-class="el-icon-arrow-right"
                class="flex flex-start page_box_title">
                <el-breadcrumb-item v-if="currenttab === 0" :to="{ path: '/' }"
                  class="flex flex-end page_box_title_right_item sethand">
                  <el-image class="page_box_title_right_item_icon" :src="require('@/assets/img/home1.png')"></el-image>
                  <div class="page_box_title_right_item_text">首页</div>
                </el-breadcrumb-item>
                <el-breadcrumb-item v-if="currenttab === 3" :to="{ path: '/classily' }"
                  class="flex flex-end page_box_title_right_item sethand">
                  <el-image class="page_box_title_right_item_icon" :src="require('@/assets/img/home1.png')"></el-image>
                  <div class="page_box_title_right_item_text">分类</div>
                </el-breadcrumb-item>
                <el-breadcrumb-item v-if="currenttab === 4" :to="{ path: '/my' }"
                  class="flex flex-end page_box_title_right_item sethand">
                  <el-image class="page_box_title_right_item_icon" :src="require('@/assets/img/home1.png')"></el-image>
                  <div class="page_box_title_right_item_text">个人中心</div>
                </el-breadcrumb-item>
                <el-breadcrumb-item class="flex flex-end page_box_title_right_item">产品详情</el-breadcrumb-item>
              </el-breadcrumb>
              <el-breadcrumb v-if="aim === 2" separator-class="el-icon-arrow-right"
                class="flex flex-start page_box_title">
                <el-breadcrumb-item v-if="currenttab === 0" :to="{ path: '/' }"
                  class="flex flex-end page_box_title_right_item sethand">
                  <el-image class="page_box_title_right_item_icon" :src="require('@/assets/img/home1.png')"></el-image>
                  <div class="page_box_title_right_item_text">首页</div>
                </el-breadcrumb-item>
                <el-breadcrumb-item v-if="currenttab === 3" :to="{ path: '/classily' }"
                  class="flex flex-end page_box_title_right_item sethand">
                  <el-image class="page_box_title_right_item_icon" :src="require('@/assets/img/home1.png')"></el-image>
                  <div class="page_box_title_right_item_text">分类</div>
                </el-breadcrumb-item>
                <el-breadcrumb-item v-if="currenttab === 4" :to="{ path: '/my' }"
                  class="flex flex-end page_box_title_right_item sethand">
                  <el-image class="page_box_title_right_item_icon" :src="require('@/assets/img/home1.png')"></el-image>
                  <div class="page_box_title_right_item_text">个人中心</div>
                </el-breadcrumb-item>
                <el-breadcrumb-item class="flex flex-end page_box_title_right_item">产品详情</el-breadcrumb-item>
              </el-breadcrumb>


              <el-row class="page_box_tab_list">
                <el-col :lg="12" :md="12" :sm="12" :xs="24">

                  <div class="page_box_tab_list_left flex flex-center">
                    <div class="page_box_tab_list_left_img_box">
                      <el-image class="page_box_tab_list_left_img" :src="setHttps(page_info.image)"></el-image>
                    </div>
                  </div>

                </el-col>
                <el-col :lg="12" :md="12" :sm="12" :xs="24">
                  <div class="page_box_tab_list">

                    <div class="page_box_tab_list_right">
                      <div class="r1">{{ page_info.name || '-' }}</div>
                      <div class="r2" v-if="page_info.labels && page_info.labels.length">
                        <div v-for="(item, index) in page_info.labels" :key="index" class="r2_item ellipsis">{{ item }}
                        </div>
                      </div>
                      <div class="r3">{{ page_info.price }}元</div>
                      <div class="r4">
                        <div v-if="Number(page_info.views) > 10000">{{ page_info.views / 10000 }}万</div>
                        <div v-else>{{ page_info.views }}</div>
                        <el-image class="r4_icon" :src="require('@/assets/img/see1.png')"></el-image>
                      </div>
                      <div class="r5" @click.stop="scClick">
                        <div :class="{ 'act': !(page_info.collect_status === 0) }">收藏</div>
                        <el-image v-if="page_info.collect_status === 0" class="r4_icon"
                          :src="require('@/assets/img/sc1.png')"></el-image>
                        <el-image v-else class="r4_icon" :src="require('@/assets/img/c2a.png')"></el-image>
                      </div>
                      <div class="r6">相关产品</div>
                      <div class="r7">
                        <el-row :gutter="5">
                          <el-col :lg="4" :md="6" :sm="8" :xs="6">
                            <el-image class="r7_item act" :src="setHttps(page_info.image)"></el-image>
                          </el-col>
                          <el-col :lg="4" :md="6" :sm="8" :xs="6" v-for="(item, index) in harbor_list" :key="index">
                            <el-image @click.stop="jumpDetail(item)" class="r7_item sethand" :src="setHttps(item.image)"></el-image>
                          </el-col>
                        </el-row>
                      </div>
                    </div>

                  </div>
                </el-col>
              </el-row>

              <div class="rich_box">
                <div class="rich_box_title flex flex-start">
                  <div class="title_item">
                    <div class="rich_box_title_text">产品介绍</div>
                    <div class="rich_box_title_link"></div>
                  </div>
                </div>
                <el-row v-if="page_info.content">
                  <el-col :lg="12" :md="12" :sm="12" :xs="24">
                    <div class="rich_box_cont" v-html="editContent(page_info.content)"></div>
                  </el-col>
                </el-row>
              </div>







            </div>
          </div>

        </el-col>
      </el-row>




    </div>
  </div>
</template>
<script>
module.exports = {
  data: function () {
    return {
      config: '',
      currenttab: '',
      
      page_info: '',
      harbor_list: [],
      aim: '',
      info: '',//携带进来的数据
      param: {
        product_id: '',//优质|明星
      },




    }
  },
  created() {
    console.log('create-------------')
    this.currenttab = Number(this.$route.query.currenttab)
  },
  mounted() {
    this.getConfig()
    // 获取名为'info'的参数
    this.aim = Number(this.$route.query.aim);
    // 优质产品详情|明星产品详情|热门推荐产品详情
    this.param.product_id = this.$route.query.id
    this.getInfo()
  },
  methods: {
    getConfig() {
      console.log('2222')
      this.$('/api/Index/getBaseConfig').then(res => {
        console.log('配置信息1', res.data)
        this.config = JSON.parse(JSON.stringify(res.data))
        localStorage.setItem('config', JSON.stringify(res.data));
        console.log('配置信息2', res.data)
        // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
        document.head.querySelectorAll("link").forEach(link => {
          if (link.rel === "icon") {
            link.parentNode?.removeChild(link);
          }
        });
        const link = document.createElement('link');
        link.rel = "icon";
        link.href = this.setHttps(res.data.icon); // 将网络图片的URL赋给href属性
        document.head.appendChild(link);
      })
    },
    // 跳转
    jumpDetail(item) {
      if(this.currenttab === 4){
        let href = this.$router.resolve({path:'/routeDetail',query:{id:item.id,tabstate:true,aim:this.aim,currenttab:this.currenttab}}).href;
        window.open(href, "_blank");
      }else{
        let href = this.$router.resolve({path:'/routeDetail',query:{id:item.id,aim:this.aim,currenttab:this.currenttab}}).href;
        window.open(href, "_blank");
      }
    },
    // 收藏
    scClick() {
      this.$('/api/Product/setProductCollect', this.param).then(res => {
        console.log('收藏---', res)
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.getInfo()
      })
    },
    // 优质产品详情|明星产品详情|热门推荐产品详情
    getInfo() {
      this.$('/api/Product/getProductInfo', this.param).then(res => {
        console.log('数据---', res.data)
        this.page_info = res.data
        if (res.data.end_harbor_id) {
          this.getScollList(res.data.end_harbor_id)
        }
      })
    },
    // 获取同类型
    getScollList(harbor_id) {
      this.$('/api/Product/getProductList', { harbor_id: harbor_id }).then(res => {
        console.log('其他封面---', res.data)
        this.harbor_list = res.data
      })
    },



  }
}
</script>
<style lang="scss" scoped>
.route_detail {
  width: 100%;
  background-color: #fff;

  .banner_item {
    width: 100%;
    height: auto;
  }

  .main {
    min-height: 100vh;

    .main_room {


      .page_box {
        width: 100%;

        .page_box_title {
          padding: 40px 0;

          &::v-deep .el-breadcrumb__inner {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          .page_box_title_right_item {
            display: flex;
            justify-items: flex-end;
            align-items: center;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #6C757D;

            &::v-deep .el-breadcrumb__inner.is-link {
              color: #6C757D;
              font-weight: 400;
            }

            &:last-child::v-deep .el-breadcrumb__inner {
              color: #000;
            }

            .page_box_title_right_item_icon {
              width: 15px;
              height: 15px;
              margin-right: 7px;
            }


          }
        }

        .page_box_tab_list {

          .page_box_tab_list_left {
            padding: 20px 40px 60px;
            border: 1px solid #e6e6e8;

            .page_box_tab_list_left_img_box {
              width: 100%;
              padding-top: 133.33333%;
              position: relative;

              .page_box_tab_list_left_img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }

          .page_box_tab_list_right {
            padding: 0 10px 60px;

            .r1 {
              font-size: 24px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #191919;
            }

            .r2 {
              padding: 20px 0;
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: flex-start;
              row-gap: 20px;

              .r2_item {
                max-width: 200px;
                height: 26px;
                line-height: 26px;
                margin-right: 5px;
                padding: 0 4px;
                border-radius: 4px;
                background-color: #f7d274;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #482221;
              }
            }

            .r3 {
              font-size: 24px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #DD6029;
            }

            .r4 {
              padding: 20px 0;
              display: grid;
              grid-template-columns: auto 13px;
              justify-content: flex-start;
              align-items: center;
              font-size: 13px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;

              .r4_icon {
                width: 13px;
                height: 13px;
              }
            }

            .r5 {
              padding: 0 0 20px;
              display: grid;
              grid-template-columns: auto 24px;
              column-gap: 10px;
              justify-content: flex-start;
              align-items: center;
              font-size: 16px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #41416E;

              .act {
                font-weight: 500;
                color: #CEB05A;
              }

              .r5_icon {
                width: 24px;
                height: 24px;
              }
            }

            .r6 {
              padding: 10px 0;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }

            .r7 {

              .r7_item {
                width: 70px;
                height: 90px;
                border-radius: 5px;
                border: 1px solid rgba(0, 0, 0, 0);

                &.act {
                  border: 1px solid #FE4F00;
                }
              }
            }
          }
        }


        .rich_box {
          padding: 30px 0 60px;

          .rich_box_title {
            border-bottom: 1px solid #eaeaea;

            .title_item {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              column-gap: 3px;
              justify-items: center;
              align-items: center;

              .rich_box_title_text {
                font-size: 20px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #482221;
              }

              .rich_box_title_link {
                width: 35px;
                height: 3px;
                border-radius: 5px;
                background-color: #ceb05a;
              }
            }

          }

          .rich_box_cont {
            padding: 60px 200px;
            padding: 60px 0;
          }
        }



      }

    }
  }


}
</style>
