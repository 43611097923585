<template>
  <div class="search_page">
    <BaseHeader v-if="config" :headerNum="2" :config="config"></BaseHeader>

    <div class="main">


      <!-- 轮播 -->
      <el-carousel height="26.041vw" class="hidden-xs-only" :interval="Number(banner4.interval)" :loop="true">

        <el-carousel-item v-for="(item, index) in banner4.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>

      </el-carousel>
      <el-carousel height="26.041vw" class="hidden-sm-and-up" :interval="Number(banner4.interval)" :loop="true">

        <el-carousel-item v-for="(item, index) in banner4.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>

      </el-carousel>


      <!-- 展示区 -->
      <div class="base_width main_room">
        <el-row type="flex" justify="center">
          <el-col :lg="24" :md="20" :sm="20" :xs="20">
            <div class="page_box">
              <div class="page_box_title">
                <div class="page_box_title_left">
                  <div class="page_box_title_left_text">查询</div>
                  <div class="page_box_title_left_link"></div>
                </div>
              </div>

              <!-- 搜索区 -->
              <div class="search_room hidden-md-and-down">

                <div class="search_item_box">
                  <div class="search_item defsearch">
                    <input v-model="search_key" @keyup.enter="jumpClick" class="defsearch_input" placeholder="请输入提运单号或车牌号"
                      type="text">
                    <el-image class="defsearch_img sethand" @click.stop="jumpClick"
                      :src="require('@/assets/img/c7.png')"></el-image>
                  </div>
                </div>

                <div class="search_item_box">
                  <div v-if="[1].includes(ipt_num)" class="search_item base_search">
                    <div class="base_search_left act">
                      <el-input v-model="city_val" @change="search_city" :ref="`ipt${ipt_num}`"
                        class="base_search_left_ipt" placeholder="请输入城市简码/中文/国家"></el-input>
                    </div>
                    <el-image class="base_search_right sethand" @click.stop="delClick_city"
                      :src="require('@/assets/img/sa.png')"></el-image>
                  </div>
                  <div v-else class="search_item base_search sethand" @click.stop="cityClick(1)">
                    <div class="base_search_left">
                      <div class="base_search_left_top">城市查询</div>
                      <div class="base_search_left_bot">请输入城市简码/中文/国家</div>
                    </div>
                    <el-image class="base_search_right" :src="require('@/assets/img/c7a.png')"></el-image>
                  </div>
                  <div v-if="search_state1" class="search_item_list show1">
                    <div class="show1_top">
                      <div v-for="(item, index) in city_list" :key="index" class="show1_top_item sethand"
                        :class="{ 'act': city_idx === index }" @click.stop="cityOpt(index)">{{ item.name }}</div>
                    </div>
                    <div class="show1_bot">
                      <div class="show1_bot_item">
                        <div class="show1_bot_item_text">三字代码</div>
                        <div class="show1_bot_item_code">{{ city_list[city_idx].code || '-' }}</div>
                      </div>
                      <div class="show1_bot_item">
                        <div class="show1_bot_item_text">城市名称</div>
                        <div class="show1_bot_item_code">{{ city_list[city_idx].name || '-' }}</div>
                      </div>
                      <div class="show1_bot_item">
                        <div class="show1_bot_item_text">所属国家</div>
                        <div class="show1_bot_item_code">{{ city_list[city_idx].country || '-' }}</div>
                      </div>

                    </div>
                  </div>
                </div>



                <div class="search_item_box">
                  <div v-if="[2].includes(ipt_num)" class="search_item base_search">
                    <div class="base_search_left act">
                      <el-input v-model="air_val" @change="search_air" :ref="`ipt${ipt_num}`" class="base_search_left_ipt"
                        placeholder="请输入航司二位代码/航司名称"></el-input>
                    </div>
                    <el-image class="base_search_right sethand" @click.stop="delClick_air"
                      :src="require('@/assets/img/sa.png')"></el-image>
                  </div>
                  <div v-else class="search_item base_search sethand" @click.stop="cityClick(2)">
                    <div class="base_search_left">
                      <div class="base_search_left_top">航司查询</div>
                      <div class="base_search_left_bot">请输入航司二位代码/航司名称</div>
                    </div>
                    <el-image class="base_search_right" :src="require('@/assets/img/c7a.png')"></el-image>
                  </div>
                  <div v-if="search_state2" class="search_item_list show2">
                    <div class="show1_top">
                      <div v-for="(item, index) in air_list" :key="index" class="show1_top_item sethand"
                        :class="{ 'act': air_idx === index }" @click.stop="airOpt(index)">{{ item.name }}</div>
                    </div>
                    <div class="show1_bot">
                      <div class="show1_bot_item">
                        <div class="show1_bot_item_text">二位代码</div>
                        <div class="show1_bot_item_code">{{ air_list[air_idx].code || '-' }}</div>
                      </div>
                      <div class="show1_bot_item">
                        <div class="show1_bot_item_text">航司名称</div>
                        <div class="show1_bot_item_code">{{ air_list[air_idx].name || '-' }}</div>
                      </div>

                    </div>
                  </div>
                </div>


                <div class="search_item_box">
                  <div v-if="[3].includes(ipt_num)" class="search_item base_search">
                    <div class="base_search_left act">
                      <el-input v-model="hs_val" @change="search_hs" :ref="`ipt${ipt_num}`" class="base_search_left_ipt"
                        placeholder="请输入商品编码(4位以上)"></el-input>
                    </div>
                    <el-image class="base_search_right sethand" @click.stop="delClick_hs"
                      :src="require('@/assets/img/sa.png')"></el-image>
                  </div>
                  <div v-else class="search_item base_search sethand" @click.stop="cityClick(3)">
                    <div class="base_search_left">
                      <div class="base_search_left_top">HS查询</div>
                      <div class="base_search_left_bot">请输入商品编码(4位以上)</div>
                    </div>
                    <el-image class="base_search_right" :src="require('@/assets/img/c7a.png')"></el-image>
                  </div>
                  <div v-if="search_state3" class="search_item_list show3">
                    <div class="show1_top">
                      <div v-if="hs_type === 1">
                        <div v-for="(item, index) in hs_list" :key="index">
                          <div class="show1_top_item sethand" :class="{ 'act': hs_idx === index }"
                            @click.stop="hsClick(index)">{{
                              item.code }}</div>
                        </div>
                      </div>
                      <div v-if="hs_type === 2">
                        <div class="show1_top_item sethand" :class="{ 'act': hs_idx === 0 }" @click.stop="hsClick(0)">
                          {{ hs_list2.hs_code }}</div>
                      </div>

                    </div>
                    <!-- 选中时展示 -->
                    <div v-loading="loading" class="show3_bot">
                      <div class="req1_item">
                        <div class="req1_item_title">商品编码：</div>
                        <div class="req1_item_text">{{ hs_list2.hs_code || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">商品名称：</div>
                        <div class="req1_item_text">{{ hs_list2.hs_name || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">法定第一单位：</div>
                        <div class="req1_item_text">{{ hs_list2.unit_first || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">法定第二单位：</div>
                        <div class="req1_item_text">{{ hs_list2.unit_second || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">进口关税：</div>
                        <div class="req1_item_text">{{ hs_list2.import_tariff || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">进口增值税：</div>
                        <div class="req1_item_text">{{ hs_list2.value_added_tax || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">出口退税率：</div>
                        <div class="req1_item_text">{{ hs_list2.export_return_tariff || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">监管条件：</div>
                        <div class="req1_item_text">{{ hs_list2.supervision_cond || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">申报要素：</div>
                        <div class="req1_item_text">{{ hs_list2.declar_items || '-' }}</div>
                      </div>
                    </div>


                  </div>
                </div>


              </div>
              <div class="search_room hidden-lg-and-up">

                <div class="search_item_box">
                  <div class="search_item defsearch">
                    <input v-model="search_key" @keyup.enter="jumpClick" class="defsearch_input" placeholder="请输入提运单号或车牌号"
                      type="text">
                    <el-image class="defsearch_img sethand" @click.stop="jumpClick"
                      :src="require('@/assets/img/c7.png')"></el-image>
                  </div>
                </div>

                <div class="search_item_box">
                  <div v-if="[1].includes(ipt_num)" class="search_item base_search">
                    <div class="base_search_left act">
                      <el-input v-model="city_val" @change="search_city" :ref="`ipt${ipt_num}`"
                        class="base_search_left_ipt" placeholder="请输入城市简码/中文/国家"></el-input>
                    </div>
                    <el-image class="base_search_right sethand" @click.stop="delClick_city"
                      :src="require('@/assets/img/sa.png')"></el-image>
                  </div>
                  <div v-else class="search_item base_search sethand" @click.stop="cityClick(1)">
                    <div class="base_search_left">
                      <div class="base_search_left_top">城市查询</div>
                      <div class="base_search_left_bot">请输入城市简码/中文/国家</div>
                    </div>
                    <el-image class="base_search_right" :src="require('@/assets/img/c7a.png')"></el-image>
                  </div>
                  <div v-if="search_state1" class="search_item_list show1">
                    <div class="show1_top">
                      <div v-for="(item, index) in city_list" :key="index" class="show1_top_item sethand"
                        :class="{ 'act': city_idx === index }" @click.stop="cityOpt(index)">{{ item.name }}</div>
                    </div>
                    <div class="show1_bot">
                      <div class="show1_bot_item">
                        <div class="show1_bot_item_text">三字代码</div>
                        <div class="show1_bot_item_code">{{ city_list[city_idx].code || '-' }}</div>
                      </div>
                      <div class="show1_bot_item">
                        <div class="show1_bot_item_text">城市名称</div>
                        <div class="show1_bot_item_code">{{ city_list[city_idx].name || '-' }}</div>
                      </div>
                      <div class="show1_bot_item">
                        <div class="show1_bot_item_text">所属国家</div>
                        <div class="show1_bot_item_code">{{ city_list[city_idx].country || '-' }}</div>
                      </div>

                    </div>
                  </div>
                </div>



                <div class="search_item_box">
                  <div v-if="[2].includes(ipt_num)" class="search_item base_search">
                    <div class="base_search_left act">
                      <el-input v-model="air_val" @change="search_air" :ref="`ipt${ipt_num}`" class="base_search_left_ipt"
                        placeholder="请输入航司二位代码/航司名称"></el-input>
                    </div>
                    <el-image class="base_search_right sethand" @click.stop="delClick_air"
                      :src="require('@/assets/img/sa.png')"></el-image>
                  </div>
                  <div v-else class="search_item base_search sethand" @click.stop="cityClick(2)">
                    <div class="base_search_left">
                      <div class="base_search_left_top">航司查询</div>
                      <div class="base_search_left_bot">请输入航司二位代码/航司名称</div>
                    </div>
                    <el-image class="base_search_right" :src="require('@/assets/img/c7a.png')"></el-image>
                  </div>
                  <div v-if="search_state2" class="search_item_list show2">
                    <div class="show1_top">
                      <div v-for="(item, index) in air_list" :key="index" class="show1_top_item sethand"
                        :class="{ 'act': air_idx === index }" @click.stop="airOpt(index)">{{ item.name }}</div>
                    </div>
                    <div class="show1_bot">
                      <div class="show1_bot_item">
                        <div class="show1_bot_item_text">二位代码</div>
                        <div class="show1_bot_item_code">{{ air_list[air_idx].code || '-' }}</div>
                      </div>
                      <div class="show1_bot_item">
                        <div class="show1_bot_item_text">航司名称</div>
                        <div class="show1_bot_item_code">{{ air_list[air_idx].name || '-' }}</div>
                      </div>

                    </div>
                  </div>
                </div>


                <div class="search_item_box">
                  <div v-if="[3].includes(ipt_num)" class="search_item base_search">
                    <div class="base_search_left act">
                      <el-input v-model="hs_val" @change="search_hs" :ref="`ipt${ipt_num}`" class="base_search_left_ipt"
                        placeholder="请输入商品编码(4位以上)"></el-input>
                    </div>
                    <el-image class="base_search_right sethand" @click.stop="delClick_hs"
                      :src="require('@/assets/img/sa.png')"></el-image>
                  </div>
                  <div v-else class="search_item base_search sethand" @click.stop="cityClick(3)">
                    <div class="base_search_left">
                      <div class="base_search_left_top">HS查询</div>
                      <div class="base_search_left_bot">请输入商品编码(4位以上)</div>
                    </div>
                    <el-image class="base_search_right" :src="require('@/assets/img/c7a.png')"></el-image>
                  </div>
                  <div v-if="search_state3" class="search_item_list show3">
                    <div class="show1_top">
                      <div v-if="hs_type === 1">
                        <div v-for="(item, index) in hs_list" :key="index">
                          <div class="show1_top_item sethand" :class="{ 'act': hs_idx === index }"
                            @click.stop="hsClick(index)">{{
                              item.code }}</div>
                        </div>
                      </div>
                      <div v-if="hs_type === 2">
                        <div class="show1_top_item sethand" :class="{ 'act': hs_idx === 0 }" @click.stop="hsClick(0)">
                          {{ hs_list2.hs_code }}</div>
                      </div>

                    </div>
                    <!-- 选中时展示 -->
                    <div v-loading="loading" class="show3_bot">
                      <div class="req1_item">
                        <div class="req1_item_title">商品编码：</div>
                        <div class="req1_item_text">{{ hs_list2.hs_code || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">商品名称：</div>
                        <div class="req1_item_text">{{ hs_list2.hs_name || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">法定第一单位：</div>
                        <div class="req1_item_text">{{ hs_list2.unit_first || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">法定第二单位：</div>
                        <div class="req1_item_text">{{ hs_list2.unit_second || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">进口关税：</div>
                        <div class="req1_item_text">{{ hs_list2.import_tariff || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">进口增值税：</div>
                        <div class="req1_item_text">{{ hs_list2.value_added_tax || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">出口退税率：</div>
                        <div class="req1_item_text">{{ hs_list2.export_return_tariff || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">监管条件：</div>
                        <div class="req1_item_text">{{ hs_list2.supervision_cond || '-' }}</div>
                      </div>
                      <div class="req1_item">
                        <div class="req1_item_title">申报要素：</div>
                        <div class="req1_item_text">{{ hs_list2.declar_items || '-' }}</div>
                      </div>
                    </div>


                  </div>
                </div>


              </div>








            </div>
          </el-col>
        </el-row>


      </div>


    </div>
  </div>
</template>
<script>
module.exports = {
  data: function () {
    return {
      loading: false,
      banner4: '',
      config: '',

      ipt_num: '',//点击哪个展示哪个ipt
      search_key: '',//当前搜索框搜索内容

      city_val: '',
      search_state1: false,
      city_list: '',//城市查询结果
      city_idx: 0,//选中的查询结果

      air_val: '',
      search_state2: false,
      air_list: '',//城市查询结果
      air_idx: 0,//选中的查询结果


      search_state3: false,
      hs_val: '',
      hs_list: '',//城市查询结果
      hs_list2: '',//城市查询结果
      hs_idx: 0,//选中的查询结果
      hs_type: '',//查询状态1单子2货名

    }
  },
  created() {


  },


  mounted() {
    this.getConfig()
    this.getBanner4()
  },
  methods: {
    getConfig() {
      console.log('2222')
      this.$('/api/Index/getBaseConfig').then(res => {
        console.log('配置信息1', res.data)
        this.config = JSON.parse(JSON.stringify(res.data))
        localStorage.setItem('config', JSON.stringify(res.data));
        console.log('配置信息2', res.data)
        // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
        document.head.querySelectorAll("link").forEach(link => {
          if (link.rel === "icon") {
            link.parentNode?.removeChild(link);
          }
        });
        const link = document.createElement('link');
        link.rel = "icon";
        link.href = this.setHttps(res.data.icon); // 将网络图片的URL赋给href属性
        document.head.appendChild(link);
      })
    },
    // 选中某个HS
    hsClick(idx) {
      this.hs_idx = idx
      if (this.hs_type === 1) {
        this.getCont(this.hs_list[idx].name)
      } else {
        console.log('按名称查,就一条,没得切')
        return;
      }
      this.$forceUpdate()
    },
    // 清除
    delClick_hs() {
      this.search_state3 = false
      this.hs_val = ''
      this.hs_list = ''
      this.hs_list2 = ''
      this.hs_idx = 0
      this.hs_type = ''
      this.ipt_num = 0
    },
    // 搜索触发
    search_hs(e) {
      this.hs_list = ''
      this.hs_list2 = ''
      this.hs_idx = 0
      this.hs_type = ''
      console.log('搜索值', e)
      let regex = /^[0-9]{4,}$/;
      if (e) {
        if (regex.test(e)) {
          if (e.length < 4) {
            this.$message.error('关键字必须大于4个字符');
            return;
          }
          this.$('/api/Search/searchQuickHs', {
            hs_code: e
          }).then(res => {
            console.log('查询hs---', res.data)
            this.hs_list = res.data
            if (this.hs_list.length) {
              this.search_state3 = true
              this.hs_type = 1
              this.getCont(res.data[0].name)
            } else {
              this.$message.error('没有查询到相关信息');
            }
          })
        } else {
          console.log('搜索值', e)
          this.$('/api/Search/searchHs', {
            search_key: e
          }).then(res => {
            console.log('查询hs2---', res.data)
            this.hs_list2 = res.data
            if (this.hs_list2.length) {
              this.search_state3 = true
              this.hs_type = 2
            } else {
              this.$message.error('没有查询到相关信息');
            }
            this.$forceUpdate()
          })
        }

      }
    },
    // hs详情展示区
    getCont(keys) {
      this.loading = true
      this.$('/api/Search/searchHs', {
        search_key: keys
      }).then(res => {
        console.log('查询hs2---', res.data)
        this.hs_list2 = res.data
        this.search_state3 = true
        this.$forceUpdate()
      }).finally(() => {
        this.loading = false
      })
    },



    // 清除
    delClick_air() {
      this.search_state2 = false
      this.air_val = ''
      this.air_list = []
      this.ipt_num = 0
    },
    // 选中某个城市
    airOpt(idx) {
      this.air_idx = idx
      this.$forceUpdate()
    },
    // 搜索2触发
    search_air(e) {
      console.log('搜索值', e)
      if (e.length > 0) {
        console.log('搜索值', e)
        this.$('/api/Search/searchCompany', {
          search_key: e
        }).then(res => {
          console.log('查询航司---', res.data)
          this.air_list = res.data
          if (this.air_list.length) {
            this.search_state2 = true
          } else {
            this.$message.error('没有查询到相关信息');
          }
        })
      } else {
        // 如果输入内容为空，重置
        this.delClick_air()
      }
    },
    // 清除
    delClick_city() {
      this.search_state1 = false
      this.city_val = ''
      this.city_list = []
      this.ipt_num = 0
    },
    // 选中某个城市
    cityOpt(idx) {
      this.city_idx = idx
      this.$forceUpdate()
    },

    // 搜索1触发
    search_city(e) {
      console.log('搜索', e)
      if (e.length > 0) {
        this.$('/api/Search/searchCity', {
          search_key: e
        }).then(res => {
          console.log('查询城市---', res.data)
          this.city_list = res.data
          if (this.city_list.length) {
            this.search_state1 = true
          } else {
            this.$message.error('没有查询到相关信息');
          }
        })
      } else {
        // 如果输入内容为空，重置
        this.delClick_city()
      }
    },
    // 第一个框-跳转
    jumpClick() {
      if (!this.search_key) {
        this.$message.error('请输入提运单号或车牌号');
        return;
      }
      let regex = /^[\u4e00-\u9fa5][A-Z][A-Z0-9]{5,6}$/;
      regex.test(this.search_key)
      // 校验是不是车牌号
      if (regex.test(this.search_key)) {
        // console.log('---------------货物 E9610202310160002')
        this.$router.push({ name: 'searchDetail', query: { infokeys: this.search_key, infotype: 2,currenttab:2 } });
      } else {
        console.log('---------------车牌号')
        this.$router.push({ name: 'searchDetail', query: { infokeys: this.search_key, infotype: 1,currenttab:2 } });
      }

    },
    // 点击某个搜索框
    cityClick(num) {
      // 先清空
      this.delClick_city()
      this.delClick_air()
      this.delClick_hs()

      // 再赋值
      this.ipt_num = num
      let refkey = `ipt${num}`
      this.$nextTick(() => {
        this.$refs[refkey].focus()
      })
    },

    // 轮播
    getBanner4() {
      this.$('/api/Index/getBannerList', {
        type: 1,
        show_type: 4
      }).then(res => {
        console.log('banner===', res.data)
        this.banner4 = res.data
      })
    },




  }
}
</script>
<style lang="scss" scoped>
.search_page {
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 28%, #F6F6F6 100%);




  .banner_item {
    width: 100%;
    height: auto;
  }

  .main {
    min-height: 100vh;

    .main_room {
      position: relative;
      top: -3vw;
      left: 0;
      z-index: 10;

      .page_box {
        width: 100%;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px 25px 58px;

        .page_box_title {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: space-between;
          align-items: center;

          .page_box_title_left {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 3px;
            justify-content: flex-start;
            align-content: center;

            .page_box_title_left_text {
              font-size: 20px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #191919;
            }

            .page_box_title_left_link {
              width: 35px;
              height: 3px;
              border-radius: 5px;
              background-color: #ceb05a;
            }
          }

          .page_box_title_right {
            &::v-deep .el-breadcrumb__inner {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }

            .page_box_title_right_item {
              display: flex;
              justify-items: flex-end;
              align-items: center;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #6C757D;

              &::v-deep .el-breadcrumb__inner.is-link {
                color: #6C757D;
                font-weight: 400;
              }

              &:last-child::v-deep .el-breadcrumb__inner {
                color: #000;
              }

              .page_box_title_right_item_icon {
                width: 15px;
                height: 15px;
                margin-right: 7px;
              }


            }
          }
        }

        .search_room {
          padding: 30px 0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 60px;
          row-gap: 25px;

          &.hidden-lg-and-up {
            grid-template-columns: repeat(1, 1fr);
            column-gap: 0;
          }

          .search_item_box {
            width: 100%;
            position: relative;

            .search_item {
              width: 100%;
              height: 75px;
              border-radius: 10px;
              padding: 0 20px;

            }

            .defsearch {
              border: 1px solid #ceb05a;
              background-color: #CEB05A;
              display: grid;
              grid-template-columns: calc(100% - 20px) 20px;
              justify-content: space-between;
              align-items: center;

              .defsearch_input {
                width: 100%;
                height: 100%;
                font-size: 15px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
              }

              .defsearch_input::-webkit-input-placeholder {
                font-weight: 600;
                color: #FFFFFF;
              }

              .defsearch_img {
                width: 20px;
                height: 20px;
              }
            }

            .base_search {
              display: grid;
              grid-template-columns: calc(100% - 20px - 10px) 20px;
              justify-content: space-between;
              align-items: center;
              column-gap: 10px;
              border: 1px solid #ceb05a;
              background-color: #fff;

              .base_search_left {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                row-gap: 5px;
                justify-content: flex-start;
                align-content: center;

                &::v-deep .el-input__inner {
                  height: 53px;
                  border-radius: 10px;
                  background-color: #CEB05A;
                  border: #CEB05A;
                  font-size: 16px;
                  font-weight: 600;
                  font-family: PingFang SC-Semibold, PingFang SC;
                  color: #FFFFFF;
                }

                &::v-deep .el-input input::-webkit-input-placeholder {
                  font-weight: 400;
                  color: #FFFFFF;
                }

                &::v-deep .el-input__inner:focus {
                  font-weight: 400;
                  background-color: #CEB05A;
                  border: #CEB05A;
                }

                // 选中时的输入框
                .base_search_left_ipt {
                  width: 100%;
                  height: 100%;
                }

                .base_search_left_top {
                  font-size: 16px;
                  font-family: PingFang SC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #191919;
                }

                .base_search_left_bot {
                  font-size: 13px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #999999;
                }
              }

              .base_search_right {
                width: 20px;
                height: 20px;
              }
            }
          }

          .search_item_list {
            width: 100%;
            min-height: 250px;
            position: absolute;
            top: 75px;
            left: 0;
            z-index: 100;
            border-radius: 10px;
            border: 1px solid #ceb05a;
            background-color: #fff;
            box-shadow: 0px 5px 5px 0px rgba(153, 153, 153, 0.15);

            &.show1 {
              .show1_top {
                width: 100%;
                height: 150px;
                padding: 0 25px;
                overflow-y: scroll;
                border-bottom: 8px solid #f6f6f6;

                .show1_top_item {
                  padding: 15px 0;
                  border-bottom: 1px solid #F2f2f2;
                  font-size: 13px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;

                  &:last-child {
                    border: 1px solid rgba(0, 0, 0, 0);
                  }

                  &.act {
                    font-size: 26rpx;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #CEB05A;
                  }

                  &:last-child {
                    border-bottom: 1rpx solid rgba(0, 0, 0, 0);
                  }
                }
              }

              .show1_bot {
                padding: 15px 0 0;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                align-content: center;

                .show1_bot_item {
                  display: grid;
                  grid-template-columns: repeat(1, 1fr);
                  row-gap: 15px;
                  align-content: center;
                  justify-items: center;

                  .show1_bot_item_text {
                    font-size: 13px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;
                  }

                  .show1_bot_item_code {
                    font-size: 13px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #191919;
                    text-align: center;

                  }
                }
              }
            }

            &.show2 {
              .show1_top {
                width: 100%;
                height: 150px;
                padding: 0 25px;
                overflow-y: scroll;
                border-bottom: 8px solid #f6f6f6;

                .show1_top_item {
                  padding: 15px 0;
                  border-bottom: 1px solid #F2f2f2;
                  font-size: 13px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;

                  &:last-child {
                    border: 1px solid rgba(0, 0, 0, 0);
                  }

                  &.act {
                    font-size: 26rpx;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #CEB05A;
                  }

                  &:last-child {
                    border-bottom: 1rpx solid rgba(0, 0, 0, 0);
                  }
                }
              }

              .show1_bot {
                padding: 15px 0 0;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-content: center;

                .show1_bot_item {
                  display: grid;
                  grid-template-columns: repeat(1, 1fr);
                  row-gap: 15px;
                  align-content: center;
                  justify-items: center;

                  .show1_bot_item_text {
                    font-size: 13px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;
                  }

                  .show1_bot_item_code {
                    font-size: 13px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #191919;
                    text-align: center;

                  }
                }
              }
            }

            &.show3 {
              .show1_top {
                width: 100%;
                height: 150px;
                padding: 0 25px;
                overflow-y: scroll;
                border-bottom: 8px solid #f6f6f6;

                .show1_top_item {
                  padding: 15px 0;
                  border-bottom: 1px solid #F2f2f2;
                  font-size: 13px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;

                  &:last-child {
                    border: 1px solid rgba(0, 0, 0, 0);
                  }

                  &.act {
                    font-size: 13px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #CEB05A;
                  }

                  &:last-child {
                    border-bottom: 1px solid rgba(0, 0, 0, 0);
                  }
                }
              }

              .show3_bot {
                padding: 10px 15px;
                height: auto;

                .req1_item {
                  padding: 10px 15px;
                  display: grid;
                  grid-template-columns: 100px calc(100% - 100px);
                  column-gap: 5px;
                  align-items: center;
                  justify-items: flex-start;

                  .req1_item_title {
                    font-size: 13px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                  }

                  .req1_item_text {
                    font-size: 13px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #191919;
                  }
                }
              }










            }



            /* 自定义滚动条样式 */

            .show1_top::-webkit-scrollbar {
              width: 3px;
              /* 设置滚动条宽度 */
            }

            .show1_top::-webkit-scrollbar-thumb {
              background: #E6E6E8;
              /* 设置滚动条背景颜色 */
            }
          }

        }










      }

    }
  }


}
</style>
