<template>
  <div class="login_page">
    <el-image class="login_bgm" :src="require('@/assets/img/bgfj.png')"></el-image>


    <!-- <div class="posi-all" style="width: 100%;"> -->
    <el-row type="flex" justify="center" v-if="config">
      <el-col :lg="14" :md="16" :sm="18" :xs="22" class="base_shadow login_card">

        <el-row type="flex" justify="center" class="login_room" v-loading="loading">
          <el-col :lg="14" :md="10" :sm="8" :xs="0" class="hidden-xs-only">
            <div class="login_room_left">
              <el-image class="login_logo" :src="setHttps(config.logo)"></el-image>
            </div>
          </el-col>
          <el-col :lg="10" :md="14" :sm="16" :xs="24">
            <div class="login_room_right">
              <div class="r1 flex flex-between">
                <div class="hidden-xs-only"></div>
                <el-image class="r1_l hidden-sm-and-up" :src="setHttps(config.logo)"></el-image>
                <div class="r1_r">
                  <el-image class="r1_r_img" :src="require('@/assets/img/home1.png')"></el-image>
                  <div @click.stop="bakeHome">返回首页</div>
                </div>
              </div>
              <div class="r2">欢迎回来!</div>
              <div class="r3">登录可同步您的预约信息，查询记录等信息。</div>
              <div class="r4">
                <div class="ipt_phone">
                  <div class="ipt_phone_title">手机号</div>
                  <el-input placeholder="请输入内容" v-model="param.phone" :maxlength="11" clearable>
                  </el-input>
                </div>
                <div class="ipt_code">
                  <div class="ipt_code_title">验证码</div>
                  <div class="ipt_code_cont">
                    <el-input class="ipt_code_cont_ipt" placeholder="请输入验证码" v-model="param.code" :maxlength="8"
                      clearable>
                    </el-input>
                    <div v-if="cd_state === 1" class="ipt_box_cd" @click.stop="getCode">获取验证码</div>
                    <div v-if="cd_state === 2" class="ipt_box_cd">{{ cd_time }}s</div>
                    <div v-if="cd_state === 3" class="ipt_box_cd" @click.stop="getCode">重新获取</div>
                  </div>
                </div>
              </div>
              <div class="r5">
                <el-checkbox v-model="record" :checked="record">记住登录状态</el-checkbox>
              </div>
              <div class="r6" @click.stop="signIn">
                <el-image class="r6_img" :src="require('@/assets/img/outlogin.png')"></el-image>
                <div>登录</div>
              </div>
              <div class="r7" @click.stop="con">未注册手机验证后自动登录</div>
            </div>

          </el-col>
        </el-row>

      </el-col>
    </el-row>
    <!-- </div> -->


  </div>
</template>
<script>
module.exports = {
  data: function () {
    return {
      config: '',
      record: false,//默认不记密码
      loading: false,
      param: {
        phone: '',
        code: ''
      },
      cd_time: 60,//倒计时时间
      cd_state: 1, //1默认2计时3重发
      cd_run: null,
    }
  },
  mounted() {
    this.config = JSON.parse(localStorage.getItem('config'));
    this.record = JSON.parse(localStorage.getItem('record'));
  },
  methods: {

    // 登录
    signIn() {
      if (!(this.param.phone)) {
        this.$message.error('请输入手机号');
        return;
      }
      if (!this.tel_reg(this.param.phone)) {
        this.$message.error('请输入正确的手机号');
        return;
      }
      if (!(this.param.code)) {
        this.$message.error('请输入验证码');
        return;
      }
      localStorage.setItem('record', JSON.stringify(this.record));
      this.loading = true
      this.$('/api/Login/smsLogin', this.param).then(res => {
        if (res.data.token && res.data.userInfo) {
          if (res.data.userInfo.status === 1) {
            // 如果勾选进行存储否则清除local存session
            if (this.record) {
              localStorage.setItem('token', res.data.token);
              localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo));
              this.$router.go(-1)
            } else {
              localStorage.setItem('token', '');
              localStorage.setItem('userInfo', '');
              sessionStorage.setItem('token', res.data.token);
              sessionStorage.setItem('userInfo', JSON.stringify(res.data.userInfo));
              this.$router.go(-1)
            }
          } else {
            this.$message.error('账户下架');
            this.$router.go(-1)
          }
        }

      }).finally(() => {
        this.loading = false
      })

    },
    // 获取验证码
    getCode() {
      if (!(this.param.phone)) {
        this.$message.error('请输入手机号');
        return;
      }
      if (!this.tel_reg(this.param.phone)) {
        this.$message.error('请输入正确的手机号');
        return;
      }
      this.loading = true
      this.$('/api/User/sendSms', { phone: this.param.phone }).then(res => {
        this.cd_time = res.data.second_second ? Number(res.data.second_second) : 60;
        this.cd_state = 2
        this.cd_run = setInterval(() => {
          this.cd_time = this.cd_time - 1;
          if (this.cd_time < 0) {
            this.cd_state = 3
            clearInterval(this.t);
          }
        }, 1000)
      }).finally(() => {
        this.loading = false
      })
    },
    // 返回首页
    bakeHome() {
      this.$router.push({ name: 'home' });
    },
  }
}
</script>
<style lang="scss" scoped>
.login_page {
  padding-top: 10vw;

  .login_bgm {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .login_card {
    min-height: 32.23958vw;
    padding: 25px 25px 0;
    border-radius: 30px;
    background-color: #fff;

    .login_room {
      width: 100%;
      height: 100%;
      // background-color: #0f0;
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
      background-image: url('../assets/img/dfj.png');
      background-size: 100% auto;
      background-position: center 82.31017%;
      background-repeat: no-repeat;

      /* 设置元素的高度，可以根据需要调整 */
      .login_logo {
        width: 5.46875vw;
        height: 4.47916vw;
        min-width: 43px;
        min-height: 35px;
      }

      .login_room_right {
        // min-height: 619px;

        position: relative;
        z-index: 100;
        // background-color: #0f0;
        padding: 0 2.29166vw 0 3.125vw;
        background-color: rgba(255, 255, 255, 0.92);
        .r1 {
          .r1_l {
            width: 5.46875vw;
            height: 4.47916vw;
            min-width: 43px;
            min-height: 35px;
          }

          .r1_r {
            display: grid;
            grid-template-columns: 15px auto;
            column-gap: 7px;
            justify-content: flex-end;
            align-items: center;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #6C757D;

            .r1_r_img {
              width: 15px;
              height: 15px;
            }
          }
        }


        .r2 {
          padding: 1.5625vw 0 1.25vw;
          font-size: 36px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #12131A;
        }

        .r3 {
          padding: 0 0 2.08333vw;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #696C80;
        }

        .r4 {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          row-gap: 15px;

          .ipt_phone {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 5px;

            .ipt_phone_title {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #12131A;
            }
          }

          .ipt_code {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 5px;

            .ipt_code_title {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #12131A;
            }

            .ipt_code_cont {
              width: 100%;
              position: relative;

              &::v-deep .el-input--suffix .el-input__inner {
                padding-right: 100px;
              }

              .ipt_box_cd {
                width: 100px;
                line-height: 40px;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 110;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #CEB05A;
                text-align: center;
              }
            }


          }


        }

        .r5 {
          padding: 24px 0 36px;
        }

        .r6 {
          width: 100%;
          height: 50px;
          line-height: 50px;
          border-radius: 4px;
          background-color: #CEB05A;
          display: grid;
          grid-template-columns: auto auto;
          justify-content: center;
          align-items: center;
          column-gap: 10px;

          font-size: 16px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;

          .r6_img {
            width: 24px;
            height: 24px;
          }
        }

        .r7 {
          padding-top: 24px;
          text-align: center;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #696C80;
          padding-bottom: 90px;

        }
      }
      @media (max-width:768px) {
        .login_room_right{
          padding: 0;
        }
      }
    }
  }
}
</style>
