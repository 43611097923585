<template>
    <div class="my_page">
        <BaseHeader v-if="config" :headerNum="4" :config="config"></BaseHeader>

        <el-row type="flex" justify="center"
            :style="`background: url(${setHttps(bgimgs[Number(weekval) - 1])}) no-repeat center center / cover;height:400px;`">
            <el-col :lg="16" :md="18" :sm="20" :xs="22" class="h100 flex flex-center" style="height: 400px;">
                <div class="base_width" style="width: 100%;height: 400px;">
                    <el-row type="flex" justify="end" class="calendar_row_box" style="height: 400px;">
                        <el-col :lg="10" :md="14" :sm="18" :xs="24" class="calendar_row_room">

                            <div class="calendar_box">
                                <div class="calendar_box_title flex flex-start">
                                    <div class="calendar_box_title_link"></div>
                                    <div class="calendar_box_title_text">{{ dateval || endDate.split('-')[1] }}月预约日历</div>
                                </div>
                                <div class="calendar_box_room">
                                    <div class="week">
                                        <div v-for="i in 7" :key="i" class="week_item">{{ i === 1 ? 'Sun' : i === 2 ? 'Mon'
                                            : i === 3 ? 'Tue' : i === 4 ? 'Wen' : i === 5 ? 'Thu' : i === 6 ? 'Fri' : 'Sat'
                                        }}</div>
                                    </div>
                                    <div class="time_list">
                                        <div v-for="i in day_list.dayNum" :key="i" class="time_list_item">
                                            <el-image v-if="calendar_list.includes((i))" class="time_list_item_img"
                                                :src="require('@/assets/img/z1.png')"></el-image>
                                            <div class="time_list_item_text"
                                                :class="{ 'act': calendar_list.includes((i)) }">{{
                                                    i }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
        <el-row type="flex" justify="center">
            <el-col :lg="16" :md="18" :sm="20" :xs="22">
                <el-row class="base_width main_room">

                    <el-col :lg="5" :md="6" :sm="7" :xs="24">
                        <div class="info_bar hidden-xs-only">
                            <div class="r1 flex flex-center">
                                <el-image v-if="user_info.avatar" class="r1_img"
                                    :src="setHttps(user_info.avatar)"></el-image>
                            </div>
                            <div class="r2">{{ user_info.nickname }}</div>
                            <div class="r3">{{ user_info.phone }}</div>
                            <div class="tab_list">
                                <div v-for="(item, index) in tab_tree_my" :key="index" @click.stop="tabClick(index)"
                                    class="tab_list_item sethand">
                                    <div class="tab_list_item_link" :class="{ 'act': tab_num === index }"></div>
                                    <div class="tab_list_item_right">
                                        <el-image class="tab_list_item_icon"
                                            :src="require(`@/assets/img/t${index}.png`)"></el-image>
                                        <div class="tab_list_item_text" :class="{ 'act': tab_num === index }">{{ item.title
                                        }}
                                        </div>
                                        <el-image v-if="tab_num === index" class="tab_list_item_rbtn"
                                            :src="require('@/assets/img/r2b.png')"></el-image>
                                        <el-image v-else class="tab_list_item_rbtn"
                                            :src="require('@/assets/img/r2a.png')"></el-image>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="info_bar_set_tab hidden-sm-and-up">
                            <div class="info_bar_set_tab_item sethand" v-for="(item, index) in tab_tree_my" :key="index"
                                @click.stop="tabClick(index)" :class="{ 'act': tab_num === index }">{{ item.title }}</div>
                        </div>
                    </el-col>
                    <el-col :lg="19" :md="18" :sm="17" :xs="24" class="cont_bar">
                        <!-- 合作伙伴 -->
                        <div v-if="hb_show === true && tab_num === 5">
                            <div class="cont_bar_title_hb">
                                <div class="cont_bar_title_item">
                                    <div class="cont_bar_title_top">企业介绍</div>
                                    <div class="cont_bar_title_bot"></div>
                                </div>
                                <div class="hb_mbx_box hidden-sm-and-down">
                                    <el-breadcrumb separator-class="el-icon-arrow-right" class="flex flex-end hb_mbx">
                                        <el-breadcrumb-item class="flex flex-end hb_mbx_right_item">
                                            <el-image class="hb_mbx_right_item_icon"
                                                :src="require('@/assets/img/home1.png')"></el-image>
                                            <div class="hb_mbx_right_item_text" @click.stop="backMy">个人中心</div>
                                        </el-breadcrumb-item>
                                        <el-breadcrumb-item class="flex flex-end hb_mbx_right_item">
                                            <div @click.stop="backHb">合作伙伴</div>
                                        </el-breadcrumb-item>
                                        <el-breadcrumb-item
                                            class="flex flex-end hb_mbx_right_item">企业介绍</el-breadcrumb-item>
                                    </el-breadcrumb>
                                </div>

                            </div>

                            <div class="cont_bar_box" v-loading="loading">
                                <div v-html="editContent(hb_info)"></div>
                            </div>


                        </div>
                        <!-- 非合作伙伴 -->
                        <div v-else>

                            <div class="cont_bar_title flex flex-start">
                                <div class="cont_bar_title_item">
                                    <div class="cont_bar_title_top">{{ tab_tree_my[tab_num].title }}</div>
                                    <div class="cont_bar_title_bot"></div>
                                </div>
                            </div>


                            <!-- 副tab -->
                            <div class="cont_bar_box" v-loading="loading">
                                <!-- 放在这里是为了副tab与布局匹配 -->
                                <!-- 预约记录副tab -->
                                <div v-if="[1].includes(tab_num)">
                                    <div v-if="tab_tree_my[tab_num].tab.length" class="cont_bar_box_tab">
                                        <div v-for="(item, index) in tab_tree_my[tab_num].tab" :key="index"
                                            @click.stop="ftabClick(index)" class="cont_bar_box_tab_item sethand">
                                            <div class="cont_bar_box_tab_item_top"
                                                :class="{ 'act': ftab_num_make === index }">
                                                {{
                                                    item }}
                                            </div>
                                            <div class="cont_bar_box_tab_item_bot"
                                                :class="{ 'act': ftab_num_make === index }">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 我的收藏副tab -->
                                <div v-if="[3].includes(tab_num)">
                                    <div v-if="tab_tree_my[tab_num].tab.length" class="cont_bar_box_tab">
                                        <div v-for="(item, index) in tab_tree_my[tab_num].tab" :key="index"
                                            @click.stop="ftabClick(index)" class="cont_bar_box_tab_item sethand">
                                            <div class="cont_bar_box_tab_item_top"
                                                :class="{ 'act': ftab_num_sc === index }">{{
                                                    item
                                                }}
                                            </div>
                                            <div class="cont_bar_box_tab_item_bot"
                                                :class="{ 'act': ftab_num_sc === index }">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 基本信息 -->
                                <el-row>
                                    <el-col :lg="12" :md="16" :sm="20" :xs="24">
                                        <div v-if="[0].includes(tab_num)" class="jb_card">
                                            <el-row class="jb_card_item flex flex-center">
                                                <el-col :lg="6" :md="8" :sm="10" :xs="10">
                                                    <div class="jb_card_item_title">用户头像</div>
                                                </el-col>
                                                <el-col :lg="18" :md="16" :sm="14" :xs="14">
                                                    <div class="jb_card_item_cont jb_card_r1" @click.stop="editAvatar">
                                                        <div class="jb_card_r1_img_box sethand">
                                                            <el-image v-if="user_info.avatar" @click.stop="editAvatar"
                                                                class="jb_card_r1_img"
                                                                :src="setHttps(user_info.avatar)"></el-image>
                                                            <input ref="file_avatar" style="display: none" @change="optImg"
                                                                type="file">
                                                        </div>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                            <el-row class="jb_card_item flex flex-start">
                                                <el-col :lg="6" :md="8" :sm="10" :xs="10">
                                                    <div class="jb_card_item_title">昵称</div>
                                                </el-col>
                                                <el-col :lg="18" :md="16" :sm="14" :xs="14">
                                                    <el-row class="jb_card_item_cont jb_card_r2 flex flex-start">
                                                        <el-col :lg="19" :md="19" :sm="19" :xs="18">
                                                            <div class="jb_card_r2_ipt">{{ user_info.nickname || '-' }}
                                                            </div>
                                                        </el-col>
                                                        <el-col :lg="5" :md="5" :sm="5" :xs="6">
                                                            <div class="jb_card_r2_btn sethand" @click.stop="setname">修改
                                                            </div>
                                                        </el-col>
                                                    </el-row>
                                                </el-col>
                                            </el-row>
                                            <el-row class="jb_card_item flex flex-start">
                                                <el-col :lg="6" :md="8" :sm="10" :xs="10">
                                                    <div class="jb_card_item_title">手机号</div>
                                                </el-col>
                                                <el-col :lg="18" :md="16" :sm="14" :xs="14">
                                                    <el-row class="jb_card_item_cont jb_card_r2 flex flex-start">
                                                        <el-col :lg="19" :md="19" :sm="19" :xs="18">
                                                            <div class="jb_card_r2_ipt">{{ user_info.phone || '-' }}</div>
                                                        </el-col>
                                                        <el-col :lg="5" :md="5" :sm="5" :xs="6">
                                                            <div class="jb_card_r2_btn sethand" @click.stop="showSetPhone">
                                                                修改</div>
                                                        </el-col>
                                                    </el-row>
                                                </el-col>
                                            </el-row>
                                            <el-row class="jb_card_item flex flex-center">
                                                <el-col :lg="6" :md="8" :sm="10" :xs="0"></el-col>
                                                <el-col :lg="18" :md="16" :sm="14" :xs="14">
                                                    <div class="outlogin" @click.stop="outLogin">退出登录</div>
                                                </el-col>
                                            </el-row>

                                        </div>
                                    </el-col>

                                </el-row>
                                <!-- 预约列表 -->
                                <div v-if="[1].includes(tab_num)" class="make_card">

                                    <!-- 列表 -->
                                    <div v-if="setItemsMake.length">
                                        <div class="make_card_list">
                                            <div v-for="(item, index) in setItemsMake"
                                                @click.stop="jumporder(item.reservation_sn)" :key="index"
                                                class="make_card_list_item sethand">
                                                <div class="make_card_list_item_r1">
                                                    <div class="make_card_list_item_r1_text">入仓单号</div>
                                                    <div v-if="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(item.status)"
                                                        class="make_card_list_item_r1_state">{{ item.status_name }}</div>
                                                    <div v-else class="make_card_list_item_r1_state ywc">{{ item.status_name
                                                    }}
                                                    </div>
                                                </div>
                                                <div class="make_card_list_item_r2 hidden-xs-only">{{ item.reservation_sn }}
                                                </div>
                                                <div class="make_card_list_item_r2 hidden-sm-and-up">{{ item.reservation_sn
                                                }}</div>
                                                <div class="make_card_list_item_r3 hidden-xs-only">{{
                                                    formatTime(item.create_time) }}</div>
                                                <div class="make_card_list_item_r3 hidden-sm-and-up">{{
                                                    formatTime(item.create_time) }}</div>
                                            </div>
                                        </div>
                                        <div class="tips">已经到底了~</div>
                                    </div>
                                    <div v-else>
                                        <el-empty v-if="!loading" description="暂无数据"></el-empty>
                                    </div>
                                    <!-- 分页 -->
                                    <div v-if="setItemsMake.length" class="pageing_box flex flex-center">
                                        <el-pagination small :pager-count="5" background layout="prev, pager, next"
                                            :total="make_list.length" :page-size="pageSize_make"
                                            :current-page="currentPage_make" @current-change="makeChange"
                                            class="custom_pagination"></el-pagination>
                                    </div>




                                </div>
                                <!-- 查询列表 -->
                                <div v-if="[2].includes(tab_num)" class="search_card">
                                    <!-- 列表 -->
                                    <div v-if="setItemsSearch.length">
                                        <div class="search_card_list">
                                            <div v-for="(item, index) in setItemsSearch" @click.stop="jumporder2(item)"
                                                :key="index" class="search_card_list_item sethand">
                                                <div class="search_card_list_item_r1">
                                                    <div class="search_card_list_item_r1_text">{{ item.search_type === 1 ?
                                                        '入仓单号' :
                                                        '车牌号'
                                                    }}</div>
                                                    <div class="search_card_list_item_r1_state">
                                                        <el-image class="search_card_list_item_r1_state_icon"
                                                            :src="require('@/assets/img/m4.png')"></el-image>
                                                        <div class="timer1 hidden-xs-only">查询时间：{{
                                                            formatTime(item.search_time) }}</div>
                                                        <div class="timer1 hidden-sm-and-up">查询时间：{{
                                                            formatTime(item.search_time) }}</div>
                                                    </div>
                                                </div>
                                                <div class="search_card_list_item_r2 hidden-xs-only">{{ item.search_key }}
                                                </div>
                                                <div class="search_card_list_item_r2 hidden-sm-and-up">{{ item.search_key }}
                                                </div>
                                                <div class="search_card_list_item_r3 hidden-xs-only">{{
                                                    formatTime(item.create_time) }}</div>
                                                <div class="search_card_list_item_r3 hidden-sm-and-up">{{
                                                    formatTime(item.create_time) }}</div>
                                            </div>
                                        </div>
                                        <div class="tips">已经到底了~</div>
                                    </div>
                                    <div v-else>
                                        <el-empty v-if="!loading" description="暂无数据"></el-empty>
                                    </div>
                                    <!-- 分页 -->
                                    <div v-if="setItemsSearch.length" class="pageing_box flex flex-center">
                                        <el-pagination small :pager-count="5" background layout="prev, pager, next"
                                            :total="search_list.length" :page-size="pageSize_search"
                                            :current-page="currentPage_search" @current-change="searchChange"
                                            class="custom_pagination"></el-pagination>
                                    </div>
                                </div>
                                <!-- 我的收藏 -->
                                <div v-if="[3].includes(tab_num)" class="sc_card">

                                    <div v-if="ftab_num_sc === 0">
                                        <div v-if="setItemsSc.length">
                                            <el-row :gutter="13" class="tab_list_box">
                                                <el-col :lg="6" :md="8" :sm="12" :xs="12"
                                                    v-for="(item, index) in setItemsSc" :key="index"
                                                    style="margin-bottom: 20px;">
                                                    <indexItem :obj="item" :currenttab="currentTab"></indexItem>
                                                </el-col>
                                            </el-row>
                                            <div class="tips">已经到底了~</div>
                                        </div>
                                        <div v-else>
                                            <el-empty v-if="!loading" description="暂无数据"></el-empty>
                                        </div>
                                        <div v-if="setItemsSc.length" class="pageing_box flex flex-center">
                                            <el-pagination small :pager-count="5" background layout="prev, pager, next"
                                                :total="sc_list.length" :page-size="pageSize_sc"
                                                :current-page="currentPage_sc" @current-change="scChange"
                                                class="custom_pagination"></el-pagination>
                                        </div>
                                    </div>
                                    <div v-if="ftab_num_sc === 1">
                                        <div v-if="setItemsSc.length">
                                            <el-row :gutter="13" class="tab_list_box">
                                                <el-col :lg="8" :md="12" :sm="24" :xs="24"
                                                    v-for="(item, index) in setItemsSc" :key="index"
                                                    style="margin-bottom: 20px;">
                                                    <classifyItem :obj="item" @getList3="ftabClick(2)"
                                                        :currenttab="currentTab"></classifyItem>
                                                </el-col>
                                            </el-row>
                                            <div class="tips">已经到底了~</div>
                                        </div>
                                        <div v-else>
                                            <el-empty v-if="!loading" description="暂无数据"></el-empty>
                                        </div>
                                        <div v-if="setItemsSc.length" class="pageing_box flex flex-center">
                                            <el-pagination small :pager-count="5" background layout="prev, pager, next"
                                                :total="sc_list.length" :page-size="pageSize_sc"
                                                :current-page="currentPage_sc" @current-change="scChange"
                                                class="custom_pagination"></el-pagination>
                                        </div>
                                    </div>


                                </div>
                                <!-- 帮助中心 -->
                                <div v-if="[4].includes(tab_num)" class="help_card">
                                    <!-- 列表 -->
                                    <div v-if="setItemsHelp.length">
                                        <div class="help_card_list">
                                            <div v-for="(item, index) in setItemsHelp" :key="index"
                                                class="help_card_list_item">
                                                <div class="help_card_list_item_r1">
                                                    <el-image class="help_card_list_item_r1_icon"
                                                        :src="require('@/assets/img/m5.png')"></el-image>
                                                    <div class="help_card_list_item_r1_text">{{ item.name }}</div>
                                                </div>
                                                <div class="help_card_list_item_r2">{{ item.desc }}</div>
                                            </div>
                                        </div>
                                        <div class="tips">已经到底了~</div>
                                    </div>
                                    <div v-else>
                                        <el-empty v-if="!loading" description="暂无数据"></el-empty>
                                    </div>
                                    <!-- 分页 -->
                                    <div v-if="setItemsHelp.length" class="pageing_box flex flex-center">
                                        <el-pagination small :pager-count="5" background layout="prev, pager, next"
                                            :total="help_list.length" :page-size="pageSize_help"
                                            :current-page="currentPage_help" @current-change="helpChange"
                                            class="custom_pagination"></el-pagination>
                                    </div>
                                </div>
                                <!-- 合作伙伴 -->
                                <div v-if="[5].includes(tab_num)" class="hzhb_card">
                                    <!-- 列表 -->
                                    <div v-if="hblist.length">
                                        <el-row :gutter="13" class="hzhb_card_list">
                                            <el-col :lg="3" :md="3" :sm="4" :xs="6" v-for="(item, index) in hblist"
                                                :key="index" class="hzhb_card_list_item" style="margin-bottom: 13px;">
                                                <div class="hzhb_card_list_item_top sethand" @click.stop="hbClick(item.id)">
                                                    <el-image class="hzhb_card_list_item_top_img"
                                                        :src="setHttps(item.image)"></el-image>
                                                </div>
                                                <div class="hzhb_card_list_item_bot">{{ item.name }}</div>
                                            </el-col>
                                        </el-row>
                                        <div class="tips">已经到底了~</div>
                                    </div>
                                    <div v-else>
                                        <el-empty v-if="!loading" description="暂无数据"></el-empty>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </el-col>


                </el-row>
            </el-col>
        </el-row>

        <el-dialog custom-class="show_set_card" title="昵称修改" :visible.sync="showSet2">
            <div class="setcont">
                <el-row :gutter="20" type="flex" justify="space-between" align="middle" class="ipt_phone">
                    <el-col :lg="4" :md="6" :sm="8" :xs="0">
                        <div class="ipt_phone_title">修改昵称</div>
                    </el-col>
                    <el-col :lg="20" :md="18" :sm="16" :xs="24">
                        <el-input class="ipt_phone_ipt" placeholder="请输入昵称" v-model="setnickname.nickname" :maxlength="11"
                            clearable></el-input>
                    </el-col>
                </el-row>
                <el-row :gutter="20" type="flex" justify="center" align="middle" class="btn_box">
                    <el-col :lg="5" :md="7" :sm="9" :xs="10" style="padding-bottom: 10px;">
                        <div class="btn_box_btn" @click.stop="showSet2 = false">取消</div>
                    </el-col>
                    <el-col :lg="5" :md="7" :sm="9" :xs="14" style="padding-bottom: 10px;">
                        <div class="btn_box_btn act" @click.stop="sendSetnickname">确认修改</div>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>

        <el-dialog custom-class="show_set_card" title="手机号修改" :visible.sync="showSet">
            <div class="setcont">
                <el-row :gutter="20" type="flex" justify="space-between" align="middle" class="ipt_phone">
                    <el-col :lg="4" :md="6" :sm="8" :xs="0">
                        <div class="ipt_phone_title">手机号</div>
                    </el-col>
                    <el-col :lg="20" :md="18" :sm="16" :xs="24">
                        <el-input placeholder="请输入手机号" v-model="setphone.phone" :maxlength="11" clearable></el-input>
                    </el-col>
                </el-row>

                <div class="ipt_code">
                    <el-row :gutter="20" type="flex" justify="space-between" align="middle" class="ipt_phone">
                        <el-col :lg="4" :md="6" :sm="8" :xs="0">
                            <div class="ipt_code_title">验证码</div>
                        </el-col>
                        <el-col :lg="20" :md="18" :sm="16" :xs="24">
                            <div class="ipt_code_cont">
                                <el-input placeholder="请输入验证码" v-model="setphone.code" :maxlength="8" clearable>
                                </el-input>
                                <div v-if="cd_state === 1" class="ipt_box_cd hidden-xs-only" @click.stop="getCode">获取验证码
                                </div>
                                <div v-if="cd_state === 2" class="ipt_box_cd hidden-xs-only">{{ cd_time }}s</div>
                                <div v-if="cd_state === 3" class="ipt_box_cd hidden-xs-only" @click.stop="getCode">重新获取
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="ipt_code_cont2 hidden-sm-and-up">
                    <div v-if="cd_state === 1" class="ipt_box_cd" @click.stop="getCode">获取验证码</div>
                    <div v-if="cd_state === 2" class="ipt_box_cd">{{ cd_time }}s</div>
                    <div v-if="cd_state === 3" class="ipt_box_cd" @click.stop="getCode">重新获取</div>
                </div>
                <el-row :gutter="20" type="flex" justify="center" align="middle" class="btn_box">
                    <el-col :lg="5" :md="7" :sm="9" :xs="10" style="padding-bottom: 10px;">
                        <div class="btn_box_btn" @click.stop="showSet = false">取消</div>
                    </el-col>
                    <el-col :lg="5" :md="7" :sm="9" :xs="14" style="padding-bottom: 10px;">
                        <div class="btn_box_btn act" @click.stop="sendSetphone">确认修改</div>
                    </el-col>
                </el-row>

            </div>
        </el-dialog>
    </div>
</template>
<script>
module.exports = {
    data: function () {
        return {
            loading: false,

            currentTab: 4,

            showSet: false,
            showSet2: false,
            cd_time: 60,//倒计时时间
            cd_state: 1, //1默认2计时3重发
            cd_run: null,


            tab_tree_my: [],
            tab_num: 0,
            cont_tab: [],
            ftab_num_make: 0,//预约小tab值
            ftab_num_sc: 0,//收藏小tab值

            user_info: '',
            token: '',
            config: '',

            setavatar: {
                avatar: ''
            },
            setnickname: {
                nickname: ''
            },
            setphone: {
                phone: '',
                code: ''
            },

            bgimgs: [],//每天的背景数组

            date: '',
            dateval: '',
            endDate: '',
            day_list: '', //当月信息
            calendar_list: [], //签到的数据

            weekval: '',//今天周几 1周1-2周2

            //合作伙伴
            hblist: [],
            hb_info: '',
            hb_show: false,

            //预约记录
            make_list: [],
            currentPage_make: 1,
            pageSize_make: 3,
            setItemsMake: '',

            //查询记录
            search_list: [],
            currentPage_search: 1,
            pageSize_search: 3,
            setItemsSearch: '',

            //我的收藏
            sc_list: [],
            currentPage_sc: 1,
            pageSize_sc: 10,
            setItemsSc: '',

            //帮助中心
            help_list: [],
            currentPage_help: 1,
            pageSize_help: 7,
            setItemsHelp: '',
        }
    },
    created() {
        this.tab_tree_my = [
            {
                title: '基本信息',
                tab: []
            },
            {
                title: '预约记录',
                tab: ['全部', '待送仓', '已完成']
            },
            {
                title: '查询记录',
                tab: []
            },
            {
                title: '我的收藏',
                tab: ['航司产品', '资源推荐']
            },
            {
                title: '帮助中心',
                tab: []
            },
            {
                title: '合作伙伴',
                tab: []
            },
        ]
        this.refresh()


    },
    mounted() {
        console.log('================my================')
        this.endDate = this.my_time('ym')
        this.getConfig()//获取配置信息

    },

    methods: {

        // 退出登录
        outLogin() {
            this.menu_state = false
            localStorage.setItem('token', '');
            localStorage.setItem('userInfo', '');
            sessionStorage.setItem('token', '');
            sessionStorage.setItem('userInfo', '');
            this.token = ''
            this.$router.replace({ name: 'login' });
        },
        // 查询记录的跳转
        jumporder2(info) {
            let href = this.$router.resolve({ path: '/searchDetail', query: { infokeys: info.search_key, infotype: info.search_type, tabstate: true, currenttab: 4 } }).href;
            window.open(href, "_blank");
        },
        // 跳转查询结果页-预约记录带入仓单号
        jumporder(val) {
            let href = this.$router.resolve({ path: '/searchDetail', query: { infokeys: val, infotype: 2, tabstate: true, currenttab: 4 } }).href;
            window.open(href, "_blank");
        },
        // 手机号确认修改
        sendSetphone() {
            if (!(this.setphone.phone)) {
                this.$message.error('请输入手机号');
                return;
            }
            if (!(this.setphone.code)) {
                this.$message.error('请输入验证码');
                return;
            }
            if (!this.tel_reg(this.setphone.phone)) {
                this.$message.error('请输入正确的手机号');
                return;
            }
            this.$('/api/User/changePhone', this.setphone).then(() => {
                this.$message({
                    message: '修改成功,请重新登录',
                    type: 'success'
                });
                localStorage.setItem('token', '');
                localStorage.setItem('userInfo', '');
                sessionStorage.setItem('token', '');
                sessionStorage.setItem('userInfo', '');
                this.token = ''
                this.$router.replace({ name: 'home' });
            }).catch(cat => {
                this.$message.error(cat.msg);
            })
        },
        // 获取验证码
        getCode() {
            if (!(this.setphone.phone)) {
                this.$message.error('请输入手机号');
                return;
            }
            if (!this.tel_reg(this.setphone.phone)) {
                this.$message.error('请输入正确的手机号');
                return;
            }
            this.loading = true
            this.$('/api/User/sendSms', { phone: this.setphone.phone }).then(res => {
                this.cd_time = res.data.second_second ? Number(res.data.second_second) : 60;
                this.cd_state = 2
                this.cd_run = setInterval(() => {
                    this.cd_time = this.cd_time - 1;
                    if (this.cd_time < 0) {
                        this.cd_state = 3
                        clearInterval(this.t);
                    }
                }, 1000)
            }).finally(() => {
                this.loading = false
            })
        },
        // 修改手机号
        showSetPhone() {
            this.showSet = !this.showSet
        },
        // 设置昵称
        sendSetnickname() {
            this.$('/api/User/changeNickname', this.setnickname).then(res => {
                this.$message({
                    type: 'success',
                    message: res.msg
                });

                this.getUsetInfo();
            }).catch(() => {
                this.$message.error('修改失败')
            }).finally(() => {
                this.showSet2 = false
            });
        },
        // 设置昵称
        setname() {
            this.showSet2 = !this.showSet2
        },
        // 选图片
        optImg(event) {
            let fileInput = event.target;
            let files = fileInput.files;

            if (files.length > 0) {
                let selectedFile = files[0];
                // console.log('头像', selectedFile)
                let formData = new FormData();
                formData.append('upload', selectedFile); // 将文件添加到FormData

                // 上传
                this.$axios.post('/api/Index/upload', formData)
                    .then(response => {
                        // 上传成功的处理逻辑
                        // console.log('文件上传成功', response.data.data);
                        this.$('/api/User/changeAvatar', {
                            avatar: this.setHttps(response.data.data)
                        }).then(() => {
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });
                            this.getUsetInfo();

                        })

                    })
                    .catch(error => {
                        // 上传失败的处理逻辑
                        console.error('文件上传失败', error);
                    });

            }
        },
        // 修改头像
        editAvatar() {
            // console.log('触发修改头像')
            this.$refs.file_avatar.click();
        },
        // 合作伙伴点击个人中心
        backMy() {
            this.tabClick(0)
        },
        // 合作伙伴点击合作伙伴
        backHb() {
            this.hb_show = false
        },
        // ftab切换
        ftabClick(num) {
            if (this.tab_num === 1) {
                this.ftab_num_make = num
                this.make_list = []
                this.currentPage_make = 1
                this.pageSize_make = 3
                this.setItemsMake = ''
                this.getMakeList()//预约记录
            }
            if (this.tab_num === 3) {
                this.ftab_num_sc = num
                this.sc_list = []
                this.currentPage_sc = 1
                this.pageSize_sc = 10
                this.setItemsSc = ''
                this.getScList()//预约记录
            }

        },
        // tab切换
        tabClick(num) {
            this.tab_num = num
            if (!(num === 5)) {
                this.hb_show = false
            } else {
                this.hb_info = ''
                this.hb_show = false
            }
            if (num === 1) {
                this.ftab_num_make = 0//大tab切换每次重置小tab
                this.make_list = []
                this.currentPage_make = 1
                this.pageSize_make = 3
                this.setItemsMake = ''
                this.getMakeList()//预约记录
            }
            if (num === 2) {
                this.ftab_num_search = 0//大tab切换每次重置小tab
                this.search_list = []
                this.currentPage_search = 1
                this.pageSize_search = 3
                this.setItemsSearch = ''
                this.getSearchList()//查询记录
            }
            if (num === 3) {
                this.ftab_num_sc = 0//大tab切换每次重置小tab
                this.sc_list = []
                this.currentPage_sc = 1
                this.pageSize_sc = 3
                this.setItemsSc = ''
                this.getScList()//我的收藏
            }
            if (num === 4) {
                this.help_list = []
                this.currentPage_help = 1
                this.pageSize_help = 7
                this.setItemsHelp = ''
                this.getHelpList()//帮助中心
            }
        },
        // 点击合作伙伴
        hbClick(id) {
            this.hb_info = ''
            this.loading = true
            this.hb_show = true
            this.$('/api/Index/getContent', {
                id: id,
                table: 'links'
            }).then(res => {
                this.hb_info = res.data
                // console.log('合作伙伴---展示', res.data)
            }).finally(() => {
                this.loading = false
            })
        },
        // 获取合作伙伴
        getHbList() {
            this.$('/api/Index/getLinksList').then(res => {
                // console.log('合作伙伴---', res.data)
                // 每组的大小
                this.hblist = res.data
                // console.log('=====', this.hblist)
            })
        },
        // 帮助中心
        getHelpList() {
            this.loading = true
            this.$('/api/Index/getHelpsList').then(res => {
                this.help_list = res.data
                let startIndex = (this.currentPage_help - 1) * this.pageSize_help;
                let endIndex = startIndex + this.pageSize_help;
                this.setItemsHelp = this.help_list.slice(startIndex, endIndex);
            }).finally(() => {
                this.loading = false
            })
        },
        // 切换分页-帮助中心
        helpChange(num) {
            this.loading = true
            this.currentPage_help = num;
            // 根据当前页和分页大小计算要显示的数据范围
            let startIndex = (this.currentPage_help - 1) * this.pageSize_help;
            let endIndex = startIndex + this.pageSize_help;
            this.setItemsHelp = this.help_list.slice(startIndex, endIndex);
            this.loading = false
        },
        // 我的收藏
        getScList() {
            this.loading = true
            this.$('/api/User/getCollectList', {
                obj_type: (this.ftab_num_sc + 1)
            }).then(res => {
                this.sc_list = res.data
                let startIndex = (this.currentPage_sc - 1) * this.pageSize_sc;
                let endIndex = startIndex + this.pageSize_sc;
                this.setItemsSc = this.sc_list.slice(startIndex, endIndex);
            }).finally(() => {
                this.loading = false
            })
        },
        // 切换分页-我的收藏
        scChange(num) {
            this.loading = true
            this.currentPage_sc = num;
            // 根据当前页和分页大小计算要显示的数据范围
            let startIndex = (this.currentPage_sc - 1) * this.pageSize_sc;
            let endIndex = startIndex + this.pageSize_sc;
            this.setItemsSc = this.sc_list.slice(startIndex, endIndex);
            this.loading = false
        },
        // 查询记录
        getSearchList() {
            this.loading = true
            this.$('/api/User/getSearchLog').then(res => {
                this.search_list = res.data
                let startIndex = (this.currentPage_search - 1) * this.pageSize_search;
                let endIndex = startIndex + this.pageSize_search;
                this.setItemsSearch = this.search_list.slice(startIndex, endIndex);
            }).finally(() => {
                this.loading = false
            })
        },
        // 切换分页-查询记录
        searchChange(num) {
            this.loading = true
            this.currentPage_search = num;
            // 根据当前页和分页大小计算要显示的数据范围
            let startIndex = (this.currentPage_search - 1) * this.pageSize_search;
            let endIndex = startIndex + this.pageSize_search;
            this.setItemsSearch = this.search_list.slice(startIndex, endIndex);
            this.loading = false
        },
        // 预约记录
        getMakeList() {
            let makeType = 0
            if (this.ftab_num_make === 0) { makeType = 0 }
            if (this.ftab_num_make === 1) { makeType = 2 }
            if (this.ftab_num_make === 2) { makeType = 3 }
            this.loading = true
            this.$('/api/User/getReservationList', {
                status: makeType
            }).then(res => {
                this.make_list = res.data
                let startIndex = (this.currentPage_make - 1) * this.pageSize_make;
                let endIndex = startIndex + this.pageSize_make;
                this.setItemsMake = this.make_list.slice(startIndex, endIndex);
            }).finally(() => {
                this.loading = false
            })
        },
        // 切换分页-预约记录
        makeChange(num) {
            this.loading = true
            this.currentPage_make = num;
            // 根据当前页和分页大小计算要显示的数据范围
            let startIndex = (this.currentPage_make - 1) * this.pageSize_make;
            let endIndex = startIndex + this.pageSize_make;
            this.setItemsMake = this.make_list.slice(startIndex, endIndex);
            this.loading = false
        },
        // 刷新
        refresh() {
            this.user_info = '';
            this.calendar_list = []
            this.day_list = this.getMonthInfo(this.my_time('ym')); //获取该月信息
            this.dateval = this.my_time('ym').split('-')[1];
            this.getweek();
            this.getHbList();
            this.getCalendar();
            this.getUsetInfo();
        },
        // 选择的月份
        dateChange(e) {
            this.calendar_list = []
            // console.log('---', e.detail.value)
            if (e.detail.value) {
                this.day_list = this.getMonthInfo(e.detail.value); //获取该月信息
                this.date = e.detail.value
                this.dateval = e.detail.value.split('-')[1]
                this.getCalendar()
            }
        },

        // 配置信息
        getConfig() {
            console.log('2222')
            this.$('/api/Index/getBaseConfig').then(res => {
                console.log('配置信息1', res.data)
                this.config = JSON.parse(JSON.stringify(res.data))
                localStorage.setItem('config', JSON.stringify(res.data));
                console.log('配置信息2', res.data)
                // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
                document.head.querySelectorAll("link").forEach(link => {
                    if (link.rel === "icon") {
                        link.parentNode?.removeChild(link);
                    }
                });
                const link = document.createElement('link');
                link.rel = "icon";
                link.href = this.setHttps(res.data.icon); // 将网络图片的URL赋给href属性
                document.head.appendChild(link);
            })
        },
        //今天周几
        getweek() {
            // 创建一个表示当前日期的 Date 对象
            let today = new Date();
            // 获取今天是星期几（0 表示星期日，1 表示星期一，以此类推）
            let dayOfWeek = today.getDay();
            if (dayOfWeek === 0) {
                dayOfWeek = 7;
            }
            this.weekval = dayOfWeek
            // console.log('weekval', this.weekval)//0星期日 1星期一
            // 接口获取背景图
            this.$('/api/User/getBackgroundList').then(res => {
                this.bgimgs = res.data || []
                // console.log('this.bgimgs------', this.bgimgs)
            })

        },
        // 传入年月查询当月多少天
        getMonthInfo(time) {
            let year = time.split('-')[0];
            let month = time.split('-')[1];
            let date = new Date(year, month - 1, 1); // 构造一个表示指定年份和月份的日期对象
            // 获取该月的最后一天，这里先将日期设置为下个月的第0天，然后减去一天即为该月的最后一天
            let lastDay = new Date(year, month, 0);
            let firstDay = date.getDay(); // 获取第一天是星期几（0 表示星期日，1 表示星期一，以此类推）
            let dayNum = lastDay.getDate(); // 获取该月的天数
            return {
                dayNum,
                firstDay,
            };
        },
        // 获取getCalendar
        getCalendar() {
            this.$('/api/User/getReservationRecord', {
                time: this.date || this.my_time('ym')
            }).then(res => {
                // console.log('日历---', res)
                this.calendar_list = res.data.map(i => Number(i))
            })
        },
        // 用户信息
        getUsetInfo() {
            this.$('/api/User/getUserInfo').then(res => {
                // console.log('用户信息---', res.data)
                this.user_info = res.data
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.my_page {
    min-height: 100vh;
    background-color: #f6f6f6;

    .outlogin {
        width: 100%;
        border-radius: 5px;
        border: 1px solid #999;
        line-height: 34px;
        text-align: center;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #B9B9BD;
    }

    .setcont {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 15px;

        .ipt_phone {
            .ipt_phone_title {
                font-size: 13px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }
        }

        .ipt_code {
            .ipt_code_title {
                font-size: 13px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }

            .ipt_code_cont {
                width: 100%;
                position: relative;

                &::v-deep .el-input--suffix .el-input__inner {
                    padding-right: 5.20833vw;
                }

                .ipt_box_cd {
                    width: 100px;
                    line-height: 40px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 110;
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #CEB05A;
                    text-align: center;
                }
            }


        }

        .ipt_code_cont2 {
            width: 100%;

            &::v-deep .el-input--suffix .el-input__inner {
                padding-right: 5.20833vw;
            }

            .ipt_box_cd {
                width: 100px;
                line-height: 40px;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #CEB05A;
                text-align: center;
            }
        }

        .btn_box {
            .btn_box_btn {
                line-height: 2;
                border-radius: 5px 5px 5px 5px;
                opacity: 1;
                border: 1px solid #999999;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #B9B9BD;
                text-align: center;

                &.act {
                    color: #fff;
                    background: #CEB05A;
                    border: 1px solid #CEB05A;
                }
            }
        }
    }



    ::v-deep .el-dialog__wrapper .el-dialog {
        width: 540px;
        border-radius: 10px;
    }

    ::v-deep .el-dialog__header {
        text-align: center;
        border-bottom: 1px solid #eee;
    }

    .calendar_row_box {
        padding: 35px 0 25px;

        .calendar_row_room {
            padding-top: 87%;
            position: relative;

            .calendar_box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background-color: #fff;
                padding: 0 15px;
                border-radius: 10px;

                .calendar_box_title {
                    width: 100%;
                    height: 54px;

                    .calendar_box_title_link {
                        width: 3px;
                        height: 18px;
                        background-color: #ceb05a;
                        border-radius: 5px;
                    }

                    .calendar_box_title_text {
                        padding-left: 5px;
                        font-size: 16px;
                        font-family: PingFang SC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #191919;
                    }
                }

                .calendar_box_room {
                    width: 100%;

                    .week {
                        width: 100%;
                        height: 28px;

                        display: grid;
                        grid-template-columns: repeat(7, 1fr);
                        justify-items: center;
                        align-items: center;

                        border-radius: 5px;
                        background-color: #f9f9f9;
                        font-size: 11px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ADB2B1;

                    }

                    .time_list {
                        padding: 20rpx 0 0;
                        font-size: 22rpx;
                        font-family: DIN Alternate-Bold, DIN Alternate;
                        font-weight: bold;
                        color: #666666;

                        display: grid;
                        grid-template-columns: repeat(7, 1fr);
                        column-gap: 16rpx;
                        justify-items: center;
                        align-items: center;

                        .time_list_item {
                            width: 38px;
                            display: grid;
                            grid-template-columns: repeat(1, 1fr);
                            justify-items: center;
                            align-items: center;
                            height: 38px;

                            .time_list_item_img {
                                width: 20px;
                                height: 20px;

                            }

                            .time_list_item_text {
                                font-size: 11px;
                                font-family: DIN Alternate-Bold, DIN Alternate;
                                font-weight: bold;
                                color: #666666;

                                &.act {
                                    color: #D0B362;
                                }
                            }

                        }
                    }


                }
            }
        }
    }





    .main_room {
        padding: 0 0 80px;
        position: relative;
        top: -42px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        // display: grid;
        // grid-template-columns: 235px calc(100% - 235px - 20px);
        // column-gap: 20px;
        // justify-content: center;
        // align-items: flex-start;

        .info_bar {
            padding-top: 55px;
            min-height: 630px;
            background-color: #fff;
            border-radius: 10px;
            margin-bottom: 50px;
            margin-right: 20px;

            .r1 {
                width: 54px;
                height: 54px;
                margin: 0 auto;

                .ri_img {
                    width: 54px;
                    height: 54px;
                    border-radius: 50%;
                    margin: 0 auto;
                    overflow: hidden;

                }

                &::v-deep .el-image .el-image__inner {
                    width: 54px;
                    height: 54px;
                    border-radius: 50%;
                }
            }

            .r2 {
                padding: 8px 20px;
                font-size: 18px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #482221;
                text-align: center;
            }

            .r3 {
                padding: 0 20px;
                text-align: center;
                font-size: 13px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }

            .tab_list {
                padding: 50px 0;

                .tab_list_item {
                    border-bottom: 1px solid #eee;
                    padding: 0.88541vw 1.30208vw 0.88541vw 0;
                    display: grid;
                    grid-template-columns: 4px calc(100% - 4px - 19px);
                    column-gap: 19px;
                    justify-content: flex-start;
                    align-items: center;

                    .tab_list_item_link {
                        width: 4px;
                        height: 25px;
                        background-color: rgba(0, 0, 0, 0);

                        &.act {
                            background-color: #ceb05a;
                        }
                    }

                    .tab_list_item_right {
                        display: grid;
                        grid-template-columns: 24px calc(100% - 24px - 11px - 16px) 11px;
                        column-gap: 8px;
                        justify-content: flex-start;
                        align-items: center;

                        .tab_list_item_icon {
                            width: 24px;
                            height: 24px;
                        }

                        .tab_list_item_text {
                            font-size: 13px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #191919;

                            &.act {
                                color: #CEB05A;
                            }
                        }

                        .tab_list_item_rbtn {
                            width: 11px;
                            height: 11px;
                        }
                    }



                }
            }


        }

        @media (max-width:768px) {
            .info_bar {
                margin-right: 0;
            }
        }

        .info_bar_set_tab {

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 10px;
            column-gap: 10px;
            align-items: center;
            padding-bottom: 10px;

            .info_bar_set_tab_item {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                justify-items: center;
                align-items: center;
                background-color: #fff;
                padding: 20px;
                border-radius: 10px;

                &.act {
                    background-color: #CEB05A;
                    color: #fff;
                }
            }
        }




        .cont_bar {
            min-height: 630px;
            background-color: #fff;
            border-radius: 10px;

            // 伙伴头
            .cont_bar_title_hb {
                width: 100%;
                height: 60px;
                border-bottom: 1px solid #eee;
                padding: 0 25px;
                display: grid;
                grid-template-columns: auto auto;
                justify-content: space-between;
                align-items: flex-end;

                .cont_bar_title_item {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    row-gap: 3px;
                    justify-items: center;
                    align-items: flex-end;

                    .cont_bar_title_top {
                        font-size: 20px;
                        font-family: PingFang SC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #482221;
                    }

                    .cont_bar_title_bot {
                        width: 35px;
                        height: 3px;
                        border-radius: 5px;
                        background-color: #ceb05a;
                    }
                }

                .hb_mbx_box {
                    height: 100%;
                }

                .hb_mbx {
                    height: 100%;

                    &::v-deep .el-breadcrumb__inner {
                        height: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }

                    .hb_mbx_right_item {
                        display: flex;
                        justify-items: flex-end;
                        align-items: center;
                        font-size: 16px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #6C757D;

                        &::v-deep .el-breadcrumb__inner.is-link {
                            color: #6C757D;
                            font-weight: 400;
                        }

                        &:last-child::v-deep .el-breadcrumb__inner {
                            color: #000;
                        }

                        .hb_mbx_right_item_icon {
                            width: 15px;
                            height: 15px;
                            margin-right: 7px;
                        }


                    }
                }
            }

            // 公共头
            .cont_bar_title {
                width: 100%;
                height: 60px;
                border-bottom: 1px solid #eee;
                padding: 0 25px;
                align-items: flex-end;

                .cont_bar_title_item {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    row-gap: 3px;
                    justify-items: center;
                    align-items: flex-end;

                    .cont_bar_title_top {
                        font-size: 20px;
                        font-family: PingFang SC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #482221;
                    }

                    .cont_bar_title_bot {
                        width: 35px;
                        height: 3px;
                        border-radius: 5px;
                        background-color: #ceb05a;
                    }
                }

            }

            // 展示区
            .cont_bar_box {
                padding: 20px 25px 40px;

                // 展示区tab样式
                .cont_bar_box_tab {
                    padding-bottom: 14px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;

                    .cont_bar_box_tab_item {
                        padding-right: 30px;
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 3px;
                        justify-items: center;
                        align-items: flex-end;

                        .cont_bar_box_tab_item_top {
                            font-size: 13px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;

                            &.act {
                                color: #666666;
                                font-weight: 600;
                                color: #482221;
                            }
                        }

                        .cont_bar_box_tab_item_bot {
                            width: 20px;
                            height: 3px;
                            border-radius: 5px;
                            background-color: rgba(0, 0, 0, 0);

                            &.act {
                                background-color: #ceb05a;
                            }
                        }

                    }
                }

                // 基本信息
                .jb_card {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    row-gap: 25px;
                    align-content: flex-start;

                    .jb_card_item {
                        // display: grid;
                        // grid-template-columns: 3.64583vw calc(100% - 3.64583vw - 200px);
                        // column-gap: 20px;
                        // justify-content: flex-start;
                        // align-items: center;

                        .jb_card_item_title {
                            font-size: 13px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                        }

                        .jb_card_item_cont {
                            &.jb_card_r1 {

                                .jb_card_r1_img_box {
                                    width: 4.94791vw;
                                    height: 4.94791vw;
                                    min-height: 40px;
                                    min-width: 40px;
                                    border-radius: 50%;
                                    position: relative;
                                    overflow: hidden;


                                    &:hover::before {
                                        content: "修改头像";
                                        width: 100%;
                                        height: 100%;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        z-index: 10;
                                        font-size: 10px;
                                        font-family: PingFang SC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #FFFFFF;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        background-color: rgba(51, 51, 51, 0.5);

                                    }

                                    .jb_card_r1_img {
                                        width: 4.94791vw;
                                        height: 4.94791vw;
                                        border-radius: 50%;
                                        min-height: 40px;
                                        min-width: 40px;
                                    }
                                }
                            }

                            &.jb_card_r2 {
                                width: 100%;
                                height: 45px;
                                border-radius: 5px;
                                border: 1px solid #999;
                                padding: 0 15px;


                                .jb_card_r2_ipt {
                                    font-size: 14px;
                                    font-family: PingFang SC-Medium, PingFang SC;
                                    font-weight: 500;
                                }

                                .jb_card_r2_btn {
                                    font-size: 14px;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #999;
                                }
                            }

                        }
                    }

                }

                // 查询记录
                .search_card {
                    .search_card_list {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 10px;
                        align-content: flex-start;

                        .search_card_list_item {
                            border-radius: 10px;
                            border: 1px solid #eee;
                            padding: 10px;
                            display: grid;
                            grid-template-columns: repeat(1, 1fr);
                            row-gap: 5px;
                            align-content: flex-start;

                            .search_card_list_item_r1 {
                                display: grid;
                                grid-template-columns: auto auto;
                                justify-content: space-between;
                                align-items: center;

                                .search_card_list_item_r1_text {
                                    font-size: 13px;
                                    font-family: PingFang SC-Medium, PingFang SC;
                                    font-weight: 500;
                                    color: #333333;
                                }

                                .search_card_list_item_r1_state {
                                    font-size: 13px;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #B9B9BD;
                                    display: grid;
                                    grid-template-columns: 16px auto;
                                    justify-content: flex-end;
                                    align-items: center;

                                    .hidden-sm-and-up {
                                        font-size: 10px;
                                    }

                                    .search_card_list_item_r1_state_icon {
                                        width: 16px;
                                        height: 16px;
                                    }

                                    &.ywc {
                                        color: #B9B9BD;
                                    }
                                }
                            }

                            .search_card_list_item_r2 {
                                font-size: 24px;
                                font-family: PingFang SC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #191919;

                                &.hidden-sm-and-up {
                                    font-size: 14px;
                                }
                            }

                            .search_card_list_item_r3 {
                                font-size: 13px;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #999999;

                                &.hidden-sm-and-up {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }

                // 预约记录
                .make_card {

                    .make_card_list {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 10px;
                        align-content: flex-start;

                        .make_card_list_item {
                            border-radius: 10px;
                            border: 1px solid #eee;
                            padding: 10px;
                            display: grid;
                            grid-template-columns: repeat(1, 1fr);
                            row-gap: 5px;
                            align-content: flex-start;

                            .make_card_list_item_r1 {
                                display: grid;
                                grid-template-columns: auto auto;
                                justify-content: space-between;
                                align-items: center;

                                .make_card_list_item_r1_text {
                                    font-size: 13px;
                                    font-family: PingFang SC-Medium, PingFang SC;
                                    font-weight: 500;
                                    color: #333333;
                                }

                                .make_card_list_item_r1_state {
                                    font-size: 13px;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #CDAF5A;

                                    &.ywc {
                                        color: #B9B9BD;
                                    }
                                }
                            }

                            .make_card_list_item_r2 {
                                font-size: 24px;
                                font-family: PingFang SC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #191919;

                                &.hidden-sm-and-up {
                                    font-size: 14px;
                                }
                            }

                            .make_card_list_item_r3 {
                                font-size: 13px;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #999999;

                                &.hidden-sm-and-up {
                                    font-size: 10px;
                                }
                            }
                        }
                    }

                }

                // 我的收藏
                .sc_card {

                    .tab_list_box {
                        min-height: 590px;
                        // display: grid;
                        // grid-template-columns: repeat(5, 1fr);
                        // column-gap: 13px;
                        // row-gap: 20px;
                        // align-content: flex-start;
                    }

                }

                // 帮助中心
                .help_card {
                    .help_card_list {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 10px;
                        align-content: flex-start;

                        .help_card_list_item {
                            border-radius: 10px;
                            border: 1px solid #eee;
                            padding: 15px;
                            display: grid;
                            grid-template-columns: repeat(1, 1fr);
                            row-gap: 10px;
                            align-content: flex-start;

                            .help_card_list_item_r1 {
                                display: grid;
                                grid-template-columns: 20px calc(100% - 20px - 5px);
                                column-gap: 5px;
                                justify-content: flex-start;
                                align-items: flex-start;

                                .help_card_list_item_r1_icon {
                                    width: 20px;
                                    height: 20px;
                                }

                                .help_card_list_item_r1_text {
                                    line-height: 20px;
                                    font-size: 14px;
                                    font-family: PingFang SC-Medium, PingFang SC;
                                    font-weight: 500;
                                    color: #000000;
                                }
                            }

                            .help_card_list_item_r2 {
                                padding-left: 25px;
                                font-size: 12px;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #666666;
                            }

                        }
                    }
                }

                // 合作伙伴
                .hzhb_card {


                    .hzhb_card_list {
                        // display: grid;
                        // grid-template-columns: repeat(8, 1fr);
                        // row-gap: 13px;
                        // column-gap: 13px;
                        // align-content: flex-start;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;

                      

                        .hzhb_card_list_item {
                            display: grid;
                            grid-template-columns: repeat(1, 1fr);
                            row-gap: 2px;
                            justify-items: center;
                            align-content: flex-start;

                            .hzhb_card_list_item_top {

                                padding: 0.52083vw 0.52083vw 0.78125vw;
                                border-radius: 6px;
                                border: 1px solid #e6e6e6;

                                .hzhb_card_list_item_top_img {
                                    width: 4.16666vw;
                                    height: 2.08333vw;
                                    min-width: 40px;
                                    min-height: 20px;
                                }
                            }

                            .hzhb_card_list_item_bot {
                                text-align: center;
                                font-size: 10px;
                                font-family: PingFang SC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #482221;
                            }
                        }
                    }
                }


            }

            .tips {
                padding: 20px 0 0;
                text-align: center;
                font-size: 13px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
            }

            .pageing_box {
                padding: 30px 0 20px;

                .custom_pagination::v-deep .el-pager li.active {
                    background-color: #CEB05A;
                    /* 设置选中页码的背景颜色 */
                }

                .custom_pagination::v-deep .el-pager li:not(.active):hover {
                    color: #CEB05A;
                    /* 修改悬停时的字体颜色 */
                }
            }


        }
    }
}

@media (max-width: 768px) {
    ::v-deep .el-dialog {
        width: 90% !important;
        /* 小屏幕时宽度改为80% */
    }
}
</style>
