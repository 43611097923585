<template>
  <div class="base_footer">
    <div class="base_width base_footer_room">
      <div v-if="config.url_year == config.url_year">版权所有©{{ `${config.url_year} ${config.company}` }}</div>
      <div v-else>版权所有©{{ `${config.url_year}-${my_time('y')}${config.company}` }}</div>
      <div v-if="config.icp_url" class="sethand" @click.stop="jumpweb(config.icp_url)">{{ config.icp }}</div>
      <div v-if="config.security" class="gongan_box sethand" @click.stop="jumpweb(config.security_url)">
        <el-image class="gongan" :src="require('@/assets/img/gongan.png')"></el-image>
        <div>公安备案号: {{ config.security }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFooter',
  data:function() {
    return{
      view_tree:'',
      config:'',
    }
  },
 
  mounted(){
    this.config = JSON.parse(localStorage.getItem('config'));
  },
  methods:{
    // 跳转
    jumpweb(hrefurl){
      window.open(hrefurl, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.base_footer{
  width: 100%;
  height: 45px;
  background-color: #222229;

  .base_footer_room{
    height: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 5px;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255,255,255,0.5);

    .gongan_box{
      display: grid;
      grid-template-columns: 18px auto;
      column-gap: 5px;
      justify-content: flex-start;
      align-items: center;
      .gongan{
        width: 18px;
        height: 18px;
      }
    }
  }
  
}

</style>
