<template>
  <div class="search_page">
    <BaseHeader v-if="config" :headerNum="currenttab" :config="config"></BaseHeader>

    <div class="main">


      <!-- 轮播 -->
      <el-carousel height="26.041vw" class="hidden-xs-only" :interval="Number(banner4.interval)" :loop="true">
        <el-carousel-item v-for="(item, index) in banner4.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>
      </el-carousel>
      <el-carousel height="26.041vw" class="hidden-sm-and-up" :interval="Number(banner4.interval)" :loop="true">
        <el-carousel-item v-for="(item, index) in banner4.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>
      </el-carousel>

      <!-- 展示区 -->
      <div class="base_width main_room">

        <div class="page_box">
          <div class="page_box_title">
            <div class="page_box_title_left">
              <div class="page_box_title_left_text">查询</div>
              <div class="page_box_title_left_link"></div>
            </div>
          </div>


          <div class="search_content">
            <el-row type="flex" justify="center">
              <el-col :lg="6" :md="8" :sm="10" :xs="12">
                <div v-show="stype" class="search_content_top">

                  <div v-if="type === 1" class="r2">车牌号</div>
                  <div v-if="type === 2" class="r2">入仓单号</div>
                  <div class="r3">{{ param.search_key }}</div>
                  <div class="r4 flex flex-center">
                    <canvas id="qrcode" class="canvas_style"></canvas>
                  </div>

                </div>
              </el-col>
            </el-row>

            <el-row type="flex" justify="center" style="padding-top: 20px;">
              <el-col :lg="6" :md="8" :sm="10" :xs="12">
                <div class="list_bar">

                  <div v-if="infodata.length">
                    <div v-for="(item, index) in infodata" :key="index">
                      <div class="list_bar_item">
                        <div class="list_bar_item_left">
                          <div :class="{ 'act': (index === 0) }">{{ formatTime(item.create_time).split(' ')[0] }}</div>
                          <div :class="{ 'act': (index === 0) }">{{ formatTime(item.create_time).split(' ')[1] }}</div>
                        </div>
                        <div class="list_bar_item_right">
                          <div class="link">
                            <el-image v-if="index === 0" class="link_dot act"
                              :src="require('@/assets/img/c9.png')"></el-image>
                            <div v-else class="link_dot"></div>
                            <div v-if="!((infodata.length - 1) === index)" class="link_center"></div>
                          </div>
                          <div class="content">
                            <div class="content_r1" :class="{ 'act': (index === 0) }">{{ item.status_name }}</div>
                            <div class="content_r2" :class="{ 'act': (index === 0) }">{{ item.desc || '-' }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <el-empty description="暂无数据~"></el-empty>
                  </div>

                </div>
              </el-col>
            </el-row>


          </div>








        </div>


      </div>


    </div>
  </div>
</template>
<script>
const QRCode = require('qrcode');
module.exports = {
  data: function () {
    return {
      banner4: '',
      config: '',
      currenttab: 4,

      ipt_num: '',//点击哪个展示哪个ipt
      search_key: '',//当前搜索框搜索内容

      param: {
        search_key: '',
        limit: 20,
      },

      infodata: '',
      type: '',
      stype: '',

    }
  },
  created() {
    this.currenttab = Number(this.$route.query.currenttab)

  },
  mounted() {
    this.getConfig()
    let infokeys = this.$route.query.infokeys;
    console.log('info===', this.infodata)
    // 优质产品详情|明星产品详情|热门推荐产品详情
    this.param.search_key = infokeys
    this.search_key = infokeys
    this.type = Number(this.$route.query.infotype)
    this.getBanner4()
    this.getInfo()
  },
  methods: {
    getConfig() {
      console.log('2222')
      this.$('/api/Index/getBaseConfig').then(res => {
        console.log('配置信息1', res.data)
        this.config = JSON.parse(JSON.stringify(res.data))
        localStorage.setItem('config', JSON.stringify(res.data));
        console.log('配置信息2', res.data)
        // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
        document.head.querySelectorAll("link").forEach(link => {
          if (link.rel === "icon") {
            link.parentNode?.removeChild(link);
          }
        });
        const link = document.createElement('link');
        link.rel = "icon";
        link.href = this.setHttps(res.data.icon); // 将网络图片的URL赋给href属性
        document.head.appendChild(link);
      })
    },
    // 生成二维码
    setQRCode(code) {
      let qrcodeview = document.getElementById('qrcode')
      // 生成二维码
      QRCode.toCanvas(qrcodeview, code, function (error) {
        if (error) {
          console.error(error);
        } else {
          console.log('二维码已生成并显示');
        }
      });
    },
    getInfo() {
      this.$('/api/Search/getReservationLog', this.param).then(res => {
        console.log('数据---', res.data)
        if (res.data.length) {
          this.stype = true
          this.infodata = res.data.length ? res.data : '';
          this.setQRCode(this.param.search_key)
        } else {
          this.stype = false
          this.param.search_key = ''
          this.infodata = ''
          this.type = ''
          this.$confirm('', '暂无数据', {
            confirmButtonText: '返回上一页',
            cancelButtonText: '留在本页',
            center: true,
            // type: 'warning'
          }).then(() => {
            if (this.currenttab === 2) {
              this.$router.go(-1);
            } else {
              window.close(); // 有上一页，返回上一页
            }
          }).catch(() => {
            return;
          });
        }
      })
    },

    // 轮播
    getBanner4() {
      this.$('/api/Index/getBannerList', {
        type: 1,
        show_type: 4
      }).then(res => {
        console.log('banner===', res.data)
        this.banner4 = res.data
      })
    },




  }
}
</script>
<style lang="scss" scoped>
.search_page {
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 28%, #F6F6F6 100%);

  .banner_item {
    width: 100%;
    height: auto;
  }

  .main {
    min-height: 100vh;

    .main_room {
      position: relative;
      top: -3vw;
      left: 0;
      z-index: 10;

      .page_box {
        width: 100%;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px 0 100px;

        .page_box_title {
          padding: 0 25px;
          display: grid;
          grid-template-columns: auto auto;
          justify-content: space-between;
          align-items: center;

          .page_box_title_left {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 3px;
            justify-content: flex-start;
            align-content: center;

            .page_box_title_left_text {
              font-size: 20px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #191919;
            }

            .page_box_title_left_link {
              width: 35px;
              height: 3px;
              border-radius: 5px;
              background-color: #ceb05a;
            }
          }

          .page_box_title_right {
            &::v-deep .el-breadcrumb__inner {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }

            .page_box_title_right_item {
              display: flex;
              justify-items: flex-end;
              align-items: center;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #6C757D;

              &::v-deep .el-breadcrumb__inner.is-link {
                color: #6C757D;
                font-weight: 400;
              }

              &:last-child::v-deep .el-breadcrumb__inner {
                color: #000;
              }

              .page_box_title_right_item_icon {
                width: 15px;
                height: 15px;
                margin-right: 7px;
              }


            }
          }
        }


        .search_content {
          .search_content_top {
            border-bottom: 1px solid #e6e6e8;

            .r1 {
              width: 100%;

              .search_item {
                width: 100%;
                height: 75px;
                border-radius: 10px;
                padding: 0 20px;

              }

              .defsearch {
                border: 1px solid #ceb05a;
                background-color: #CEB05A;
                display: grid;
                grid-template-columns: calc(100% - 20px) 20px;
                justify-content: space-between;
                align-items: center;

                .defsearch_input {
                  width: 100%;
                  height: 100%;
                  font-size: 15px;
                  font-family: PingFang SC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #FFFFFF;
                }

                .defsearch_input::-webkit-input-placeholder {
                  font-weight: 600;
                  color: #FFFFFF;
                }

                .defsearch_img {
                  width: 20px;
                  height: 20px;
                }
              }
            }

            .r2 {
              padding: 60px 0 20px;
              text-align: center;
              font-size: 16px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }

            .r3 {
              width: 100%;
              padding-bottom: 30px;
              text-align: center;
              font-size: 32px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #191919;
              white-space: normal;
              /* 设置文本换行 */
              word-wrap: break-word;
              /* 设置单词内换行 */
            }

            .r4 {
              width: 200px;
              height: 200px;
              margin: 0 auto;

              .canvas_style {
                width: 100% !important;
                height: 100% !important;
              }
            }



          }



          .list_bar {


            .list_bar_item {
              display: grid;
              grid-template-columns: auto auto;
              column-gap: 8px;
              justify-content: flex-start;
              align-content: flex-start;

              .list_bar_item_left {
                padding-top: 6px;
                font-size: 10px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #999;

                .act {
                  font-size: 10px;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #333333;
                }
              }

              .list_bar_item_right {
                display: grid;
                grid-template-columns: 12px calc(100% - 12px - 8px);
                column-gap: 8px;

                .link {
                  width: 12px;
                  height: 100%;
                  position: relative;

                  .link_center {
                    width: 2px;
                    border-right: 2px dotted #d9d9d9;
                    height: calc(100% - 12px);
                    position: absolute;
                    bottom: 0;
                    left: 5px;
                  }

                  .link_dot {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    position: absolute;
                    top: 7px;
                    left: 3px;
                    z-index: 50;
                    background-color: #d9d9d9;

                    &.act {
                      width: 12px;
                      height: 12px;
                      border-radius: 50%;
                      position: absolute;
                      top: 5px;
                      left: 0;
                      z-index: 50;
                      background-color: rgba(0, 0, 0, 0);
                    }
                  }
                }

                .content {
                  padding-bottom: 23px;

                  .content_r1 {
                    font-size: 15px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #999999;

                    &.act {
                      font-size: 15px;
                      font-family: PingFang SC-Regular, PingFang SC;
                      font-weight: 600;
                      color: #333333;
                    }
                  }

                  .content_r2 {
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;

                    &.act {
                      font-size: 12px;
                      font-family: PingFang SC-Medium, PingFang SC;
                      font-weight: 600;
                      color: #191919;
                    }
                  }
                }
              }
            }

          }


        }



      }

    }
  }


}
</style>
