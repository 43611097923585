import Vue from 'vue'
import App from './App.vue'
import store from './store'
Vue.prototype.$store=store


// 引入axios
import axios from 'axios';
// 创建全局 Axios 实例
const axiosInstance = axios.create({
  baseURL: 'https://miniapp.bmeiscs.shop', // 替换为你的 API 地址
});
// 将 Axios 实例绑定到 Vue 原型中
Vue.prototype.$axios = axiosInstance;

//引入路由组件
import router from './router/index'
import VueRouter from 'vue-router'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';

Vue.use(ElementUI);

import BaseHeader from '@/components/BaseHeader';
import BaseFooter from '@/components/BaseFooter';
import indexItem from '@/components/index/index-item';
import classifyItem from '@/components/classify/classify-item';
import reviewItem from '@/components/index/review-item';
Vue.component('BaseHeader', BaseHeader);
Vue.component('BaseFooter', BaseFooter);
Vue.component('indexItem', indexItem);
Vue.component('classifyItem', classifyItem);
Vue.component('reviewItem', reviewItem);

import common from '@/utils/common.js';
for (let i in common) {
	Vue.prototype[i] = common[i]
}

//引入全局样式表
import './assets/css/common.css'

Vue.config.productionTip = false
Vue.use(VueRouter);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
