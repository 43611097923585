import Vue from 'vue'

//导入页面
import home from '@/views/home/home'
import classily from '@/views/classily/classily'
import make from '@/views/make/make'
import search from '@/views/search/search'
import my from '@/views/my/my'
import login from '@/views/login'
import hslist from '@/views/home/attach/hs_list'
import routeDetail from '@/views/home/attach/route_detail'
import classList from '@/views/classily/attach/classily_list'
import searchDetail from '@/views/search/attach/search_detail'
import zyDetail from '@/views/home/attach/zy_detail'
import richDetail from '@/views/home/attach/rich_detail'
import richDetailAg from '@/views/home/attach/rich_detail_ag'


import Router from 'vue-router' // 导入vue-router
 
Vue.use(Router)

 
//配置页面路由
export const constantRouterMap = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      title: '首页',
      haveHeader:true,
      haveFooter:true
    },
  },
  {
    path: '/make',
    name: 'make',
    component: make,
    meta: {
      title: '预约',
      haveHeader:true,
      haveFooter:true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: search,
    meta: {
      title: '查询',
      haveHeader:true,
      haveFooter:true
    }
  },
  {
    path: '/classily',
    name: 'classily',
    component: classily,
    meta: {
      title: '分类',
      haveHeader:true,
      haveFooter:true
    }
  },
  {
    path: '/my',
    name: 'my',
    component: my,
    meta: {
      title: '个人中心',
      haveHeader:true,
      haveFooter:true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: '登录',
      haveHeader:false,
      haveFooter:false
    },
  },
  {
    path: '/hslist',
    name: 'hslist',
    component: hslist,
    meta: {
      title: '列表',
      haveHeader:true,
      haveFooter:true
    }
  },
  {
    path: '/routeDetail',
    name: 'routeDetail',
    component: routeDetail,
    meta: {
      title: '详情',
      haveHeader:true,
      haveFooter:true
    }
  },
  {
    path: '/classList',
    name: 'classList',
    component: classList,
    meta: {
      title: '列表',
      haveHeader:true,
      haveFooter:true
    }
  },
  {
    path: '/searchDetail',
    name: 'searchDetail',
    component: searchDetail,
    meta: {
      title: '详情',
      haveHeader:true,
      haveFooter:true
    }
  },
  {
    path: '/zyDetail',
    name: 'zyDetail',
    component: zyDetail,
    meta: {
      title: '详情',
      haveHeader:true,
      haveFooter:true
    }
  },
  {
    path: '/richDetail',
    name: 'richDetail',
    component: richDetail,
    meta: {
      title: '详情',
      haveHeader:true,
      haveFooter:true
    }
  },
  {
    path: '/richDetailAg',
    name: 'richDetailAg',
    component: richDetailAg,
    meta: {
      title: '详情',
      haveHeader:true,
      haveFooter:true
    }
  }
  
  
  
]

const router = new Router({
    routes: constantRouterMap,
    mode:'hash'//history
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '铭亿国际'; // 设置页面标题
  next();
});
 
export default router