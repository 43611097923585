<template>
  <div class="rich_detail_page">
    <div class="main">


      <!-- 轮播 -->
      <el-carousel height="26.041vw" class="hidden-xs-only" :interval="Number(banner1.interval)" :loop="true">
        <el-carousel-item v-for="(item, index) in banner1.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>
      </el-carousel>
      <el-carousel height="26.041vw" class="hidden-sm-and-up" :interval="Number(banner1.interval)" :loop="true">
        <el-carousel-item v-for="(item, index) in banner1.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>
      </el-carousel>

      <!-- 展示区page_info -->
      <el-row type="flex" justify="center">
        <el-col :lg="16" :md="18" :sm="20" :xs="22">
          <div class="base_width main_room">

            <div class="page_box">
              <el-row type="flex" justify="space-between" align="flex-end" class="page_box_title">
                <el-col :lg="3" :md="4" :sm="5" :xs="24">
                  <div class="page_box_title_left">
                    <div class="page_box_title_left_text">{{ titname }}</div>
                    <div class="page_box_title_left_link"></div>
                  </div>
                </el-col>
                <el-col :lg="18" :md="17" :sm="16" :xs="12" class="hidden-xs-only">
                  <div class="mbxbox">
                    <el-breadcrumb separator-class="el-icon-arrow-right" class="flex flex-end page_box_title_right">
                      <el-breadcrumb-item :to="{ path: '/' }" class="flex flex-end page_box_title_right_item sethand">
                        <el-image class="page_box_title_right_item_icon"
                          :src="require('@/assets/img/home1.png')"></el-image>
                        <div class="page_box_title_right_item_text">首页</div>
                      </el-breadcrumb-item>
                      <el-breadcrumb-item class="flex flex-end page_box_title_right_item">{{ titname }}</el-breadcrumb-item>
                    </el-breadcrumb>
                  </div>
                </el-col>
              </el-row>


              <div class="cont_box">
                <div v-html="editContent(page_info)"></div>
              </div>

            </div>



          </div>
        </el-col>
      </el-row>


    </div>
  </div>
</template>
<script>
module.exports = {
  data: function () {
    return {
      banner1: '',

      titname: '',//携带进来的数据
      param: {
        id: '',
        table: ''
      },
      page_info: '',

    }
  },
  created() {

    // 获取名为'info'的参数
    this.param.id = this.$route.query.id
    this.param.table = this.$route.query.table
    this.titname = this.$route.query.titname
    console.log('参数===', this.param)

  },

  mounted() {
    this.getBanner1()
    this.getPageInfo()
  },
  methods: {
    // 轮播
    getBanner1() {
      this.$('/api/Index/getBannerList', {
        type: 1,
        show_type: 1
      }).then(res => {
        console.log('banner===', res.data)
        this.banner1 = res.data
      })
    },
    // 获取详情
    getPageInfo() {
      this.$('/api/Index/getContent', this.param).then(res => {
        this.page_info = res.data
      })
    },




  }
}
</script>
<style lang="scss" scoped>
.rich_detail_page {
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 28%, #F6F6F6 100%);

  .banner_item {
    width: 100%;
    height: auto;
  }

  .main {
    min-height: 100vh;

    .main_room {
      position: relative;
      top: -3vw;
      left: 0;
      z-index: 10;

      .page_box {
        width: 100%;
        border-radius: 10px;
        background-color: #fff;

        .cont_box {
          min-height: 300px;
          padding: 0 25px;
        }

        .page_box_title {
          padding: 0 25px;
          height: 60px;
          border-bottom: 1px solid #eee;

          .page_box_title_left {
            height: 100%;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 3px;
            justify-content: flex-start;
            align-content: flex-end;

            .page_box_title_left_text {
              font-size: 20px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #191919;
            }

            .page_box_title_left_link {
              width: 35px;
              height: 3px;
              border-radius: 5px;
              background-color: #ceb05a;
            }
          }

          .mbxbox {
            height: 100%;
          }

          .page_box_title_right {
            height: 100%;

            &::v-deep .el-breadcrumb__inner {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }

            .page_box_title_right_item {
              display: flex;
              justify-items: flex-end;
              align-items: center;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #6C757D;

              &::v-deep .el-breadcrumb__inner.is-link {
                color: #6C757D;
                font-weight: 400;
              }

              &:last-child::v-deep .el-breadcrumb__inner {
                color: #000;
              }

              .page_box_title_right_item_icon {
                width: 15px;
                height: 15px;
                margin-right: 7px;
              }


            }
          }
        }

        .page_box_tab_list {
          padding: 20px 0 58px;

          .tab_list_box {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 18px;
            row-gap: 18px;
            align-content: flex-start;
          }
        }

        .tips {
          padding-top: 35px;
          text-align: center;
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }

        .pageing_box {
          padding: 30px 0 60px;

          .custom_pagination::v-deep .el-pager li.active {
            background-color: #CEB05A;
            /* 设置选中页码的背景颜色 */
          }

          .custom_pagination::v-deep .el-pager li:not(.active):hover {
            color: #CEB05A;
            /* 修改悬停时的字体颜色 */
          }
        }
      }

    }
  }


}
</style>
