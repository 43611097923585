import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    headerNum:Number(localStorage.getItem('headerNum')),
    keepAliveList:[]
  },
  getters: {

  },
  //里面定义方法，操作state方发
  mutations: {
    setTabNum(state,num){
      state.headerNum = Number(num)
    },
    setkeepAliveList(state,list){
      state.keepAliveList = list
    }
  },
  // 操作异步操作mutation
  actions: {
    
  },
  modules: {

  },
})
export default store;