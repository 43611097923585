<template>
  <div class="review_item_view sethand">

    <el-image class="item_icon" :src="setHttps(obj.avatar)"></el-image>
    <div class="item_cont">
      <div class="item_cont_top">
        <div class="item_cont_top_name">{{ obj.nickname }}</div>
        <div class="item_cont_top_time">{{ formatTime(obj.create_time) }}</div>
      </div>
      <div class="item_cont_bot">
        <div class="item_cont_bot_text" :class="{ 'ellipsis3': !isExpanded }">{{ obj.evaluate }}</div>
        <div @click.stop="toggleExpand" class="item_cont_bot_btn">{{ isExpanded ? '收起' : '展开' }}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'review-item',
  props: {
    obj: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data: function () {
    return {
      isExpanded: false,//展开收起状态
    }
  },
  mounted() {

  },
  methods: {
    // 展开收起
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  }

}
</script>

<style lang="scss" scoped>
.review_item_view {
  display: grid;
  grid-template-columns: 35px calc(100% - 35px - 8px);
  column-gap: 8px;
  align-items: flex-start;

  .item_icon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .item_cont {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
    align-content: flex-start;

    .item_cont_top {
      .item_cont_top_name {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .item_cont_top_time {
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }

    .item_cont_bot {

      // 内容
      .item_cont_bot_text {
        max-width: 200px;
        white-space: normal !important;
        word-wrap: break-word !important;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      // 超出点击展示的区域;去掉行数限制css  改变展开为收起
      .item_cont_bot_btn {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #CEB05A;
      }
    }
  }



}
</style>
