<template>
  <div class="index_item_view base_shadow sethand">

    <el-image class="index_item_top" :src="setHttps(obj.image)" @click.stop="jumpDetail"></el-image>
    <div class="index_item_bot">
      <div class="index_item_bot_info">
        <div class="index_item_bot_info_title ellipsis3" @click.stop="jumpDetail">{{ obj.name }}</div>
        <div v-if="obj.labels&&obj.labels.length" ref="nav" class="index_item_bot_info_sign_box">
          <div v-for="(item,index) in obj.labels" :key="index" class="index_item_bot_info_sign">
            <div class="index_item_bot_info_sign_btn">{{ item }}</div>
          </div>
        </div>
        
      </div>
      <div class="index_item_bot_fot" @click.stop="jumpDetail">
        <div class="index_item_bot_fot_price">¥{{ obj.price }}</div>
        <div class="index_item_bot_fot_see">
          <div class="index_item_bot_fot_see_text">{{ obj.views || '0' }}</div>
          <el-image class="index_item_bot_fot_see_icon" :src="require('@/assets/img/see1.png')"></el-image>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'index-item',
  props: {
    obj: {
      type: Object,
      default () {
        return {};
      }
    },
    currenttab:{
      type:Number,
      default:0
    },
    aim:{
      type:Number,
      default:1
    },
    jumptype:{
      type: String,
      default:'',
    }
  },
  data:function() {
    return{

    }
  },
  mounted() {
    // console.log(this.$refs.nav)
    // 获取要绑定事件的元素
    let nav = this.$refs.nav
    if(nav){
      this.scrollInit(nav);
    }
  },
  methods: {
    // 跳转
    jumpDetail(){
      console.log('跳转')
      if(this.currenttab === 4){
        let href = this.$router.resolve({path:'/routeDetail',query:{id:this.obj.id,tabstate:true,aim:this.aim,currenttab:this.currenttab}}).href;
        window.open(href, "_blank");
      }else{
        this.$router.push({name:'routeDetail',query:{id:this.obj.id,aim:this.aim,currenttab:this.currenttab}});
      }
    },
    // 初始化与绑定监听事件方法
    scrollInit(nav) {
      let flag; // 鼠标按下
      let downX; // 鼠标点击的x下标
      let scrollLeft; // 当前元素滚动条的偏移量
      nav.addEventListener("mousedown", function (event) {
        flag = true;
        downX = event.clientX; // 获取到点击的x下标
        scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
      });
      nav.addEventListener("mousemove", function (event) {
        if (flag) { // 判断是否是鼠标按下滚动元素区域
          let moveX = event.clientX; // 获取移动的x轴
          let scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
          this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
        }
      });
      // 鼠标抬起停止拖动
      nav.addEventListener("mouseup", function () {
        flag = false;
      });
      // 鼠标离开元素停止拖动
      nav.addEventListener("mouseleave", function () {
        flag = false;
      });
    },
  }

}
</script>

<style lang="scss" scoped>
.index_item_view{
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  .index_item_top{
    width: 100%;
    height: auto;
  }
  .index_item_bot{
    width: 100%;
    height: 170px;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    align-content: space-between;
    .index_item_bot_info{
      width: 100%; /* 设置宽度，确保容器可以横向滚动 */
      display: grid;
      grid-template-columns: repeat(1,1fr);
      row-gap: 0.52083vw;
      align-content: flex-start;
      .index_item_bot_info_title{
        font-size: 13px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .index_item_bot_info_sign_box{
        width: 100%; /* 设置宽度，确保容器可以横向滚动 */
        white-space: nowrap; /* 防止内容自动换行 */
        overflow-x: scroll; /* 允许内容横向滚动 */
        overflow-y: hidden;
        .index_item_bot_info_sign{
          display: inline-block; /* 将每个项目以行内块元素的方式显示，从而它们可以横向排列 */
          white-space: normal; /* 恢复内容的默认换行行为，以便内容可以在项目内换行 */
          .index_item_bot_info_sign_btn{
            display: inline-block;
            white-space: nowrap; /* 防止内容自动换行 */
            margin-right: 4px;
            padding: 2px 4px;
            border-radius: 4px;
            background-color: #f7d274;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #482221;
          }
        }
      }
      /* 如果你想自定义滚动条的样式，可以使用以下属性（可选） */
      .index_item_bot_info_sign_box::-webkit-scrollbar {
        width: 0.5em; 
      }
    }
    .index_item_bot_fot{
      display: grid;
      grid-template-columns: repeat(1,1fr);
      row-gap: 10px;
      align-content: flex-end;
      .index_item_bot_fot_price{
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #DD6029;
      }
      .index_item_bot_fot_see{
        display: grid;
        grid-template-columns: auto 13px;
        justify-content: flex-start;
        align-items: center;
        .index_item_bot_fot_see_text{
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        .index_item_bot_fot_see_icon{
          width: 13px;
          height: 13px;
        }
      }
    }
  }
    
    
  
}

</style>
