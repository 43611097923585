<template>
  <div class="zy_page">
    <BaseHeader v-if="config" :headerNum="currenttab" :config="config"></BaseHeader>

    <div class="main">

      <!-- 展示区 -->
      <el-row type="flex" justify="center">
        <el-col :lg="16" :md="18" :sm="20" :xs="22">
          <div class="base_width main_room">

            <div class="page_box">
              
              <el-breadcrumb v-if="currenttab === 3" separator-class="el-icon-arrow-right"
                class="flex flex-start page_box_title">
                <el-breadcrumb-item :to="{ path: '/classily' }" class="flex flex-end page_box_title_right_item">
                  <el-image class="page_box_title_right_item_icon" :src="require('@/assets/img/home1.png')"></el-image>
                  <div class="page_box_title_right_item_text">分类</div>
                </el-breadcrumb-item>
                <el-breadcrumb-item class="flex flex-end page_box_title_right_item">{{ page_info.name }}</el-breadcrumb-item>
              </el-breadcrumb>
              <el-breadcrumb v-else separator-class="el-icon-arrow-right" class="flex flex-start page_box_title">
                <el-breadcrumb-item :to="{ path: '/' }" class="flex flex-end page_box_title_right_item">
                  <el-image class="page_box_title_right_item_icon" :src="require('@/assets/img/home1.png')"></el-image>
                  <div class="page_box_title_right_item_text">首页</div>
                </el-breadcrumb-item>
                <el-breadcrumb-item class="flex flex-end page_box_title_right_item">{{ page_info.name }}</el-breadcrumb-item>
              </el-breadcrumb>


              <el-row type="flex" justify="center">
                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                  <div class="rich_box">

                    <div v-if="page_info.content" class="rich_box_cont" v-html="editContent(page_info.content)"></div>

                  </div>
                </el-col>
              </el-row>

              <el-row type="flex" justify="center">
                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                  <div class="review_list">
                    <div class="title">全部评论 {{ page_info.evaluate_num || '0' }}</div>
                    <div v-if="review_list.length" class="list">

                      <div v-for="(item, index) in review_list" :key="index">
                        <review-item :obj="item"></review-item>
                      </div>

                    </div>
                    <el-empty v-else description="暂无数据"></el-empty>



                    <div class="btn_box">
                      <el-row type="flex" justify="space-between" align="middle">
                        <el-col :lg="16" :md="16" :sm="14" :xs="12" class="btn_box_left sethand">
                          <el-row type="flex" justify="space-between" align="middle" class="h100">
                            <el-col :lg="16" :md="16" :sm="16" :xs="14" class="h100">
                              <input v-model="evaluate" class="btn_box_left_ipt" placeholder="评论一下..." type="text">
                            </el-col>
                            <el-col :lg="8" :md="8" :sm="8" :xs="10">
                              <div class="btn_box_left_btn" @click.stop="plClick">发表</div>
                            </el-col>
                          </el-row>
                        </el-col>

                        <el-col :lg="8" :md="18" :sm="10" :xs="12" class="btn_box_right flex flex-end">
                          

                          <el-row :gutter="5" type="flex" justify="end" align="middle">
                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="btn_box_right_item flex sethand" @click.stop="scClick">
                              <el-image v-if="page_info.collect_status === 0" class="btn_box_right_item_img"
                              :src="require('@/assets/img/c2.png')"></el-image>
                              <el-image v-else class="btn_box_right_item_img"
                                :src="require('@/assets/img/c2a.png')"></el-image>
                              <div class="btn_box_right_item_num">{{ page_info.likes_num }}</div>
                            </el-col>
                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="btn_box_right_item flex sethand" @click.stop="dzClick">
                              <el-image v-if="page_info.likes_status === 0" class="btn_box_right_item_img"
                              :src="require('@/assets/img/c3.png')"></el-image>
                              <el-image v-else class="btn_box_right_item_img"
                                :src="require('@/assets/img/c3a.png')"></el-image>
                              <div class="btn_box_right_item_num">{{ page_info.collect_num }}</div>
                            </el-col>
                          </el-row>
                          
                        </el-col>

                      </el-row>


                    </div>



                  </div>
                </el-col>
              </el-row>








            </div>



          </div>
        </el-col>
      </el-row>

    </div>
  </div>
</template>
<script>
module.exports = {
  data: function () {
    return {
      currenttab: 4,
      config: '',

      page_info: '',
      review_list: '',

      param: {
        resource_id: '',
      },
      evaluate: '',



    }
  },
  created() {
    this.currenttab = Number(this.$route.query.currenttab)
  },

  mounted() {
    this.getConfig()

    // 获取名为'info'的参数
    console.log('currenttab===', this.currenttab)
    this.param.resource_id = this.$route.query.id
    this.getInfo()
    this.getlist()
  },
  methods: {
    getConfig() {
      console.log('2222')
      this.$('/api/Index/getBaseConfig').then(res => {
        console.log('配置信息1', res.data)
        this.config = JSON.parse(JSON.stringify(res.data))
        localStorage.setItem('config', JSON.stringify(res.data));
        console.log('配置信息2', res.data)
        // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
        document.head.querySelectorAll("link").forEach(link => {
          if (link.rel === "icon") {
            link.parentNode?.removeChild(link);
          }
        });
        const link = document.createElement('link');
        link.rel = "icon";
        link.href = this.setHttps(res.data.icon); // 将网络图片的URL赋给href属性
        document.head.appendChild(link);
      })
    },
    // 评论
    plClick() {
      if (!this.evaluate) {
        this.$message.error('请填写评价内容');
        return;
      }
      this.$('/api/Index/addResourceEvaluate', {
        evaluate: this.evaluate,
        ...this.param
      }).then(res => {
        console.log('收藏---', res)
        this.evaluate = ''
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.getInfo()
        this.getlist()
      })
    },
    // 点赞
    dzClick() {
      this.$('/api/Index/setResourceLikes', this.param).then(res => {
        console.log('收藏---', res)
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.getInfo()
      })
    },
    // 收藏
    scClick() {
      this.$('/api/Index/setResourceCollect', this.param).then(res => {
        console.log('收藏---', res)
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.getInfo()
      })
    },
    // 获取产品
    getInfo() {
      this.$('/api/Index/getResourceInfo', this.param).then(res => {
        console.log('数据---', res.data)
        this.page_info = res.data
      })
    },
    //评论列表
    getlist() {
      this.$('/api/Reservation/getEvaluateList', {
        resource_id: this.param.resource_id,
        limit: 30
      }).then(res => {
        console.log('评论列表---', res.data)
        this.review_list = res.data || []
      })
    },



  }
}
</script>
<style lang="scss" scoped>
.zy_page {
  width: 100%;
  background-color: #fff;

  .banner_item {
    width: 100%;
    height: auto;
  }

  .main {
    min-height: 100vh;

    .main_room {


      .page_box {
        width: 100%;
        padding-bottom: 100px;

        .page_box_title {
          padding: 40px 0;

          &::v-deep .el-breadcrumb__inner {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          .page_box_title_right_item {
            display: flex;
            justify-items: flex-end;
            align-items: center;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #6C757D;

            &::v-deep .el-breadcrumb__inner.is-link {
              color: #6C757D;
              font-weight: 400;
            }

            &:last-child::v-deep .el-breadcrumb__inner {
              color: #000;
            }

            .page_box_title_right_item_icon {
              width: 15px;
              height: 15px;
              margin-right: 7px;
            }


          }
        }




        .rich_box {
          padding: 20px 0 60px;
          border: 1px solid #e6e6e8;

          .rich_box_cont {
            padding: 3.125vw 10.41666vw;
          }
        }

        .review_list {
          padding: 20px 1.5625vw 35px;
          border-left: 1px solid #e6e6e8;
          border-right: 1px solid #e6e6e8;
          border-bottom: 1px solid #e6e6e8;

          .title {
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }

          .list {
            padding: 20px 0;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 20px;
            align-content: flex-start;

          }


          .btn_box {
            width: 100%;

            .btn_box_left {
              height: 44px;
              padding: 0 0 0 5px;
              border-radius: 22px;
              border: 1px solid #e8e8e8;
              background-color: #f5f5f5;

              .btn_box_left_ipt {
                width: 100%;
                height: 100%;
              }

              .btn_box_left_btn {
                line-height: 42px;
                border-radius: 21px;
                background-color: #CEB05A;
                font-size: 16px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                text-align: center;

              }
            }

            .btn_box_right {

              .btn_box_right_item {
                

                .btn_box_right_item_img {
                  width: 24px;
                  height: 24px;
                }

                .btn_box_right_item_num {
                  font-size: 13px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #979797;
                }
              }
            }
          }

        }




      }

    }
  }


}
</style>
