<template>
  <el-row type="flex" justify="center" class="base_header">
    <el-col :lg="16" :md="18" :sm="20" :xs="22">
      <div class="base_width h100" style="height: 100%;">
        <el-row type="flex" class="h100" justify="space-between">
          <el-col :span="6" class="h100">
            <div v-if="config" class="base_header_logo_box flex flex-start">
              <img class="base_header_logo sethand" :src="`https://miniapp.bmeiscs.shop/${config.logo}`" @click.stop="joinHome" alt="">
            </div>
          </el-col>
          <el-col :sm="18" :xs="18" class="h100" style="position: relative;">

            <el-row type="flex" class="h100" justify="end">
              <el-col :sm="15" :xs="15" class="h100" style=" position: relative;">

                <div class="base_header_cont flex flex-end">
                  <div class="base_header_tab hidden-xs-only">
                    <div v-for="(item, index) in view_tree" :key="index" @click.stop="tabClick(index)"
                      class="base_header_tab_item sethand">
                      <div class="base_header_tab_item_text" :class="{ 'act': headerNum === index }">{{ item.title }}</div>
                      <div class="base_header_tab_item_link" :class="{ 'act': headerNum === index }"></div>
                    </div>
                  </div>

                  <div class="ph_menu_box hidden-sm-and-up">
                    <div class="ph_menu_btn flex flex-center" @click.stop="menu_state = !menu_state"><i
                        class="el-icon-menu"></i></div>
                    <div v-if="menu_state" class="ph_menu_list">
                      <div class="ph_menu_list_item" :class="{ 'act': headerNum === index }"
                        v-for="(item, index) in view_tree" :key="index" @click.stop="tabClick(index)">{{ item.title }}
                      </div>
                      <!-- <div class="ph_menu_list_item" :class="{ 'act': headerNum === 4 }" @click.stop="jumpMy">个人中心</div> -->
                      <div v-if="!token" class="ph_menu_list_item out_login" @click.stop="signIn">注册｜登录</div>
                      <!-- <div v-else class="ph_menu_list_item out_login" @click.stop="outLogin">退出登录</div> -->
                    </div>
                  </div>
                </div>

              </el-col>
              <el-col v-if="!token" :sm="6" :xs="6" class="h100 hidden-xs-only">
                <div class="flex flex-center h100">
                  <div @click.stop="signIn" class="base_header_btn sethand">
                    <div class="">注册｜登录</div>
                    <el-image class="base_header_btn_img" :src="require('@/assets/img/r1.png')"></el-image>
                  </div>
                  <!-- <div v-else class="base_header_btn2 flex flex-center sethand"> -->
                    <!-- <el-image @click.stop="jumpMy" class="avatar" :src="setHttps(userinfo.avatar)"></el-image> -->
                    <!-- <div class="ellipsis" @click.stop="jumpMy">{{ userinfo.nickname }}</div> -->
                    <!-- <div class="outlogin posi-y hidden-md-and-down" @click.stop="outLogin">退出登录</div> -->
                    <!-- <div class="outlogin posi-y hidden-lg-and-up" @click.stop="outLogin">退出</div> -->
                  <!-- </div> -->
                </div>
              </el-col>
            </el-row>
          </el-col>


        </el-row>

        <!-- 按钮 -->
        <div class="mov_btn">
        <div class="mov_btn_box sethand">
          <div class="itm_box1">
            <div class="mov_btn_box_item">
              <el-image class="mov_btn_box_item_icon" :src="require('@/assets/img/up3.png')"></el-image>
              <div class="mov_btn_box_item_text">咨询电话</div>
            </div>
            <div class="show_bars1">
              <div class="show_bars1_room">
                <el-image class="show_bars1_room_icon" :src="require('@/assets/img/up3.png')"></el-image>
                <div class="show_bars1_room_text">{{ config.phone || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="itm_box2">
            <div class="mov_btn_box_item">
              <el-image class="mov_btn_box_item_icon" :src="require('@/assets/img/up2.png')" lazy></el-image>
              <div class="mov_btn_box_item_text">微信客服</div>
            </div>
            <div v-if="config" class="show_bars2">
              <el-image class="topClick_icon" :src="setHttps(config.mini_qrcode)"></el-image>
            </div>
          </div>
          <div class="topClick" @click.stop="backtopclcik">
            <el-image class="topClick_icon" :src="require('@/assets/img/up1.png')"></el-image>
          </div>
        </div>
      </div>


      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'BaseHeader',
  props: {
    headerNum: {
      type: [Number], // 这里可以根据实际情况指定参数的类型
      required:'' // 如果参数是必须的，可以设置为true
    },
    config:{
      type:Object,
      default(){
        return {};
      }
    }
  },
  data: function () {
    return {
      view_tree: '',
      token: '',
      userinfo: '',
      menu_state: false,//移动菜单隐藏
    }
  },
  created() {

  },

  mounted() {

   
    this.view_tree = [
      {
        title: '首页',
        path: '/'
      },
      {
        title: '预约',
        path: '/make'
      },
      {
        title: '查询',
        path: '/search'
      },
      {
        title: '分类',
        path: '/classily'
      },
      {
        title: '我的',
        path: '/my'
      }
    ]
    let recordstate = JSON.parse(localStorage.getItem('record'))
    this.token = recordstate ? (localStorage.getItem('token') || '') : (localStorage.getItem('token') || '');
    if (this.token) {
      this.userinfo = recordstate ? (JSON.parse(localStorage.getItem('userInfo')) || '') : (JSON.parse(sessionStorage.getItem('userInfo')) || '');
    }


  },
  methods: {
    
    // 回到顶部
    backtopclcik() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'  // 如果希望有平滑的滚动效果，可以添加这个属性
      });
    },
    // 跳转首页
    joinHome() {
      this.menu_state = false
      this.$router.replace({ name: 'home' });
    },
    // 跳转个人中心
    jumpMy() {
      this.menu_state = false
      this.$router.push({ name: 'my' });
    },
    
    // 去登录
    signIn() {
      this.$router.push({ name: 'login' });
    },
    // tab切换
    tabClick(num) {
      console.log('tabclick===', num)
      this.menu_state = false
      this.$router.push(this.view_tree[num].path);
    },

  }
}
</script>

<style lang="scss" scoped>
.base_header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 90px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);



  .mov_btn {
    position: fixed;
    right: 0;
    top: calc(50vh - 115px);
    z-index: 9000;

    .mov_btn_box {
      .itm_box1 {
        position: relative;

        &:hover {
          .show_bars1 {
            display: block;
          }
        }
      }

      .itm_box2 {
        position: relative;

        &:hover {
          .show_bars2 {
            display: block;
          }
        }
      }

      .show_bars1 {
        display: none;
        position: absolute;
        right: 60px;
        min-width: 240px;
        top: 0;
        height: 85px;
        padding: 30px 25px;
        background-color: #fff;

        .show_bars1_room {
          display: grid;
          grid-template-columns: 24px auto;
          column-gap: 10px;
          justify-content: flex-end;
          align-items: center;

          .show_bars1_room_icon {
            width: 24px;
            height: 24px;
          }

          .show_bars1_room_text {
            font-size: 24px;
            font-family: Arial-Bold, Arial;
            font-weight: bold;
            color: #333333;
          }
        }
      }

      .show_bars2 {
        display: none;
        position: absolute;
        top: -30px;
        right: 60px;
        width: 145px;
        height: 145px;
        background-color: #fff;
      }

      .mov_btn_box_item {
        width: 60px;
        height: 85px;
        border-bottom: 1px solid #f5f5f5;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-content: center;
        background-color: #fff;

        .mov_btn_box_item_icon {
          width: 24px;
          height: 24px;
        }


        .mov_btn_box_item_text {
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }

      }


      .topClick {
        width: 60px;
        height: 60px;
        background-color: #CEB05A;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-content: center;

        .topClick_icon {
          width: 28px;
          height: 28px;
        }


      }
    }



  }

  .base_header_logo_box {
    height: 100%;

    .base_header_logo {
      width: 70px;
      height: 59px;
    }
  }


  .base_header_cont {
    height: 100%;
    .base_header_tab {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      justify-items: center;
      align-items: center;

      .base_header_tab_item {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 10px;
        justify-items: center;
        align-content: center;

        .base_header_tab_item_text {
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;

          &.act {
            color: #CEB05A;
          }
        }

        .base_header_tab_item_link {
          width: 39px;
          height: 2px;
          background-color: rgba(0, 0, 0, 0);

          &.act {
            background-color: #CEB05A;
          }
        }
      }
    }

    .ph_menu_box {

      background-color: #fff;

      .ph_menu_btn {
        font-size: 32px;
        color: #505050;
      }

      .ph_menu_list {
        width: 100%;
        position: absolute;
        top: 90px;
        right: 0;
        background-color: #fff;
        box-shadow: 0px 5px 5px 0px rgba(153, 153, 153, 0.15);

        .ph_menu_list_item {
          width: 100%;
          padding: 10px;

          &.act {
            color: #CEB05A;
          }

          &.out_login {
            text-align: center;
            color: #fff;
            background-color: #CEB05A;
          }
        }
      }
    }
    
  }

  .base_header_btn {
    width: 148px;
    height: 50px;
    border-radius: 5px;
    background-color: #ceb05a;
    line-height: 50px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 15px;
    justify-content: center;
    align-items: center;

    .base_header_btn_img {
      width: 12px;
      height: 10px;
    }
  }

  .base_header_btn2 {
    display: grid;
    grid-template-columns: 50px auto;
    column-gap: 10px;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .uname {
      white-space: nowrap;
      font-size: 15px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #482221;
    }

    .outlogin {
      position: absolute;
      right: -5.20833vw;
      width: 4.16666vw;
      border-radius: 5px;
      border: 1px solid #999;
      line-height: 34px;
      text-align: center;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #B9B9BD;
    }
  }


}
</style>
