<template>
    <div class="home">
        <BaseHeader v-if="config" :headerNum="0" :config="config"></BaseHeader>
        <div class="main">
            <!-- 轮播 -->
            <el-carousel height="31.25vw" class="hidden-xs-only" :interval="Number(banner1.interval)" :loop="true">
                <el-carousel-item v-for="(item, index) in banner1.data" :key="index">
                    <el-image class="banner_item" :src="setHttps(item.image)"
                        @click.stop="jumpRich(item, 'banner')"></el-image>
                </el-carousel-item>
            </el-carousel>
            <el-carousel height="32.533vw" class="hidden-sm-and-up" :interval="Number(banner1.interval)" :loop="true">
                <el-carousel-item v-for="(item, index) in banner1.data" :key="index">
                    <el-image class="banner_item" :src="setHttps(item.image)"
                        @click.stop="jumpRich(item, 'banner')"></el-image>
                </el-carousel-item>
            </el-carousel>

            <!-- 展示区 -->
            <div class="base_width main_room">

                <!-- 公告 -->
                <el-row class="h100" type="flex" justify="center">
                    <el-col :lg="24" :md="20" :sm="20" :xs="20" class="h100">

                        <div class="ag" @click.stop="agClcik">

                            <el-image class="ag_img" :src="require('../../assets/img/ag.png')"></el-image>
                            <!-- 公告轮播 -->
                            <el-carousel class="ag_cont" height="100%" :loop="true" direction="vertical"
                                :interval="Number(banner3.interval)" :show-indicators="false">
                                <el-carousel-item v-for="(item, index) in banner3.data" :key="index">
                                    <div class="ellipsis ag_cont_text sethand">{{ item.name }}</div>
                                </el-carousel-item>
                            </el-carousel>
                            <el-image class="ag_icon sethand" :src="require('../../assets/img/r2.png')"></el-image>

                        </div>

                    </el-col>
                </el-row>



                <el-row class="h100" type="flex" justify="center">
                    <el-col :lg="24" :md="20" :sm="20" :xs="20" class="h100">
                        <!-- 金刚区 -->
                        <div class="ent_bar">

                            <div class="ent_bar_item sethand" v-for="(item, index) in ent_list" :key="index"
                                @click.stop="jumpJgList(item)">
                                <el-image class="ent_bar_item_img" :src="setHttps(item.image)"></el-image>
                                <div class="ent_bar_item_text hidden-xs-only">{{ item.name }}</div>
                                <div class="ent_bar_item_text hidden-sm-and-up">{{ item.name }}</div>
                            </div>

                        </div>

                    </el-col>
                </el-row>



                <el-row class="h100" type="flex" justify="center">
                    <el-col :lg="24" :md="20" :sm="20" :xs="20" class="h100">
                        <!-- 中间轮播 -->
                        <div class="center_banner">
                            <el-carousel height="148px" class="hidden-xs-only" :interval="Number(banner2.interval)"
                                :loop="true">
                                <el-carousel-item v-for="(item, index) in banner2.data" :key="index">
                                    <el-image class="banner_item2" :src="setHttps(item.image)"
                                        @click.stop="jumpRich(item, 'banner')"></el-image>
                                </el-carousel-item>
                            </el-carousel>
                            <el-carousel height="92.5px" class="hidden-sm-and-up" :interval="Number(banner2.interval)"
                                :loop="true">
                                <el-carousel-item v-for="(item, index) in banner2.data" :key="index">
                                    <el-image class="banner_item2" :src="setHttps(item.image)"
                                        @click.stop="jumpRich(item, 'banner')"></el-image>
                                </el-carousel-item>
                            </el-carousel>

                        </div>
                    </el-col>
                </el-row>

                <el-row class="h100" type="flex" justify="center">
                    <el-col :lg="24" :md="20" :sm="20" :xs="20" class="h100">
                        <!-- tab -->
                        <div class="tab_box">
                            <div v-for="(item, index) in tab_tree" :key="index" @click.stop="treeClick(index)"
                                class="tab_item sethand">
                                <div class="tab_item_text" :class="{ 'act': tree_num === index }">{{ item }}</div>
                                <div class="tab_item_link" :class="{ 'act': tree_num === index }"></div>
                            </div>
                        </div>
                    </el-col>
                </el-row>

                <el-row class="h100" type="flex" justify="center">
                    <el-col :lg="24" :md="20" :sm="20" :xs="20" class="h100">
                        <!-- 加搜索 -->
                        <div v-if="[1, 2].includes(tree_num)" class="base_search">
                            <div class="base_search_box">
                                <input v-model="fildval" @change="baseSearchClick" class="base_search_box_ipt"
                                    placeholder="请输入搜索关键字" type="text">
                                <el-image @click.stop="baseSearchClick" class="base_search_box_icon sethand"
                                    :src="require('@/assets/img/s1.png')"></el-image>
                            </div>
                        </div>
                    </el-col>
                </el-row>


                <el-row class="h100" type="flex" justify="center">
                    <el-col :lg="24" :md="20" :sm="20" :xs="20" class="h100">
                        <div v-if="tree_num === 0" class="hidden-xs-only">
                            <div class="tab_list">
                                <div class="tab_list_box">

                                    <div v-for="(item, index) in hs_list" :key="index" @click.stop="jumpHsList(item)"
                                        class="tab_list_box_item">
                                        <el-image class="tab_list_box_item_img" :src="setHttps(item.image)"></el-image>
                                    </div>

                                </div>
                                <div class="tips">已经到底了~</div>
                            </div>
                        </div>
                        <div v-if="tree_num === 0" class="hidden-sm-and-up">
                            <div class="tab_list">
                                <div class="tab_list_box">

                                    <div v-for="(item, index) in hs_list" :key="index" @click.stop="jumpHsList(item)"
                                        class="tab_list_box_item">
                                        <el-image class="tab_list_box_item_img" :src="setHttps(item.image)"></el-image>
                                    </div>

                                </div>
                                <div class="tips">已经到底了~</div>
                            </div>
                        </div>

                    </el-col>
                </el-row>



                <el-row class="h100" type="flex" justify="center">
                    <el-col :lg="24" :md="20" :sm="20" :xs="20" class="h100">
                        <div v-if="tree_num === 1" class="hidden-xs-only">
                            <div class="tab_list" v-loading="loading">
                                <div class="tab_list_box">
                                    <indexItem v-for="(item, index) in setItemsMx" :key="index" :obj="item" :currenttab="0">
                                    </indexItem>
                                </div>
                                <div class="tips">已经到底了~</div>
                                <div v-if="mx_list.length" class="pageing_box flex flex-center">
                                    <el-pagination small :pager-count="5" background layout="prev, pager, next"
                                        :total="mx_list.length" :page-size="pageSize_mx" :current-page="currentPage_mx"
                                        @current-change="mxChange" class="custom_pagination"></el-pagination>
                                </div>
                            </div>
                        </div>
                        <div v-if="tree_num === 1" class="hidden-sm-and-up">
                            <div class="tab_list" v-loading="loading">
                                <div class="tab_list_box">
                                    <indexItem v-for="(item, index) in setItemsMx" :key="index" :obj="item" :currenttab="0">
                                    </indexItem>
                                </div>
                                <div class="tips">已经到底了~</div>
                                <div v-if="mx_list.length" class="pageing_box flex flex-center">
                                    <el-pagination small :pager-count="5" background layout="prev, pager, next"
                                        :total="mx_list.length" :page-size="pageSize_mx" :current-page="currentPage_mx"
                                        @current-change="mxChange" class="custom_pagination"></el-pagination>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>

                <el-row class="h100" type="flex" justify="center">
                    <el-col :lg="24" :md="20" :sm="20" :xs="20" class="h100">
                        <div v-if="tree_num === 2" class="hidden-xs-only">
                            <div class="tab_list" v-loading="loading">
                                <div class="tab_list_box">
                                    <indexItem v-for="(item, index) in setItemsRm" :key="index" :obj="item" :currenttab="0">
                                    </indexItem>
                                </div>
                                <div class="tips">已经到底了~</div>
                                <div v-if="rm_list && rm_list.length" class="pageing_box flex flex-center">
                                    <el-pagination small :pager-count="5" background layout="prev, pager, next"
                                        :total="rm_list.length" :page-size="pageSize_rm" :current-page="currentPage_rm"
                                        @current-change="rmChange" class="custom_pagination"></el-pagination>
                                </div>
                            </div>
                        </div>
                        <div v-if="tree_num === 2" class="hidden-sm-and-up">
                            <div class="tab_list" v-loading="loading">
                                <div class="tab_list_box">
                                    <indexItem v-for="(item, index) in setItemsRm" :key="index" :obj="item" :currenttab="0">
                                    </indexItem>
                                </div>
                                <div class="tips">已经到底了~</div>
                                <div v-if="rm_list && rm_list.length" class="pageing_box flex flex-center">
                                    <el-pagination small :pager-count="5" background layout="prev, pager, next"
                                        :total="rm_list.length" :page-size="pageSize_rm" :current-page="currentPage_rm"
                                        @current-change="rmChange" class="custom_pagination"></el-pagination>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>



            </div>


        </div>

        <el-dialog v-if="banner3.data && banner3.data.length" custom-class="show_ag_card" title="公告列表"
            :visible.sync="showAg">
            <el-row type="flex" justify="center">
                <el-col :lg="16" :md="18" :sm="20" :xs="22">
                    <div class="aglist">
                        <div v-for="(item, index) in banner3.data" :key="index" @click.stop="agDetail(item)"
                            class="show_ag_card_item">
                            <div class="r1">
                                <div class="r1_dot" :class="{ 'act': item.read_status === 0 }"></div>
                                <div class="r1_text ellipsis" :class="{ 'act': item.read_status === 0 }">{{ item.name }}
                                </div>
                            </div>
                            <div class="r2" :class="{ 'act': item.read_status === 0 }">{{ formatTime(item.create_time) }}
                            </div>
                        </div>
                        <div class="tips">已经到底了~</div>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
        <div class="showbarhei">
            <el-dialog custom-class="show_video_bar" :visible.sync="showVideo">
                <div class="video_box">
                    <div class="video_room">
                        <video controls style="width: 100%; height: 100%;">
                            <source :src="setHttps(video_url)" type="video/mp4">
                        </video>
                    </div>
                </div>
            </el-dialog>
        </div>


    </div>
</template>
<script>
module.exports = {
    data: function () {
        return {
            loading: false,
            fildval: '',
            showVideo: false,
            config: '',

            video_url: '',

            showAg: false,
            tab_tree: ['优质航司', '明星企业', '热门推荐'],
            tree_num: 0,

            banner1: '',//顶部轮播
            banner2: '',//中间轮播
            banner3: '',//公告
            ent_list: '',//金刚区

            hs_list: '',
            mx_list: '',
            rm_list: '',

            currentPage_rm: 1,
            pageSize_rm: 12,
            setItemsRm: '',//展示列表

            currentPage_mx: 1,
            pageSize_mx: 12,
            setItemsMx: '',//展示列表

        }
    },
    creatted() {
    },
    mounted() {
        console.log('================my================')
        this.getBanner1()// 顶部轮播
        this.getBanner2()// 中间轮播
        this.getAg()// 公告
        this.getEnt()// 金刚区
        this.getConfig()

        this.getHs()
        this.getmingxing()
        this.getremen()

    },
    methods: {
        getConfig() {
            console.log('2222')
            this.$('/api/Index/getBaseConfig').then(res => {
                console.log('配置信息1', res.data)
                this.config = JSON.parse(JSON.stringify(res.data))
                localStorage.setItem('config', JSON.stringify(res.data));
                console.log('配置信息2', this.config)
                // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
                document.head.querySelectorAll("link").forEach(link => {
                    if (link.rel === "icon") {
                        link.parentNode?.removeChild(link);
                    }
                });
                const link = document.createElement('link');
                link.rel = "icon";
                link.href = this.setHttps(res.data.icon); // 将网络图片的URL赋给href属性
                document.head.appendChild(link);
            })
        },
        // 搜索
        baseSearchClick() {
            // console.log('搜索触发----')
            if (this.tree_num === 1) {
                this.loading = true
                this.$('/api/Product/getProductList', {
                    is_excellent: 1,
                    search_key: this.fildval
                }).then(res => {
                    // console.log('tab2===', res.data)
                    this.mx_list = res.data;
                    // 根据当前页和分页大小计算要显示的数据范围
                    let startIndex = (this.currentPage_mx - 1) * this.pageSize_mx;
                    let endIndex = startIndex + this.pageSize_mx;
                    this.setItemsMx = this.mx_list.slice(startIndex, endIndex);
                }).finally(() => {
                    this.loading = false
                })
            }
            if (this.tree_num === 2) {
                this.loading = true
                this.$('/api/Product/getProductList', {
                    is_popular: 1,
                    search_key: this.fildval
                }).then(res => {
                    // console.log('tab3===', res.data)
                    this.rm_list = res.data;
                    // 根据当前页和分页大小计算要显示的数据范围
                    let startIndex = (this.currentPage_rm - 1) * this.pageSize_rm;
                    let endIndex = startIndex + this.pageSize_rm;
                    this.setItemsRm = this.rm_list.slice(startIndex, endIndex);
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        // 跳转公告详情
        agDetail(info) {
            this.$router.push({ name: 'richDetailAg', query: { id: info.id } });
        },
        // 展示隐藏公告
        agClcik() {
            this.showAg = !this.showAg
        },
        // 跳转富文本详情页
        jumpRich(info, type) {
            // console.log('=============', info, type)
            if (type === 'banner') {
                // 详情，富文本页 2banner富文本
                if ([2].includes(info.jump_type)) {
                    this.$router.push({ name: 'richDetail', query: { id: info.id, table: type, titname: info.name } });
                }
                //跳转外链
                if ([1].includes(info.jump_type)) {
                    window.open(info.url, '_blank')
                }
                //打开视频弹窗播放
                if ([3].includes(info.jump_type)) {
                    this.video_url = info.video
                    this.showVideo = !this.showVideo
                }
            }
        },
        // 跳转金刚列表
        jumpJgList(info) {
            this.$router.push({ name: 'hslist', query: { id: info.id, infoname: info.name, fieldval: 'jg' } });
        },
        // 跳转优质航司列表
        jumpHsList(info) {
            this.$router.push({ name: 'hslist', query: { id: info.id, infoname: info.name, infocode: info.code, fieldval: 'hs' } });
        },
        // 明星切换分页
        mxChange(num) {
            this.currentPage_mx = num;
            // 根据当前页和分页大小计算要显示的数据范围
            let startIndex = (this.currentPage_mx - 1) * this.pageSize_mx;
            let endIndex = startIndex + this.pageSize_mx;
            this.setItemsMx = this.rm_list.slice(startIndex, endIndex);
        },
        // 热门切换分页
        rmChange(num) {
            this.currentPage_rm = num;
            // 根据当前页和分页大小计算要显示的数据范围
            let startIndex = (this.currentPage_rm - 1) * this.pageSize_rm;
            let endIndex = startIndex + this.pageSize_rm;
            this.setItemsRm = this.rm_list.slice(startIndex, endIndex);
        },
        // 获取热门
        getremen() {
            this.loading = true
            this.$('/api/Product/getProductList', { is_popular: 1 }).then(res => {
                // console.log('tab3===', res.data)
                this.rm_list = res.data;
                // 根据当前页和分页大小计算要显示的数据范围
                let startIndex = (this.currentPage_rm - 1) * this.pageSize_rm;
                let endIndex = startIndex + this.pageSize_rm;
                this.setItemsRm = this.rm_list.slice(startIndex, endIndex);
            }).finally(() => {
                this.loading = false
            })
        },
        // 获取明星企业
        getmingxing() {
            this.loading = true
            this.$('/api/Product/getProductList', { is_excellent: 1 }).then(res => {
                // console.log('tab2===', res.data)
                this.mx_list = res.data;
                // 根据当前页和分页大小计算要显示的数据范围
                let startIndex = (this.currentPage_mx - 1) * this.pageSize_rm;
                let endIndex = startIndex + this.pageSize_rm;
                this.setItemsMx = this.mx_list.slice(startIndex, endIndex);
            }).finally(() => {
                this.loading = false
            })
        },
        // 获取优质航司
        getHs() {
            this.$('/api/Product/getCompanyList').then(res => {
                this.hs_list = res.data;
            })
        },
        // tab切换
        treeClick(num) {
            this.fildval = ''
            if (num === 1) {
                this.getmingxing()
            }
            if (num === 2) {
                this.getremen()
            }
            this.tree_num = num;
        },
        // 金刚区
        getEnt() {
            this.$('/api/Product/getTradeList').then(res => {
                // console.log('金刚区===', res.data)
                this.ent_list = res.data
            })
        },
        // 轮播
        getBanner1() {
            this.$('/api/Index/getBannerList', {
                type: 1,
                show_type: 1
            }).then(res => {
                // console.log('banner===', res.data)
                this.banner1 = res.data
            })
        },
        // 轮播
        getBanner2() {
            this.$('/api/Index/getBannerList', {
                type: 1,
                show_type: 2
            }).then(res => {
                // console.log('banner===', res.data)
                this.banner2 = res.data
            })
        },
        // 公告
        getAg() {
            this.$('/api/Index/getNoticeList').then(res => {
                // console.log('公告===', res.data)
                this.banner3 = res.data
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.home {
    width: 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 28%, #F6F6F6 100%);

    .showbarhei {
        &::v-deep .el-icon-close:before {
            color: #fff;
        }

        // 
        &::v-deep .el-dialog {
            background-color: rgba(0, 0, 0, 0);
            box-shadow: none
        }

        &::v-deep .el-dialog__header {
            text-align: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
        }


    }

    .show_video_bar {
        .video_box {
            background-color: #000;
            padding: 15px;

            .video_room {
                background-color: #999;
            }
        }
    }

    ::v-deep .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
        font-size: 24px;
    }

    ::v-deep .el-icon-close:before {
        color: #333;
    }

    .base_search {
        padding: 20px 0;

        .base_search_box {
            width: 100%;
            height: 55px;
            padding: 0 20px;
            border-radius: 10px;
            box-shadow: 0px 5px 5px 0px rgba(153, 153, 153, 0.15);
            background-color: #fff;
            display: grid;
            grid-template-columns: calc(100% - 24px) 24px;
            justify-content: space-between;
            align-items: center;

            .base_search_box_ipt {
                width: 100%;
                height: 100%;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333;

            }

            .base_search_box_icon {
                width: 24px;
                height: 24px;
            }
        }
    }



    .show_ag_card {

        .aglist {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 10px;
            align-content: flex-start;

            .show_ag_card_item {
                width: 100%;
                height: 77px;
                border-radius: 10px;
                border: 1px solid #eee;
                padding: 15px;
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                row-gap: 10px;
                justify-content: flex-start;
                align-items: center;

                .r1 {
                    display: grid;
                    grid-template-columns: 8px calc(100% - 8px - 5px);
                    column-gap: 5px;
                    justify-content: flex-start;
                    align-items: center;

                    .r1_dot {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: rgba(0, 0, 0, 0);

                        &.act {
                            background-color: #FF3E3E;
                        }
                    }

                    .r1_text {
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #B9B9BD;

                        &.act {
                            color: #000;
                        }
                    }
                }

                .r2 {
                    padding-left: 13px;
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #B9B9BD;

                    &.act {
                        color: #666;
                    }
                }
            }

            .tips {
                padding-top: 35px;
                text-align: center;
                font-size: 13px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
            }
        }

    }

    ::v-deep .el-dialog__wrapper .el-dialog {
        border-radius: 30px;
    }

    ::v-deep .el-dialog__header {
        border-bottom: 1px solid #eee;
    }

    .banner_item {
        width: 100%;
        height: auto;
    }

    .main {
        min-height: 100vh;

        .main_room {
            // position: relative;
            // top: -40px;
            // left: 0;
            // z-index: 10;

            .ag {
                border-radius: 10px;
                background-color: #fff;
                box-shadow: 0px 10px 10px 0px rgba(153, 153, 153, 0.1);
                height: 80px;
                padding: 0 25px;
                display: grid;
                grid-template-columns: 40px calc(100% - 40px - 20px - 50px) 20px;
                column-gap: 25px;
                align-items: center;

                .ag_img {
                    width: 40px;
                    height: 40px;
                }

                .ag_cont {
                    width: 100%;
                    height: 80px;
                    line-height: 80px;

                    .ag_cont_text {
                        font-size: 16px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                }

                .ag_icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .ent_bar {
                padding: 4.16666vw 0;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                row-gap: 2.44791vw;
                column-gap: 5.46875vw;
                align-items: center;

                .ent_bar_item {
                    width: 100%;
                    padding: 1.30208vw 0 1.5625vw;
                    border-radius: 0.78125vw;
                    box-shadow: 0px 19px 25px 0px rgba(0, 0, 0, 0.05);
                    background-color: #fff;

                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    row-gap: 0.260416vw;
                    justify-items: center;
                    align-content: center;

                    .ent_bar_item_img {
                        width: 5.20833vw;
                        height: 5.20833vw;
                        min-width: 50px;
                        min-height: 50px;
                    }

                    .ent_bar_item_text {
                        font-size: 24px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #482221;

                        &.hidden-sm-and-up {
                            font-size: 18px;
                        }
                    }
                }
            }

            // 中间轮播
            .center_banner {
                padding: 0 0 80px;

                .banner_item2 {
                    width: 100%;
                    height: 100%;
                }
            }

            // tab
            .tab_box {
                display: grid;
                grid-template-columns: auto auto auto;
                column-gap: 2.60416vw;
                justify-content: flex-start;
                align-items: center;

                .tab_item {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    row-gap: 3px;
                    justify-items: center;
                    align-content: center;

                    .tab_item_text {
                        font-size: 20px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;

                        &.act {
                            font-weight: 600;
                            color: #482221;
                        }
                    }

                    .tab_item_link {
                        width: 35px;
                        height: 3px;
                        border-radius: 5px;
                        background-color: rgba(0, 0, 0, 0);

                        &.act {
                            background-color: #CEB05A;
                        }
                    }

                }
            }

            .tab_list {
                padding: 20px 0 58px;

                .tab_list_box {
                    display: grid;
                    grid-template-columns: repeat(6, 1fr);
                    column-gap: 0.9375vw;
                    row-gap: 0.9375vw;
                    align-content: flex-start;
                    justify-items: center;

                    .tab_list_box_item {
                        width: 100%;
                        border-radius: 0.520833vw;
                        overflow: hidden;

                        .tab_list_box_item_img {
                            width: 100%;
                            height: auto;
                        }

                    }
                }


                .tips {
                    padding-top: 35px;
                    text-align: center;
                    font-size: 13px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                }

                .pageing_box {
                    padding: 30px 0 60px;

                    .custom_pagination::v-deep .el-pager li.active {
                        background-color: #CEB05A;
                        /* 设置选中页码的背景颜色 */
                    }

                    .custom_pagination::v-deep .el-pager li:not(.active):hover {
                        color: #CEB05A;
                        /* 修改悬停时的字体颜色 */
                    }
                }
            }


            .hidden-sm-and-up {
                .tab_list {
                    .tab_list_box {
                        grid-template-columns: repeat(2, 1fr);
                        column-gap: 10px;
                        row-gap: 20px;
                    }

                }
            }



        }
    }

}

@media (max-width: 768px) {
    ::v-deep .el-dialog {
        width: 90% !important;
        /* 小屏幕时宽度改为80% */
    }
}
</style>
