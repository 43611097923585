<template>
  <div class="classify_page">
    <BaseHeader v-if="config" :headerNum="3" :config="config"></BaseHeader>

    <div class="main">


      <!-- 轮播 -->
      <el-carousel height="26.041vw" class="hidden-xs-only" :interval="Number(banner1.interval)" :loop="true">
        <el-carousel-item v-for="(item, index) in banner1.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>
      </el-carousel>
      <el-carousel height="26.041vw" class="hidden-sm-and-up" :interval="Number(banner1.interval)" :loop="true">
        <el-carousel-item v-for="(item, index) in banner1.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>
      </el-carousel>



      <!-- 展示区 -->
      <div class="base_width main_room">
        <el-row type="flex" justify="center">
          <el-col :lg="24" :md="20" :sm="20" :xs="20">
            <div class="search">
              <div class="search_box">
                <input v-model="fildval" @change="searchClick" class="search_box_ipt" placeholder="请输入搜索关键字" type="text">
                <el-image @click.stop="searchClick" class="search_box_icon sethand"
                  :src="require('@/assets/img/s1.png')"></el-image>
              </div>
            </div>
          </el-col>
        </el-row>


        <el-row type="flex" justify="center">
          <el-col :lg="24" :md="20" :sm="20" :xs="20">
            <div class="page_box" v-loading="loading">
              <div class="page_box_title">
                <div v-for="(item, index) in tab_tree" :key="index" @click.stop="tabClick(index)"
                  class="page_box_title_item sethand">
                  <div class="page_box_title_item_text" :class="{ 'act': tab_num === index }">{{ item }}</div>
                  <div class="page_box_title_item_link" :class="{ 'act': tab_num === index }"></div>
                </div>
              </div>

              <div v-if="tab_num === 0" class="page_box_tab_list0">
                <div v-if="list_arr0">

                  <div class="tab_list_box0">
                    <div class="publice_coupling_menu">
                      <div v-for="(item, index) in list_arr0" :key="index">
                        <div class="menu_item sethand" @click.stop="menuClick(index)">
                          <div class="menu_item_room flex flex-between"
                            :class="{ 'act': menuIndex === index, 'radius_last': menuIndex === (index - 1), 'radius_first': ((index === (menuIndex - 1)) && (menuIndex > 0)) }">
                            <div class="text_sign have_color"></div>
                            <div class="">{{ item.name }}</div>
                            <div class="text_sign"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="publice_coupling_list">

                      <div v-if="setItems0.length" style="width: 100%;">
                        <el-row class="publice_coupling_list_top">
                          <el-col :lg="2" :md="3" :sm="4" :xs="8" v-for="(item, index) in setItems0" :key="index">
                            <div class="publice_coupling_list_top_item sethand" @click.stop="jumpClassList(item)">
                              <div class="publice_coupling_list_top_item_top">{{ item.code }}</div>
                              <div class="publice_coupling_list_top_item_bot ellipsis">{{ item.name }}</div>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                      <div v-else>
                        <el-empty description="暂无数据"></el-empty>
                      </div>

                      <el-row type="flex" justify="center">
                        <el-col :span="8">
                          <div class="publice_coupling_list_bot flex flex-center">
                            <el-pagination small :pager-count="5" background layout="prev, pager, next"
                              :total="list_arr0[this.menuIndex].children.length" :page-size="pageSize0"
                              :current-page="currentPage0" @current-change="hsChange0"
                              class="custom_pagination"></el-pagination>
                          </div>
                        </el-col>
                      </el-row>

                    </div>
                  </div>
                </div>
                <div v-else>
                  <el-empty description="暂无数据"></el-empty>
                </div>


              </div>

              <div v-if="tab_num === 1" class="page_box_tab_list">
                <div class="tab_list_box">
                  <el-row :gutter="18" class="publice_coupling_list_top">
                    <el-col :lg="4" :md="6" :sm="8" :xs="12" v-for="(item, index) in setItems2" :key="index"
                      style="margin-bottom: 20px;">
                      <indexItem :obj="item" :currenttab="3"></indexItem>
                    </el-col>
                  </el-row>
                </div>
                <div class="tips">已经到底了~</div>
                <el-row type="flex" justify="center">
                  <el-col :span="8">
                    <div class="pageing_box flex flex-center">
                      <el-pagination small :pager-count="5" background layout="prev, pager, next"
                        :total="list_arr2.length" :page-size="pageSize2" :current-page="currentPage2"
                        @current-change="hsChange2" class="custom_pagination"></el-pagination>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <div v-if="tab_num === 2" class="page_box_tab_list">
                <div class="tab_list_box3">
                  <el-row :gutter="57" class="publice_coupling_list_top">
                    <el-col :lg="8" :md="12" :sm="12" :xs="24">
                      <div class="column">
                        <div v-for="(item, index) in list_arr3" :key="index">
                          <classifyItem v-if="(index + 4) % 3 === 1" :obj="item" :currenttab="3" @getList3="getList3">
                          </classifyItem>
                        </div>
                      </div>
                    </el-col>
                    <el-col :lg="8" :md="12" :sm="12" :xs="24">
                      <div class="column">
                        <div v-for="(item, index) in list_arr3" :key="index">
                          <classifyItem v-if="(index + 4) % 3 === 2" :obj="item" :currenttab="3" @getList3="getList3">
                          </classifyItem>
                        </div>
                      </div>
                      <div class="column">
                        <div v-for="(item, index) in list_arr3" :key="index">
                          <classifyItem v-if="(index + 4) % 3 === 0" :obj="item" :currenttab="3" @getList3="getList3">
                          </classifyItem>
                        </div>
                      </div>
                    </el-col>
                    <el-col :lg="8" :md="12" :sm="12" :xs="24">
                      <div class="column">
                        <div v-for="(item, index) in list_arr3" :key="index">
                          <classifyItem v-if="(index + 4) % 3 === 0" :obj="item" :currenttab="3" @getList3="getList3">
                          </classifyItem>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="tips">已经到底了~</div>
              </div>

            </div>
          </el-col>
        </el-row>


      </div>


    </div>
  </div>
</template>
<script>
module.exports = {
  data: function () {
    return {
      fildval: '',
      config: '',

      banner1: '',
      tab_tree: ['国际航线', '热门推荐', '资源推荐'],
      tab_num: 0,
      loading: false,

      menuIndex: 0,

      list_arr0: '',
      currentPage0: 1,
      pageSize0: 48,
      setItems0: '',


      info: '',//携带进来的数据
      param: {
        company_id: '',//优质航司
      },

      list_arr2: '',
      currentPage2: 1,
      pageSize2: 12,
      setItems2: '',

      list_arr3: '',


    }
  },
  created() {
    // 获取名为'info'的参数
    let infodata = this.$route.query.info;
    if (infodata) {
      this.info = JSON.parse(decodeURIComponent(infodata));
      this.param.company_id = this.info.id
      console.log('info===', this.info)
    }

  },
  mounted() {
    this.getConfig()

    this.getBanner1()
    this.getList2()
    this.getList3()
    this.getClass()
  },
  methods: {
    getConfig() {
      console.log('2222')
      this.$('/api/Index/getBaseConfig').then(res => {
        console.log('配置信息1', res.data)
        this.config = JSON.parse(JSON.stringify(res.data))
        localStorage.setItem('config', JSON.stringify(res.data));
        console.log('配置信息2', res.data)
        // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
        document.head.querySelectorAll("link").forEach(link => {
          if (link.rel === "icon") {
            link.parentNode?.removeChild(link);
          }
        });
        const link = document.createElement('link');
        link.rel = "icon";
        link.href = this.setHttps(res.data.icon); // 将网络图片的URL赋给href属性
        document.head.appendChild(link);
      })
    },
    // 搜索
    searchClick() {
      console.log('搜索触发----')
      if (!this.fildval) {
        this.getBanner1()
        this.getList2()
        this.getList3()
        this.getClass()
        return;
      }
      // tab1时
      if (this.tab_num === 0) {
        this.loading = true
        this.$('/api/Product/getHarborList').then(res => {
          this.list_arr0 = res.data
          // 先匹配左侧栏，命中进行滚动
          console.log('sharch1======', this.list_arr0)
          if (this.list_arr0.find(i => i.name === this.fildval)) {
            this.menuIndex = this.list_arr0.findIndex(i => i.name === this.fildval)
            // 切换时先清空
            this.setItems0 = '';
            this.currentPage0 = 1;
            // 根据当前页和分页大小计算要显示的数据范围
            let startIndex = (this.currentPage0 - 1) * this.pageSize0;
            let endIndex = startIndex + this.pageSize0;
            this.setItems0 = this.list_arr0[this.menuIndex].children.slice(startIndex, endIndex);
          } else {
            // 未命中给模糊查询子项进行展示
            let resultArray = this.filterArray(this.list_arr0);
            console.log('过滤后====', resultArray);
            this.list_arr0 = resultArray
            let startIndex = (this.currentPage0 - 1) * this.pageSize0;
            let endIndex = startIndex + this.pageSize0;
            this.setItems0 = this.list_arr0[this.menuIndex].children.slice(startIndex, endIndex);
          }
        }).finally(() => {
          this.loading = false
        })
      }

      // tab2时
      if (this.tab_num === 1) {
        this.loading = true
        this.$('/api/Product/getProductList', {
          is_popular: 1,
          search_key: this.fildval
        }).then(res => {
          console.log('列表---', res)
          this.list_arr2 = res.data
          // 根据当前页和分页大小计算要显示的数据范围
          let startIndex = (this.currentPage2 - 1) * this.pageSize2;
          let endIndex = startIndex + this.pageSize2;
          this.setItems2 = this.list_arr2.slice(startIndex, endIndex);
        }).finally(() => {
          this.loading = false
        })

      }
      // tab3时
      if (this.tab_num === 2) {
        this.loading = true
        this.$('/api/Index/getResourceList', {
          search_key: this.fildval
        }).then(res => {
          console.log('列表3---', res)
          this.list_arr3 = res.data
        }).finally(() => {
          this.loading = false
        })
      }




    },
    // 分类模糊查子项
    filterArray(arr) {
      let filteredArray = arr.map(item => {
        let filteredChildren = item.children.filter(child => child.name.includes(this.fildval.toUpperCase()));
        if (filteredChildren.length > 0) {
          item.children = filteredChildren
          return item;
        }
        return null;
      });

      return filteredArray.filter(item => item !== null);
    },
    // 跳转分类列表页
    jumpClassList(info) {
      console.log('====', info)
      this.$router.push({ name: 'classList', query: { id: info.id, infoname: info.name, infoobj: info.obj } });
    },
    // 切换分页
    hsChange0(num) {
      // 切换时先清空
      this.setItems0 = '';

      this.currentPage0 = num;
      // 根据当前页和分页大小计算要显示的数据范围
      let startIndex = (this.currentPage0 - 1) * this.pageSize0;
      let endIndex = startIndex + this.pageSize0;
      this.setItems0 = this.list_arr0[this.menuIndex].children.slice(startIndex, endIndex);
    },
    // 选中左列元素
    menuClick(idx) {
      console.log("menu===", idx)
      this.menuIndex = idx
      // 切换时先清空
      this.setItems0 = '';
      this.currentPage0 = 1;
      // 根据当前页和分页大小计算要显示的数据范围
      let startIndex = (this.currentPage0 - 1) * this.pageSize0;
      let endIndex = startIndex + this.pageSize0;
      this.setItems0 = this.list_arr0[this.menuIndex].children.slice(startIndex, endIndex);

    },
    // 获取分类数据
    getClass() {
      this.loading = true
      this.$('/api/Product/getHarborList').then(res => {
        this.list_arr0 = res.data
        // 根据当前页和分页大小计算要显示的数据范围
        let startIndex = (this.currentPage0 - 1) * this.pageSize0;
        let endIndex = startIndex + this.pageSize0;
        this.setItems0 = this.list_arr0[this.menuIndex].children.slice(startIndex, endIndex);
      }).finally(() => {
        this.loading = false
      })

    },
    // tab切换
    tabClick(num) {
      this.fildval = ""
      this.tab_num = num
    },
    // 轮播
    getBanner1() {
      this.$('/api/Index/getBannerList', {
        type: 1,
        show_type: 5
      }).then(res => {
        console.log('banner===', res.data)
        this.banner1 = res.data
      })
    },
    // 获取tab3列表'/api/Index/getResourceList'
    // 获取资源列表
    getList3() {
      this.$('/api/Index/getResourceList').then(res => {
        console.log('列表3---', res)
        this.list_arr3 = res.data
      })
    },


    // 获取热门列表
    getList2() {
      this.$('/api/Product/getProductList', {
        is_popular: 1
      }).then(res => {
        console.log('列表---', res)
        this.list_arr2 = res.data
        // 根据当前页和分页大小计算要显示的数据范围
        let startIndex = (this.currentPage2 - 1) * this.pageSize2;
        let endIndex = startIndex + this.pageSize2;
        this.setItems2 = this.list_arr2.slice(startIndex, endIndex);
      })
    },
    // 切换分页
    hsChange2(num) {
      this.currentPage2 = num;
      // 根据当前页和分页大小计算要显示的数据范围
      let startIndex = (this.currentPage2 - 1) * this.pageSize2;
      let endIndex = startIndex + this.pageSize2;
      this.setItems2 = this.list_arr2.slice(startIndex, endIndex);
    },



  }
}
</script>
<style lang="scss" scoped>
.classify_page {
  width: 100%;
  background-color: #f6f6f6;

  .banner_item {
    width: 100%;
    height: auto;
  }

  .main {
    min-height: 100vh;

    .main_room {
      position: relative;
      top: -3vw;
      left: 0;
      z-index: 10;

      .search {
        padding-bottom: 10px;

        .search_box {
          width: 100%;
          height: 55px;
          padding: 0 20px;
          border-radius: 10px;
          box-shadow: 0px 5px 5px 0px rgba(153, 153, 153, 0.15);
          background-color: #fff;
          display: grid;
          grid-template-columns: calc(100% - 24px) 24px;
          justify-content: space-between;
          align-items: center;

          .search_box_ipt {
            width: 100%;
            height: 100%;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333;

          }

          .search_box_icon {
            width: 24px;
            height: 24px;
          }
        }
      }

      .page_box {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0px 10px 10px 0px rgba(153, 153, 153, 0.1);

        .page_box_title {
          width: 100%;
          height: 60px;
          border-bottom: 1px solid #eee;
          display: grid;
          grid-template-columns: auto auto auto;
          justify-content: center;
          align-items: flex-end;

          .page_box_title_item {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            padding: 0 3.125vw;
            row-gap: 3px;
            justify-items: center;
            align-content: flex-end;

            .page_box_title_item_text {
              font-size: 20px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;

              &.act {
                font-weight: 600;
                color: #482221;
              }
            }

            .page_box_title_item_link {
              width: 35px;
              height: 3px;
              border-radius: 5px;
              background-color: rgba(0, 0, 0, 0);

              &.act {
                background-color: #ceb05a;
              }
            }
          }

        }

        .page_box_tab_list0 {
          .tab_list_box0 {
            min-height: 590px;
            display: grid;
            grid-template-columns: 82px calc(100% - 82px);
            align-content: flex-start;

            .publice_coupling_menu {
              min-height: 590px;
              width: 82px;
              background-color: #f7f7f7;

              .menu_item {
                background-color: #fff;

                .menu_item_room {
                  width: 100%;
                  height: 58px;
                  background-color: #f7f7f7;
                  font-size: 13px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;

                  .text_sign {
                    width: 4px;
                    height: 25px;
                    border-radius: 1.5px;
                    background-color: rgba(0, 0, 0, 0);
                  }

                  &.radius_last {
                    border-top-right-radius: 10px;
                  }

                  &.radius_first {
                    border-bottom-right-radius: 10px;
                  }

                  &.act {
                    background-color: rgba(0, 0, 0, 0);
                    font-size: 13px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #CEB05A;

                    .have_color {
                      background-color: #CEB05A;
                    }
                  }
                }
              }
            }

            .publice_coupling_list {
              width: 100%;

              display: grid;
              grid-template-columns: repeat(1, 1fr);
              row-gap: 5px;

              .publice_coupling_list_top {
                min-height: 590px;
                width: 100%;
                padding: 1.302083vw;
                background-color: #fff;
                // display: grid;
                // grid-template-columns: repeat(11, 1fr);
                // column-gap: 2.08333vw;
                // row-gap: 25px;
                // align-content: flex-start;



                .publice_coupling_list_top_item {
                  width: 100%;
                  height: 100%;
                  display: grid;
                  grid-template-columns: repeat(1, 1fr);
                  justify-items: center;
                  align-content: center;
                  row-gap: 0.78125vw;
                  margin-bottom: 25px;

                  .publice_coupling_list_top_item_top {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 10px;
                    background: linear-gradient(134deg, #CEB05A 0%, rgba(206, 176, 90, 0.8) 100%);
                    font-size: 20px;
                    font-family: Arial-Bold, Arial;
                    font-weight: bold;
                    color: #FFFFFF;
                    text-align: center;
                  }

                  .publice_coupling_list_top_item_bot {
                    text-align: center;
                    font-size: 13px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                  }

                }

              }


              .publice_coupling_list_bot {
                padding: 1.5625vw 0 3.125vw;

                .custom_pagination::v-deep .el-pager li.active {
                  background-color: #CEB05A;
                  /* 设置选中页码的背景颜色 */
                }

                .custom_pagination::v-deep .el-pager li:not(.active):hover {
                  color: #CEB05A;
                  /* 修改悬停时的字体颜色 */
                }
              }

            }


          }
        }

        .page_box_tab_list {
          padding: 20px 25px 58px;

          .tab_list_box {
            min-height: 590px;
          }

          .tab_list_box3 {
            min-height: 590px;

            .column {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              align-content: flex-start;
            }
          }
        }

        .tips {
          padding-top: 35px;
          text-align: center;
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }

        .pageing_box {
          padding: 30px 0 60px;

          .custom_pagination::v-deep .el-pager li.active {
            background-color: #CEB05A;
            /* 设置选中页码的背景颜色 */
          }

          .custom_pagination::v-deep .el-pager li:not(.active):hover {
            color: #CEB05A;
            /* 修改悬停时的字体颜色 */
          }
        }
      }

    }
  }


}
</style>
