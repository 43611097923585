<template>
  <div class="make_page">
    <BaseHeader v-if="config" :headerNum="1" :config="config"></BaseHeader>

    <div class="main">


      <!-- 轮播 -->
      <el-carousel height="26.041vw" class="hidden-xs-only" :interval="Number(banner1.interval)" :loop="true">

        <el-carousel-item v-for="(item, index) in banner1.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>

      </el-carousel>
      <el-carousel height="26.041vw" class="hidden-sm-and-up" :interval="Number(banner1.interval)" :loop="true">

        <el-carousel-item v-for="(item, index) in banner1.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>

      </el-carousel>

      <!-- 展示区 -->
      <div class="base_width main_room">

        <el-row type="flex" justify="center">
          <el-col :lg="24" :md="22" :sm="22" :xs="22">
            <div class="page_box">

              <div class="page_box_title">
                <div class="page_box_title_left">
                  <div class="page_box_title_left_text">送仓预约</div>
                  <div class="page_box_title_left_link"></div>
                </div>
              </div>
              <div class="map_bar">
                <div class="map_bar_box base_shadow hidden-md-and-down">
                  <div class="map_cont">
                    <div v-if="config" class="map_cont_box">
                      <el-image class="map_item" :src="require('@/assets/img/map1.png')"></el-image>
                    </div>
                  </div>
                  <div class="map_info">
                    <div class="map_info_item">
                      <el-image class="map_info_item_icon" :src="require('@/assets/img/location.png')"></el-image>
                      <div class="map_info_item_cont">
                        <div class="map_info_item_cont_title">送仓地址</div>
                        <div class="map_info_item_cont_text" style="user-select: text;">{{ config_infomake.addr }}</div>
                      </div>
                    </div>
                    <div class="map_info_item">
                      <el-image class="map_info_item_icon" :src="require('@/assets/img/time1.png')"></el-image>
                      <div class="map_info_item_cont">
                        <div class="map_info_item_cont_title">运营时间</div>
                        <div class="map_info_item_cont_text">{{ config_infomake.time }}</div>
                      </div>
                    </div>
                    <div class="map_info_item">
                      <el-image class="map_info_item_icon" :src="require('@/assets/img/eml1.png')"></el-image>
                      <div class="map_info_item_cont">
                        <div class="map_info_item_cont_title">客服邮箱</div>
                        <div class="map_info_item_cont_text" style="user-select: text;">{{ config_infomake.email }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="map_bar_box base_shadow hidden-lg-and-up">
                  <div class="map_cont">
                    <div v-if="config" class="map_cont_box">
                      <el-image class="map_item" :src="require('@/assets/img/map1.png')"></el-image>
                    </div>
                  </div>
                  <div class="map_info">
                    <div class="map_info_item">
                      <el-image class="map_info_item_icon" :src="require('@/assets/img/location.png')"></el-image>
                      <div class="map_info_item_cont">
                        <div class="map_info_item_cont_title">送仓地址</div>
                        <div class="map_info_item_cont_text" style="user-select: text;">{{ config_infomake.addr }}</div>
                      </div>
                    </div>
                    <div class="map_info_item">
                      <el-image class="map_info_item_icon" :src="require('@/assets/img/time1.png')"></el-image>
                      <div class="map_info_item_cont">
                        <div class="map_info_item_cont_title">运营时间</div>
                        <div class="map_info_item_cont_text">{{ config_infomake.time }}</div>
                      </div>
                    </div>
                    <div class="map_info_item">
                      <el-image class="map_info_item_icon" :src="require('@/assets/img/eml1.png')"></el-image>
                      <div class="map_info_item_cont">
                        <div class="map_info_item_cont_title">客服邮箱</div>
                        <div class="map_info_item_cont_text" style="user-select: text;">{{ config_infomake.email }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>





              <div class="form_bar base_shadow" v-loading="loading">

                <div class="form_item car_box">
                  <div class="bar_title flex flex-start">
                    <div class="bar_title_link"></div>
                    <div class="bar_title_text">车辆信息</div>
                  </div>

                  <div class="car_box_fill">

                    <div class="car_box_fill_top hidden-md-and-down">


                      <div class="fill_ipt">
                        <div class="fill_ipt_title">车牌号</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt">
                            <input v-model="param.vehicle_sn" class="fill_ipt_box_ipt_cont" placeholder="请填写车牌号"
                              type="text">
                          </div>
                          <div></div>
                        </div>
                      </div>

                      <div class="fill_ipt">
                        <div class="fill_ipt_title">车辆类型</div>
                        <div class="fill_ipt_box flex flex-end">
                          <el-select class="fill_ipt_box_ipt_cont" size="small" v-model="param.vehicle_type_id"
                            placeholder="请选择车辆类型">
                            <el-option v-for="item in car_rang" :key="item.value" :label="item.label"
                              :value="item.value"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="fill_ipt">
                        <div class="fill_ipt_title">车自重</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt">
                            <input v-model="param.vehicle_weight" class="fill_ipt_box_ipt_cont" placeholder="请填写车自重"
                              type="text">
                          </div>
                          <div class="fill_ipt_box_text">kg</div>
                        </div>
                      </div>

                      <div class="fill_ipt">
                        <div class="fill_ipt_title">驾驶员姓名</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt">
                            <input v-model="param.driver_name" class="fill_ipt_box_ipt_cont" placeholder="请填写驾驶员姓名"
                              type="text">
                          </div>
                          <div></div>
                        </div>
                      </div>

                      <div class="fill_ipt">
                        <div class="fill_ipt_title">联系电话</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt">
                            <input v-model="param.driver_phone" :maxlength="11" class="fill_ipt_box_ipt_cont"
                              placeholder="请填写联系电话" type="text">
                          </div>
                          <div></div>
                        </div>
                      </div>

                      <div class="fill_ipt">
                        <div class="fill_ipt_title">驾驶员身份证号</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt">
                            <input v-model="param.driver_idcard" class="fill_ipt_box_ipt_cont" placeholder="请填写驾驶员身份证号"
                              type="text">
                          </div>
                          <div></div>
                        </div>
                      </div>

                      <div class="fill_ipt sethand">
                        <div class="fill_ipt_title">到货时间</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt sethand">

                            <el-date-picker class="fill_ipt_box_ipt_cont" v-model="param.arrival_time" type="datetime"
                              placeholder="请选择到货时间">
                            </el-date-picker>

                          </div>
                          <el-image class="fill_ipt_box_icon" :src="require('@/assets/img/r3.png')"></el-image>
                        </div>
                      </div>


                    </div>
                    <div class="car_box_fill_top hidden-lg-and-up">


                      <div class="fill_ipt">
                        <div class="fill_ipt_title">车牌号</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt">
                            <input v-model="param.vehicle_sn" class="fill_ipt_box_ipt_cont" placeholder="请填写车牌号"
                              type="text">
                          </div>
                          <div></div>
                        </div>
                      </div>

                      <div class="fill_ipt">
                        <div class="fill_ipt_title">车辆类型</div>
                        <div class="fill_ipt_box flex flex-end">
                          <el-select class="fill_ipt_box_ipt_cont" size="small" v-model="param.vehicle_type_id"
                            placeholder="请选择车辆类型">
                            <el-option v-for="item in car_rang" :key="item.value" :label="item.label"
                              :value="item.value"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="fill_ipt">
                        <div class="fill_ipt_title">车自重</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt">
                            <input v-model="param.vehicle_weight" class="fill_ipt_box_ipt_cont" placeholder="请填写车自重"
                              type="text">
                          </div>
                          <div class="fill_ipt_box_text">kg</div>
                        </div>
                      </div>

                      <div class="fill_ipt">
                        <div class="fill_ipt_title">驾驶员姓名</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt">
                            <input v-model="param.driver_name" class="fill_ipt_box_ipt_cont" placeholder="请填写驾驶员姓名"
                              type="text">
                          </div>
                          <div></div>
                        </div>
                      </div>

                      <div class="fill_ipt">
                        <div class="fill_ipt_title">联系电话</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt">
                            <input v-model="param.driver_phone" :maxlength="11" class="fill_ipt_box_ipt_cont"
                              placeholder="请填写联系电话" type="text">
                          </div>
                          <div></div>
                        </div>
                      </div>

                      <div class="fill_ipt">
                        <div class="fill_ipt_title">驾驶员身份证号</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt">
                            <input v-model="param.driver_idcard" class="fill_ipt_box_ipt_cont" placeholder="请填写驾驶员身份证号"
                              type="text">
                          </div>
                          <div></div>
                        </div>
                      </div>

                      <div class="fill_ipt sethand">
                        <div class="fill_ipt_title">到货时间</div>
                        <div class="fill_ipt_box flex flex-end">
                          <div class="fill_ipt_box_ipt sethand">
                            <el-date-picker class="fill_ipt_box_ipt_cont" v-model="param.arrival_time" type="datetime"
                              placeholder="请选择到货时间">
                            </el-date-picker>
                          </div>
                          <el-image class="fill_ipt_box_icon" :src="require('@/assets/img/r3.png')"></el-image>
                        </div>
                      </div>


                    </div>


                    <div class="car_box_fill_bot">
                      <div class="car_box_fill_bot_title">上传行驶证照片</div>


                      <div class="car_box_fill_bot_img_box hidden-md-and-down">
                        <div class="car_box_fill_bot_img_box_item sethand">
                          <div v-if="this.param.license_principal" class="car_box_fill_bot_img_box_item_img">
                            <el-image @click.stop="upImgZy" class="car_box_fill_bot_img_box_item_img_img"
                              :src="setHttps(this.param.license_principal)"></el-image>
                          </div>
                          <div v-else class="car_box_fill_bot_img_box_item_img">
                            <el-image @click.stop="upImgZy" class="car_box_fill_bot_img_box_item_img_img"
                              :src="require('@/assets/img/zy.png')"></el-image>
                          </div>
                          <input ref="file_zy" style="display: none" @change="optImgZy" type="file">
                          <div class="car_box_fill_bot_img_box_item_text">{{ this.param.license_principal ? '行驶证主页' :
                            '请上传行驶证主页' }} </div>
                        </div>
                        <div class="car_box_fill_bot_img_box_item sethand">
                          <div v-if="this.param.license_deputy" class="car_box_fill_bot_img_box_item_img">
                            <el-image @click.stop="upImgFy" class="car_box_fill_bot_img_box_item_img_img"
                              :src="setHttps(this.param.license_deputy)"></el-image>
                          </div>
                          <div v-else class="car_box_fill_bot_img_box_item_img">
                            <el-image class="car_box_fill_bot_img_box_item_img_img" @click.stop="upImgFy"
                              :src="require('@/assets/img/fy.png')"></el-image>
                          </div>
                          <input ref="file_fy" style="display: none" @change="optImgFy" type="file">
                          <div class="car_box_fill_bot_img_box_item_text">{{ this.param.license_deputy ? '行驶证主页' :
                            '请上传行驶证副页' }} </div>
                        </div>
                      </div>
                      <div class="car_box_fill_bot_img_box hidden-lg-and-up">
                        <div class="car_box_fill_bot_img_box_item sethand">
                          <div v-if="this.param.license_principal" class="car_box_fill_bot_img_box_item_img">
                            <el-image @click.stop="upImgZy" class="car_box_fill_bot_img_box_item_img_img"
                              :src="setHttps(this.param.license_principal)"></el-image>
                          </div>
                          <div v-else class="car_box_fill_bot_img_box_item_img">
                            <el-image @click.stop="upImgZy" class="car_box_fill_bot_img_box_item_img_img"
                              :src="require('@/assets/img/zy.png')"></el-image>
                          </div>
                          <input ref="file_zy" style="display: none" @change="optImgZy" type="file">
                          <div class="car_box_fill_bot_img_box_item_text">{{ this.param.license_principal ? '行驶证主页' :
                            '请上传行驶证主页' }} </div>
                        </div>
                        <div class="car_box_fill_bot_img_box_item sethand">
                          <div v-if="this.param.license_deputy" class="car_box_fill_bot_img_box_item_img">
                            <el-image @click.stop="upImgFy" class="car_box_fill_bot_img_box_item_img_img"
                              :src="setHttps(this.param.license_deputy)"></el-image>
                          </div>
                          <div v-else class="car_box_fill_bot_img_box_item_img">
                            <el-image class="car_box_fill_bot_img_box_item_img_img" @click.stop="upImgFy"
                              :src="require('@/assets/img/fy.png')"></el-image>
                          </div>
                          <input ref="file_fy" style="display: none" @change="optImgFy" type="file">
                          <div class="car_box_fill_bot_img_box_item_text">{{ this.param.license_deputy ? '行驶证主页' :
                            '请上传行驶证副页' }} </div>
                        </div>
                      </div>


                    </div>

                  </div>

                </div>

                <div class="form_item good_box" v-loading="loading">
                  <div class="bar_title flex flex-start">
                    <div class="bar_title_link"></div>
                    <div class="bar_title_text">货物信息</div>
                  </div>
                  <div class="good_box_fill">

                    <div v-for="(item, index) in param.reservation" :key="index">
                      <div class="good_box_fill_top hidden-md-and-down">

                        <div class="fill_ipt">
                          <div class="fill_ipt_title">入仓单号</div>
                          <div class="fill_ipt_box flex flex-end">
                            <div class="fill_ipt_box_ipt">
                              <input v-model="param.reservation[index].reservation_sn" class="fill_ipt_box_ipt_cont"
                                placeholder="请填写入仓单号" type="text">
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <div class="fill_ipt">
                          <div class="fill_ipt_title">提运单号（选填）</div>
                          <div class="fill_ipt_box flex flex-end">
                            <div class="fill_ipt_box_ipt">
                              <input v-model="param.reservation[index].transport_sn" class="fill_ipt_box_ipt_cont"
                                placeholder="请填写提运单号" type="text">
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <div class="fill_ipt">
                          <div class="fill_ipt_title">包装件数</div>
                          <div class="fill_ipt_box flex flex-end">
                            <div class="fill_ipt_box_ipt">
                              <input v-model="param.reservation[index].goods_num" class="fill_ipt_box_ipt_cont"
                                placeholder="请填写包装件数" type="text">
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <div class="fill_ipt">
                          <div class="fill_ipt_title">包装种类</div>
                          <div class="fill_ipt_box flex flex-end">
                            <el-select class="fill_ipt_box_ipt_cont" size="small"
                              v-model="param.reservation[index].packaging_type_id" placeholder="请选择包装种类">
                              <el-option v-for="item in bz_rang" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                            </el-select>
                          </div>
                        </div>

                        <div class="fill_ipt">
                          <div class="fill_ipt_title">货物重量</div>
                          <div class="fill_ipt_box flex flex-end">
                            <div class="fill_ipt_box_ipt">
                              <input v-model="param.reservation[index].goods_weight" class="fill_ipt_box_ipt_cont"
                                placeholder="请填货物重量" type="text">
                            </div>
                            <div class="fill_ipt_box_text">kg</div>
                          </div>
                        </div>

                        <div class="fill_ipt">
                          <div class="fill_ipt_title">主要品名（酸甜）</div>
                          <div class="fill_ipt_box flex flex-end">
                            <div class="fill_ipt_box_ipt">
                              <input v-model="param.reservation[index].goods_name" class="fill_ipt_box_ipt_cont"
                                placeholder="主要品名（选填）" type="text">
                            </div>
                            <div></div>
                          </div>
                        </div>





                      </div>
                      <div class="good_box_fill_top hidden-lg-and-up">

                        <div class="fill_ipt">
                          <div class="fill_ipt_title">入仓单号</div>
                          <div class="fill_ipt_box flex flex-end">
                            <div class="fill_ipt_box_ipt">
                              <input v-model="param.reservation[index].reservation_sn" class="fill_ipt_box_ipt_cont"
                                placeholder="请填写入仓单号" type="text">
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <div class="fill_ipt">
                          <div class="fill_ipt_title">提运单号（选填）</div>
                          <div class="fill_ipt_box flex flex-end">
                            <div class="fill_ipt_box_ipt">
                              <input v-model="param.reservation[index].transport_sn" class="fill_ipt_box_ipt_cont"
                                placeholder="请填写提运单号" type="text">
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <div class="fill_ipt">
                          <div class="fill_ipt_title">包装件数</div>
                          <div class="fill_ipt_box flex flex-end">
                            <div class="fill_ipt_box_ipt">
                              <input v-model="param.reservation[index].goods_num" class="fill_ipt_box_ipt_cont"
                                placeholder="请填写包装件数" type="text">
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <div class="fill_ipt">
                          <div class="fill_ipt_title">包装种类</div>
                          <div class="fill_ipt_box flex flex-end">
                            <el-select class="fill_ipt_box_ipt_cont" size="small"
                              v-model="param.reservation[index].packaging_type_id" placeholder="请选择包装种类">
                              <el-option v-for="item in bz_rang" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                            </el-select>
                          </div>
                        </div>

                        <div class="fill_ipt">
                          <div class="fill_ipt_title">货物重量</div>
                          <div class="fill_ipt_box flex flex-end">
                            <div class="fill_ipt_box_ipt">
                              <input v-model="param.reservation[index].goods_weight" class="fill_ipt_box_ipt_cont"
                                placeholder="请填货物重量" type="text">
                            </div>
                            <div class="fill_ipt_box_text">kg</div>
                          </div>
                        </div>

                        <div class="fill_ipt">
                          <div class="fill_ipt_title">主要品名（酸甜）</div>
                          <div class="fill_ipt_box flex flex-end">
                            <div class="fill_ipt_box_ipt">
                              <input v-model="param.reservation[index].goods_name" class="fill_ipt_box_ipt_cont"
                                placeholder="主要品名（选填）" type="text">
                            </div>
                            <div></div>
                          </div>
                        </div>





                      </div>

                      <div v-if="((param.reservation.length - 1) === index)" class="good_box_fill_bot">
                        <div class="good_box_fill_bot_left sethand" @click.stop="addClick">继续添加下一组</div>
                        <div v-if="param.reservation.length > 1" class="good_box_fill_bot_right sethand"
                          @click.stop="delClick(index)">删除</div>
                      </div>
                      <div v-else class="good_box_fill_bot">
                        <div @click.stop="delClick(index)" class="good_box_fill_bot_right sethand">删除</div>
                      </div>
                    </div>




                  </div>
                </div>

                <div class="btn_box flex flex-center">
                  <div class="sub_btn sethand" v-loading="loading" @click.stop="subClick">确认提交</div>
                </div>
              </div>





            </div>
          </el-col>
        </el-row>



      </div>


    </div>
  </div>
</template>
<script>
module.exports = {
  data: function () {
    return {

      loading: false,
      config: '',

      banner1: '',
      config_infomake: '',//配置信息
      car_rang: [], //车辆分类
      bz_rang: [], //包装分类
      token: '',
      param: {

        vehicle_sn: '', //车牌
        vehicle_type_id: '', //车辆类型
        vehicle_weight: '', //车辆自重
        license_principal: '', //行驶证主页图片
        license_deputy: '', //行驶证副页图片
        driver_name: '', //司机姓名
        driver_phone: '', //司机电话
        driver_idcard: '', //司机身份证号
        arrival_time: '', //到货时间 YYYY-MM-DD HH:II:SS
        reservation: [{
          reservation_sn: "",
          transport_sn: "",
          goods_num: "",
          packaging_type_id: "",
          goods_weight: "",
          goods_name: ""
        }], //货物信息列表

      },

    }
  },
  created() {
    this.getInfo()//获取最后一次提交信息
    // this.config_infomake = JSON.parse(localStorage.getItem('config'))
    this.$('/api/Index/getReservationConfig').then(res => {
      console.log('配置信息:', res.data)
      this.config_infomake = res.data
    })
    console.log('配置信息2333:', this.config_infomake)

  },

  mounted() {
    this.getConfig()

    let record = JSON.parse(localStorage.getItem('record'))
    this.token = record ? (localStorage.getItem('token') || '') : (sessionStorage.getItem('token') || '');
    this.getCarClass() //车辆分类
    this.getbzClass() //包装分类
    this.getBanner1()
  },
  methods: {
    getConfig() {
      console.log('2222')
      this.$('/api/Index/getBaseConfig').then(res => {
        console.log('配置信息1', res.data)
        this.config = JSON.parse(JSON.stringify(res.data))
        localStorage.setItem('config', JSON.stringify(res.data));
        console.log('配置信息2', res.data)
        // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
        document.head.querySelectorAll("link").forEach(link => {
          if (link.rel === "icon") {
            link.parentNode?.removeChild(link);
          }
        });
        const link = document.createElement('link');
        link.rel = "icon";
        link.href = this.setHttps(res.data.icon); // 将网络图片的URL赋给href属性
        document.head.appendChild(link);
      })
    },
    // 获取信息
    getInfo() {
      this.loading = true
      this.$('/api/Reservation/getDriverInfo').then(res => {
        if (res.data) {
          console.log('回显数据===', res.data)
          this.param = {
            reservation: [{
              reservation_sn: "",
              transport_sn: "",
              goods_num: "",
              packaging_type_id: "",
              goods_weight: "",
              goods_name: ""
            }],
            arrival_time: '',
            ...res.data
          }

        }
        console.log('最后一次预约信息', this.param)
      }).finally(() => {
        this.loading = false
      })
    },
    // 主页上传
    upImgZy() {
      this.$refs.file_zy.click();
    },
    // 副页上传
    upImgFy() {
      this.$refs.file_fy.click();
    },
    // 选图片
    optImgFy(event) {
      let fileInput = event.target;
      let files = fileInput.files;

      if (files.length > 0) {
        let selectedFile = files[0];
        console.log('图', selectedFile)
        let formData = new FormData();
        formData.append('upload', selectedFile); // 将文件添加到FormData

        // 上传
        this.$axios.post('/api/Index/upload', formData)
          .then(response => {
            // 上传成功的处理逻辑
            console.log('文件上传成功', response.data.data);
            this.param.license_deputy = response.data.data
            this.$message({
              message: '上传成功',
              type: 'success'
            });

          })
          .catch(error => {
            // 上传失败的处理逻辑
            console.error('上传失败', error);
          });

      }
    },
    // 选图片
    optImgZy(event) {
      let fileInput = event.target;
      let files = fileInput.files;

      if (files.length > 0) {
        let selectedFile = files[0];
        console.log('图', selectedFile)
        let formData = new FormData();
        formData.append('upload', selectedFile); // 将文件添加到FormData

        // 上传
        this.$axios.post('/api/Index/upload', formData)
          .then(response => {
            // 上传成功的处理逻辑
            console.log('文件上传成功', response.data.data);
            this.param.license_principal = response.data.data
            this.$message({
              message: '上传成功',
              type: 'success'
            });

          })
          .catch(error => {
            // 上传失败的处理逻辑
            console.error('上传失败', error);
          });

      }
    },
    // 弹出
    showmd(txt) {
      this.$alert(`${txt}字段信息填写不完整`, '提示', {
        confirmButtonText: '重新填写',
        callback: () => {
          this.$message({
            type: 'info',
          });
        }
      });
    },

    // 提交
    subClick() {
      // let isValid = this.validateParam(this.param.reservation);
      if (!this.param.vehicle_sn) {
        this.$message.error('车牌号字段信息填写不完整');
        return;
      }
      if (!this.param.vehicle_type_id) {
        this.$message.error('车辆类型字段信息填写不完整');
        return;
      }
      if (!this.param.vehicle_weight) {
        this.$message.error('车辆自重字段信息填写不完整');
        return;
      }
      if (!this.param.license_principal) {
        this.$message.error('行驶证主页图片字段信息填写不完整');
        return;
      }
      if (!this.param.license_deputy) {
        this.$message.error('行驶证副页图片字段信息填写不完整');
        return;
      }
      if (!this.param.driver_name) {
        this.$message.error('驾驶员姓名字段信息填写不完整');
        return;
      }
      if (!this.param.driver_phone) {
        this.$message.error('联系电话字段信息填写不完整');
        return;
      }
      if (!this.param.driver_idcard) {
        this.$message.error('驾驶员身份证号字段信息填写不完整');
        return;
      }
      if (!this.param.arrival_time) {
        this.$message.error('到货时间字段信息填写不完整');
        return;
      }
      let isValid = '';
      for (let keys in this.param.reservation) {
        isValid = this.validateParam(this.param.reservation[keys])
        if (!isValid) {
          this.$message.error('货物信息字段信息填写不完整');
          return;
        }
      }
      this.param.reservation = JSON.stringify(this.param.reservation)
      console.log('-------------------------------------前置校验完成', this.param)// 前置校验完成
      this.loading = true
      this.$('/api/Reservation/addReservation', this.param).then(res => {
        console.log('提交返回值', res)
        // 关闭本页,跳转到提交结果页
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.getInfo()//获取最后一次提交信息

        } else {
          this.$message.error(res.msg);
        }
      }).finally(() => {
        this.param.reservation = JSON.parse(this.param.reservation)
        this.loading = false
      })




    },
    // 校验
    validateParam(param) {
      let excludedFields = ['transport_sn', 'goods_name'];
      for (let key in param) {
        if (!excludedFields.includes(key)) {
          if (param[key] === '' || param[key] === undefined) {
            return false;
          }
        }
      }
      return true;
    },
    // 添加一条货物信息
    addClick() {
      let obj_data = {
        reservation_sn: "",
        transport_sn: "",
        goods_num: "",
        packaging_type_id: "",
        goods_weight: "",
        goods_name: ""
      }
      this.param.reservation.push(obj_data);

    },
    // 删除对应货物信息
    delClick(idx) {
      console.log('--------------', this.param.reservation)
      console.log('--------------', idx)
      // 删除
      if (idx >= 0 && idx < this.param.reservation.length) {
        this.param.reservation.splice(idx, 1);
      } else {
        this.$message.error('至少填写一条货物信息');
      }
    },
    // 获取车辆类型
    getCarClass() {
      this.$('/api/Reservation/getVehicleList').then(res => {
        console.log('车辆类型---', res)
        this.car_rang = res.data.map(i => {
          let j = {}
          j.value = i.id
          j.label = i.name
          return j;
        })
        console.log('车辆', this.car_rang)
      })

    },
    // 获取包装类型
    getbzClass() {
      this.$('/api/Reservation/getPackagingList').then(res => {
        console.log('包装类型---', res)
        this.bz_rang = res.data.map(i => {
          let j = {}
          j.value = i.id
          j.label = i.name
          return j;
        })
        console.log('包装', this.bz_rang)

      })
    },
    // 获取配置
    // getConfig() {
    //   this.config_infomake = JSON.parse(localStorage.getItem('config'))
    //   console.log('配置信息:', this.config_infomake)
    //   // this.$('/api/Index/getReservationConfig').then(res => {
    //   //   console.log('配置信息:', res.data)
    //   //   this.config_infomake = res.data
    //   // })
    // },
    // 轮播
    getBanner1() {
      this.$('/api/Index/getBannerList', {
        type: 1,
        show_type: 3
      }).then(res => {
        console.log('banner===', res.data)
        this.banner1 = res.data
      })
    },
  }




}
</script>
<style lang="scss" scoped>
.make_page {
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 28%, #F6F6F6 100%);

  .banner_item {
    width: 100%;
    height: auto;
  }

  .main {
    min-height: 100vh;

    .main_room {
      position: relative;
      top: -3vw;
      left: 0;
      z-index: 10;

      .page_box {
        width: 100%;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px 25px 58px;

        .page_box_title {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: space-between;
          align-items: center;

          .page_box_title_left {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 3px;
            justify-content: flex-start;
            align-content: center;

            .page_box_title_left_text {
              font-size: 20px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #191919;
            }

            .page_box_title_left_link {
              width: 35px;
              height: 3px;
              border-radius: 5px;
              background-color: #ceb05a;
            }
          }

          .page_box_title_right {
            &::v-deep .el-breadcrumb__inner {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }

            .page_box_title_right_item {
              display: flex;
              justify-items: flex-end;
              align-items: center;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #6C757D;

              &::v-deep .el-breadcrumb__inner.is-link {
                color: #6C757D;
                font-weight: 400;
              }

              &:last-child::v-deep .el-breadcrumb__inner {
                color: #000;
              }

              .page_box_title_right_item_icon {
                width: 15px;
                height: 15px;
                margin-right: 7px;
              }


            }
          }
        }

        // 地图
        .map_bar {
          padding: 20px 0 25px;

          .map_bar_box {
            border-radius: 15px;
            background-color: #fff;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;

            &.hidden-lg-and-up {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              justify-content: center;
              align-items: flex-start;
              overflow: hidden;

              .map_cont {
                width: 100%;

                .map_cont_box {
                  width: 100%;
                }
              }

              .map_info {
                width: 100%;
              }
            }

            .map_cont {
              width: 29.94791vw;
              height: 100%;

              .map_cont_box {
                width: 29.94791vw;
                padding-top: 57.3913%;
                position: relative;


                .map_item {
                  object-fit: cover;
                  object-position: center;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                }

                // background: linear-gradient(180deg, rgba(255, 255, 255, 0.59) 0%, rgba(239, 239, 239, 0.65) 18%, rgba(224, 224, 224, 0.35) 36%, rgba(255, 255, 255, 0) 100%);
              }
            }



            .map_info {
              width: calc(100% - 29.94791vw);
              padding: 1vw 10.15625vw 1vw 3.38541vw;

              .map_info_item {
                border-bottom: 1px solid #f5f6fa;
                padding: 15px 0;
                display: grid;
                grid-template-columns: 24px calc(100% - 24px - 10px);
                column-gap: 10px;
                justify-content: flex-start;
                align-items: flex-start;

                .map_info_item_icon {
                  width: 24px;
                  height: 24px;
                }

                .map_info_item_cont {
                  display: grid;
                  grid-template-columns: repeat(1, 1fr);
                  row-gap: 5px;
                  align-content: flex-start;

                  .map_info_item_cont_title {
                    font-size: 14px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                  }

                  .map_info_item_cont_text {
                    font-size: 13px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                  }

                }

                &:last-child {
                  border-bottom: 1px solid rgba(0, 0, 0, 0);
                }

              }
            }
          }
        }


        .form_bar {
          padding-bottom: 60px;
          border-radius: 15px;

          .form_item {
            .bar_title {
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #191919;

              .bar_title_link {
                width: 4px;
                height: 18px;
                background: #CEB05A;
                border-radius: 0px 3px 3px 0px;
              }

              .bar_title_text {
                padding-left: 25px;
              }
            }

            .car_box_fill {
              padding: 5px 25px 0;

              .car_box_fill_top {
                padding-bottom: 18px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-content: flex-start;
                column-gap: 70px;

                &.hidden-lg-and-up {
                  grid-template-columns: repeat(1, 1fr);
                  column-gap: 0;
                }

                &.hidden-md-and-down {
                  .fill_ipt {
                    .fill_ipt_box {
                      max-width: 200px;
                    }
                  }
                }

                .fill_ipt {
                  height: 50px;
                  border-bottom: 1px solid #f0f0f0;
                  display: grid;
                  grid-template-columns: auto auto;
                  justify-content: space-between;
                  align-items: center;


                  .fill_ipt_title {
                    white-space: nowrap;
                    font-size: 14px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                  }

                  .fill_ipt_box {
                    max-width: 150px;
                    min-width: 80px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;

                    // 下拉框样式修改
                    ::v-deep .el-input__inner {
                      width: 150px;
                      border: 1px solid rgba(0, 0, 0, 0);
                      color: #000;
                      text-align: right;

                      &::placeholder {
                        color: #666;
                      }
                    }

                    ::v-deep .el-select .el-input .el-select__caret {
                      color: #333;
                    }


                    .fill_ipt_box_ipt {
                      width: 100%;

                      .fill_ipt_box_ipt_cont {
                        width: 100%;
                        height: 100%;
                        text-align: right;

                        &::v-deep .el-icon-time:before {
                          content: ''
                        }

                        &::v-deep .el-input--suffix .el-input__inner {
                          padding: 0;
                        }

                        &::v-deep .el-input__inner {
                          padding: 0;

                        }


                      }

                    }



                    .fill_ipt_box_icon {
                      width: 20px;
                      height: 20px;
                      margin-left: 8px;
                    }

                    .fill_ipt_box_text {
                      padding-left: 8px;
                      font-size: 14px;
                      font-family: PingFang SC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #333333;
                    }
                  }

                }


              }

              .car_box_fill_bot {
                .car_box_fill_bot_title {
                  font-size: 14px;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #333333;
                }

                .car_box_fill_bot_img_box {
                  padding: 20px 0 0;
                  display: grid;
                  grid-template-columns: auto auto;
                  column-gap: 30px;
                  justify-content: flex-start;
                  align-items: flex-start;

                  &.hidden-lg-and-up {
                    grid-template-columns: repeat(1, 1fr);
                    row-gap: 20px;
                    column-gap: 0;
                  }

                  &.hidden-md-and-down {
                    .car_box_fill_bot_img_box_item {
                      width: 15.625vw;
                    }
                  }



                  .car_box_fill_bot_img_box_item {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    row-gap: 10px;
                    justify-items: center;
                    align-items: center;


                    &::v-deep .el-icon-plus:before {
                      content: '';
                    }


                    .car_box_fill_bot_img_box_item_img {
                      width: 100%;
                      padding-top: 63.33333%;
                      position: relative;

                      .car_box_fill_bot_img_box_item_img_img {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                      }
                    }

                    .car_box_fill_bot_img_box_item_text {
                      font-size: 14px;
                      font-family: PingFang SC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #666666;
                    }
                  }
                }

              }
            }
          }

          .car_box {
            padding: 25px 0 35px;
            border-bottom: 8px solid #f6f6f6;
          }

          .good_box {
            padding: 25px 0 0;

            .good_box_fill {
              padding: 5px 25px 0;

              .good_box_fill_top {
                padding-bottom: 25px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-content: flex-start;
                column-gap: 70px;

                &.hidden-lg-and-up {
                  grid-template-columns: repeat(1, 1fr);
                  column-gap: 0;
                }

                .fill_ipt {
                  height: 50px;
                  border-bottom: 1px solid #f0f0f0;
                  display: grid;
                  grid-template-columns: auto auto;
                  justify-content: space-between;
                  align-items: center;

                  .fill_ipt_title {
                    white-space: nowrap;
                    font-size: 14px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                  }

                  .fill_ipt_box {
                    max-width: 150px;
                    min-width: 80px;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;

                    // 下拉框样式修改
                    ::v-deep .el-input__inner {
                      max-width: 150px;
                      min-width: 80px;
                      border: 1px solid rgba(0, 0, 0, 0);
                      color: #000;
                      text-align: right;

                      &::placeholder {
                        color: #666;
                      }
                    }

                    ::v-deep .el-select .el-input .el-select__caret {
                      color: #333;
                    }

                    .fill_ipt_box_ipt {
                      width: 100%;

                      .fill_ipt_box_ipt_cont {
                        width: 100%;
                        height: 100%;
                        text-align: right;
                      }
                    }

                    .fill_ipt_box_icon {
                      width: 20px;
                      height: 20px;
                      margin-left: 8px;
                    }

                    .fill_ipt_box_text {
                      padding-left: 8px;
                      font-size: 14px;
                      font-family: PingFang SC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #333333;
                    }
                  }

                }


              }

              .good_box_fill_bot {
                margin: 0 auto;
                padding: 25px 0 40px;
                display: flex;
                column-gap: 15px;
                justify-content: center;
                align-items: baseline;

                .good_box_fill_bot_left {
                  width: 18.75vw;
                  max-width: 360px;
                  min-width: 144px;
                  // height: 48px;
                  line-height: 48px;
                  border-radius: 10px;
                  border: 1px solid #CEB05A;
                  font-size: 13px;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #CEB05A;
                  text-align: center;
                }

                .good_box_fill_bot_right {
                  width: 7.55208vw;
                  max-width: 145px;
                  min-width: 58px;
                  // height: 48px;
                  line-height: 48px;
                  border-radius: 10px;
                  border: 1px solid #F2F2F2;
                  font-size: 13px;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #999;
                  text-align: center;
                }
              }



            }


          }

          .btn_box {
            padding: 30px 25px;

            .sub_btn {
              width: 100%;
              max-width: 520px;
              height: 56px;
              line-height: 56px;
              margin: 0 auto;
              text-align: center;
              border-radius: 10px;
              background-color: #CEB05A;
              font-size: 15px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
            }

          }
        }










      }

    }
  }


}
</style>
