<template>
  <div class="classify_item_view base_shadow sethand">
    <el-image class="index_item_top" :src="setHttps(obj.image)" @click.stop="jumpDetail"></el-image>
    <div class="index_item_bot flex flex-between">
      <div class="index_item_bot_left flex flex-start">
        <div class="icon_btn" @click.stop="plClick">
          <el-image class="icon_btn_icon" :src="require('@/assets/img/c1.png')"></el-image>
          <div class="icon_btn_text">{{ obj.evaluate_num || '0' }}</div>
        </div>
      </div>
      <div class="index_item_bot_right">
        <div class="icon_btn" @click.stop="scClick">
          <el-image v-if="obj.collect_status === 0" class="icon_btn_icon" :src="require('@/assets/img/c2.png')"></el-image>
          <el-image v-else class="icon_btn_icon" :src="require('@/assets/img/c2a.png')"></el-image>
          <div class="icon_btn_text">{{ obj.collect_num || '0' }}</div>
        </div>
        <div class="icon_btn" @click.stop="dzClick">
          <el-image v-if="obj.likes_status === 0" class="icon_btn_icon" :src="require('@/assets/img/c3.png')"></el-image>
          <el-image v-else class="icon_btn_icon" :src="require('@/assets/img/c3a.png')"></el-image>
          <div class="icon_btn_text">{{ obj.likes_num || '0' }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'classify-item',
  props: {
    obj: {
      type: Object,
      default() {
        return {};
      }
    },
    currenttab: {
      type: Number,
      default: 0
    },
    jumptype: {
      type: String,
      default: '',
    }
  },
  data: function () {
    return {

    }
  },
  mounted() {
    // console.log(this.$refs.nav)
    // 获取要绑定事件的元素
    let nav = this.$refs.nav
    if (nav) {
      this.scrollInit(nav);
    }
  },
  methods: {
    // 跳转
    jumpDetail() {
      if(this.currenttab === 4){
        let href = this.$router.resolve({ path: '/zyDetail', query: { id: this.obj.id,tabstate:true, currenttab: this.currenttab }}).href;
        window.open(href, "_blank");
      }else{
        this.$router.push({ name: 'zyDetail', query: { id: this.obj.id,tabstate:false, currenttab: this.currenttab } });
      }
    },
    // 评论
    plClick() {
      console.log('跳转currenttab', this.currenttab)
      let datainfo = encodeURIComponent(JSON.stringify(this.obj))
      this.$router.push({ name: 'zyDetail', query: { info: datainfo, currenttab: this.currenttab } });
    },
    // 点赞
    dzClick() {
      this.$('/api/Index/setResourceLikes', {
        resource_id: this.obj.id
      }).then(res => {
        console.log('收藏---', res)
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.$emit('getList3')
      })
    },
    // 收藏
    scClick() {
      this.$('/api/Index/setResourceCollect', {
        resource_id: this.obj.id
      }).then(res => {
        console.log('收藏---', res)
        this.$message({
          message: res.msg,
          type: 'success'
        });
        console.log('收藏---aaa', this)

        this.$emit('getList3')

      })
    },
    // 初始化与绑定监听事件方法
    scrollInit(nav) {
      let flag; // 鼠标按下
      let downX; // 鼠标点击的x下标
      let scrollLeft; // 当前元素滚动条的偏移量
      nav.addEventListener("mousedown", function (event) {
        flag = true;
        downX = event.clientX; // 获取到点击的x下标
        scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
      });
      nav.addEventListener("mousemove", function (event) {
        if (flag) { // 判断是否是鼠标按下滚动元素区域
          let moveX = event.clientX; // 获取移动的x轴
          let scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
          this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
        }
      });
      // 鼠标抬起停止拖动
      nav.addEventListener("mouseup", function () {
        flag = false;
      });
      // 鼠标离开元素停止拖动
      nav.addEventListener("mouseleave", function () {
        flag = false;
      });
    },
  }

}
</script>

<style lang="scss" scoped>
.classify_item_view {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;

  .index_item_top {
    width: 100%;
    height: auto;
  }

  .index_item_bot {
    width: 100%;
    height: 54px;
    padding: 15px;

    .index_item_bot_left {
      color: #979797;
    }

    .index_item_bot_right {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: flex-end;
      column-gap: 20px;
      align-items: center;
    }

    .icon_btn {
      display: grid;
      grid-template-columns: 24px auto;
      justify-content: flex-start;
      align-items: center;

      .icon_btn_icon {
        width: 24px;
        height: 24px;
      }

      .icon_btn_text {
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #979797;
      }
    }

  }



}</style>
