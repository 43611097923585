<template>
  <div class="hs_list_page">
    <BaseHeader v-if="config" :headerNum="0" :config="config"></BaseHeader>

    <div class="main">


      <!-- 轮播 -->
      <el-carousel height="26.041vw" class="hidden-xs-only" :interval="Number(banner1.interval)" :loop="true">
        <el-carousel-item v-for="(item, index) in banner1.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>
      </el-carousel>
      <el-carousel height="26.041vw" class="hidden-sm-and-up" :interval="Number(banner1.interval)" :loop="true">
        <el-carousel-item v-for="(item, index) in banner1.data" :key="index">
          <el-image class="banner_item" :src="setHttps(item.image)"></el-image>
        </el-carousel-item>
      </el-carousel>

      <!-- 展示区 -->
      <el-row type="flex" justify="center">
        <el-col :lg="16" :md="18" :sm="20" :xs="22">
          <div class="base_width main_room">

            <!-- 加搜索 -->
            <div class="base_search">
              <div class="base_search_box">
                <input v-model="fildval" @change="baseSearchClick" class="base_search_box_ipt" placeholder="请输入搜索关键字"
                  type="text">
                <el-image @click.stop="baseSearchClick" class="base_search_box_icon sethand"
                  :src="require('@/assets/img/s1.png')"></el-image>
              </div>
            </div>



            <div class="page_box">



              <div v-if="fieldval === 'hs'" class="page_box_title">
                <div class="page_box_title_left">
                  <div class="page_box_title_left_text">{{ infocode }}航线</div>
                  <div class="page_box_title_left_link"></div>
                </div>
                <el-breadcrumb separator-class="el-icon-arrow-right" class="flex flex-end page_box_title_right">
                  <el-breadcrumb-item :to="{ path: '/' }" class="flex flex-end page_box_title_right_item sethand">
                    <el-image class="page_box_title_right_item_icon" :src="require('@/assets/img/home1.png')"></el-image>
                    <div class="page_box_title_right_item_text">首页</div>
                  </el-breadcrumb-item>
                  <el-breadcrumb-item class="flex flex-end page_box_title_right_item">{{ infocode }}航线</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
              <div v-if="fieldval === 'jg'" class="page_box_title">
                <div class="page_box_title_left">
                  <div class="page_box_title_left_text">{{ infoname }}</div>
                  <div class="page_box_title_left_link"></div>
                </div>
                <el-breadcrumb separator-class="el-icon-arrow-right" class="flex flex-end page_box_title_right">
                  <el-breadcrumb-item :to="{ path: '/' }" class="flex flex-end page_box_title_right_item">
                    <el-image class="page_box_title_right_item_icon" :src="require('@/assets/img/home1.png')"></el-image>
                    <div class="page_box_title_right_item_text">首页</div>
                  </el-breadcrumb-item>
                  <el-breadcrumb-item class="flex flex-end page_box_title_right_item">{{ infoname }}</el-breadcrumb-item>
                </el-breadcrumb>
              </div>


              <div class="page_box_tab_list">
                <el-row :gutter="18" v-if="list_arr" class="tab_list_box">
                  <el-col :lg="4" :md="6" :sm="8" :xs="12" v-for="(item, index) in setItemsHs" :key="index" style="margin-bottom: 20px;">
                    <indexItem :obj="item" :aim="aimval" :currenttab="0"></indexItem>
                  </el-col>
                </el-row>
                <div class="tips">已经到底了~</div>
                <el-row type="flex" justify="center">
                  <el-col :lg="24" :md="24" :sm="24" :xs="24" class="pageing_box flex flex-center">
                    <el-pagination small :pager-count="5" background layout="prev, pager, next" :total="list_arr.length" :page-size="pageSize_hs"
                      :current-page="currentPage_hs" @current-change="hsChange" class="custom_pagination"></el-pagination>
                  </el-col>
                </el-row>
              </div>
            </div>




          </div>
        </el-col>
      </el-row>


    </div>
  </div>
</template>
<script>
module.exports = {
  data: function () {
    return {
      fildval: '',
      config: '',

      banner1: '',
      fieldval: '',//列表类型-hs优质航司|jg金刚区|
      aimval: 1,
      infocode: '',//携带进来的数据
      infoname: '',//携带进来的数据
      param: {
        company_id: '',//优质航司
      },
      param1: {
        trade_id: '',//金刚区
      },
      list_arr: '',
      currentPage_hs: 1,
      pageSize_hs: 12,
      setItemsHs: '',

    }
  },
  created() {


  },

  mounted() {
    this.getConfig()

    this.getBanner1()
    // 获取名为'info'的参数
    let infoname = this.$route.query.infoname;
    let id = this.$route.query.id;
    this.fieldval = this.$route.query.fieldval
    if (this.fieldval === 'hs') {
      this.param.company_id = id
      this.infoname = infoname
      this.infocode = this.$route.query.infocode;
      this.aimval = 1
    }
    if (this.fieldval === 'jg') {
      this.param1.trade_id = id
      this.infoname = infoname
      this.aimval = 2

    }
    console.log('fieldval===', this.fieldval)
    console.log('fieldval===', this.id)

    if (this.fieldval === 'hs') {
      this.getList()
    }
    if (this.fieldval === 'jg') {
      this.getList1()
    }
  },
  methods: {
    getConfig() {
      console.log('2222')
      this.$('/api/Index/getBaseConfig').then(res => {
        console.log('配置信息1', res.data)
        this.config = JSON.parse(JSON.stringify(res.data))
        localStorage.setItem('config', JSON.stringify(res.data));
        console.log('配置信息2', res.data)
        // 删除re1为icon的link标签，解决link一直增加导致favicon不能即时刷新的问题document.head.querySelectorall("link") ,forEach(link => (if (link,rel === "icon") !
        document.head.querySelectorAll("link").forEach(link => {
          if (link.rel === "icon") {
            link.parentNode?.removeChild(link);
          }
        });
        const link = document.createElement('link');
        link.rel = "icon";
        link.href = this.setHttps(res.data.icon); // 将网络图片的URL赋给href属性
        document.head.appendChild(link);
      })
    },
    // 搜索
    baseSearchClick() {
      if (!this.fieldval) {
        if (this.fieldval === 'hs') {
          this.getList()
        }
        if (this.fieldval === 'jg') {
          this.getList1()
        }
        return;
      }
      if (this.fieldval === 'hs') {
        this.$('/api/Product/getProductList', {
          search_key: this.fildval,
          ...this.param
        }).then(res => {
          console.log('列表---', res)
          this.list_arr = res.data
          // 根据当前页和分页大小计算要显示的数据范围
          let startIndex = (this.currentPage_hs - 1) * this.pageSize_hs;
          let endIndex = startIndex + this.pageSize_hs;
          this.setItemsHs = this.list_arr.slice(startIndex, endIndex);
        })
      }
      if (this.fieldval === 'jg') {
        this.$('/api/Product/getProductList', {
          search_key: this.fildval,
          ...this.param1
        }).then(res => {
          console.log('列表---', res)
          this.list_arr = res.data
          // 根据当前页和分页大小计算要显示的数据范围
          let startIndex = (this.currentPage_hs - 1) * this.pageSize_hs;
          let endIndex = startIndex + this.pageSize_hs;
          this.setItemsHs = this.list_arr.slice(startIndex, endIndex);
        })
      }
    },
    // 轮播
    getBanner1() {
      this.$('/api/Index/getBannerList', {
        type: 1,
        show_type: 1
      }).then(res => {
        console.log('banner===', res.data)
        this.banner1 = res.data
      })
    },
    // 优质航司产品列表
    getList() {
      this.$('/api/Product/getProductList', this.param).then(res => {
        console.log('列表---', res)
        this.list_arr = res.data
        // 根据当前页和分页大小计算要显示的数据范围
        let startIndex = (this.currentPage_hs - 1) * this.pageSize_hs;
        let endIndex = startIndex + this.pageSize_hs;
        this.setItemsHs = this.list_arr.slice(startIndex, endIndex);
      })
    },
    // 金刚区产品列表
    getList1() {
      this.$('/api/Product/getProductList', this.param1).then(res => {
        console.log('列表---', res)
        this.list_arr = res.data
        // 根据当前页和分页大小计算要显示的数据范围
        let startIndex = (this.currentPage_hs - 1) * this.pageSize_hs;
        let endIndex = startIndex + this.pageSize_hs;
        this.setItemsHs = this.list_arr.slice(startIndex, endIndex);
      })
    },
    // 切换分页
    hsChange(num) {
      this.currentPage_hs = num;
      // 根据当前页和分页大小计算要显示的数据范围
      let startIndex = (this.currentPage_hs - 1) * this.pageSize_hs;
      let endIndex = startIndex + this.pageSize_hs;
      this.setItemsHs = this.list_arr.slice(startIndex, endIndex);
    },



  }
}
</script>
<style lang="scss" scoped>
.hs_list_page {
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 28%, #F6F6F6 100%);

  .base_search {
    padding: 20px 0;

    .base_search_box {
      width: 100%;
      height: 55px;
      padding: 0 20px;
      border-radius: 10px;
      box-shadow: 0px 5px 5px 0px rgba(153, 153, 153, 0.15);
      background-color: #fff;
      display: grid;
      grid-template-columns: calc(100% - 24px) 24px;
      justify-content: space-between;
      align-items: center;

      .base_search_box_ipt {
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;

      }

      .base_search_box_icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  .banner_item {
    width: 100%;
    height: auto;
  }

  .main {
    min-height: 100vh;

    .main_room {
      position: relative;
      top: -3vw;
      left: 0;
      z-index: 10;

      .page_box {
        width: 100%;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px 25px 0;

        .page_box_title {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: space-between;
          align-items: center;

          .page_box_title_left {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 3px;
            justify-content: flex-start;
            align-content: center;

            .page_box_title_left_text {
              font-size: 20px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #191919;
            }

            .page_box_title_left_link {
              width: 35px;
              height: 3px;
              border-radius: 5px;
              background-color: #ceb05a;
            }
          }

          .page_box_title_right {
            &::v-deep .el-breadcrumb__inner {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }

            .page_box_title_right_item {
              display: flex;
              justify-items: flex-end;
              align-items: center;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #6C757D;

              &::v-deep .el-breadcrumb__inner.is-link {
                color: #6C757D;
                font-weight: 400;
              }

              &:last-child::v-deep .el-breadcrumb__inner {
                color: #000;
              }

              .page_box_title_right_item_icon {
                width: 15px;
                height: 15px;
                margin-right: 7px;
              }


            }
          }
        }

        .page_box_tab_list {
          padding: 20px 0 58px;

          
        }

        .tips {
          padding-top: 35px;
          text-align: center;
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }

        .pageing_box {
          padding: 30px 0 60px;

          .custom_pagination::v-deep .el-pager li.active {
            background-color: #CEB05A;
            /* 设置选中页码的背景颜色 */
          }

          .custom_pagination::v-deep .el-pager li:not(.active):hover {
            color: #CEB05A;
            /* 修改悬停时的字体颜色 */
          }
        }
      }

    }
  }


}
</style>
